import React from 'react'
import styled from 'styled-components'
import { CategorySectionType } from '../../../queries/summary'
import { useParams, useNavigate } from 'react-router'
import { ParamsType } from '../../layout/ParamsType'
import formatPrice from '../../layout/formatPrice'

interface Props {
  summary: CategorySectionType
  vatPrice: boolean
  currencyName: string
}

export default ({ summary, vatPrice, currencyName }: Props) => {
  const params = useParams<ParamsType>()
  const navigate = useNavigate()

  function handleRedirect() {
    return summary.category.id
      ? navigate(
          `/invoices/${params.month ? `${params.month}-` : ''}${params.year}?categoryId=${
            summary.category.id
          }`
        )
      : navigate(`/invoices/${params.month ? `${params.month}-` : ''}${params.year}`)
  }

  return (
    <>
      <SummaryContainer>
        <StyledSummaryText onClick={handleRedirect}>
          <WithUnderline>{summary.category.displayName}</WithUnderline>
        </StyledSummaryText>
        <StyledVatPrice>
          {formatPrice(summary.vatPrice)}
          &nbsp; {currencyName}
        </StyledVatPrice>
        <StyledTotalPrice>
          {vatPrice ? formatPrice(summary.totalPrice) : formatPrice(summary.realPrice)}
          &nbsp; {currencyName}
        </StyledTotalPrice>
      </SummaryContainer>
    </>
  )
}

const SummaryContainer = styled.tr`
  padding-left: 2em;
  padding-right: 1.5em;
  height: 3em;

  @media (max-width: 560px) {
    padding-left: 1.5em;
  }
`

const StyledSummaryText = styled.td`
  color: #cbcfd3;
  font-family: Montserrat;
  font-size: 0.8125em;
  justify-content: flex-start;
  width: 15em;

  @media (max-width: 375px) {
    font-size: 0.65em;
  }
`

const StyledVatPrice = styled(StyledSummaryText)`
  width: 12.5em;
  justify-content: flex-start;

  @media (max-width: 560px) {
    white-space: nowrap;
  }
`

const StyledTotalPrice = styled(StyledVatPrice)`
  width: 7.8em;

  @media (max-width: 375px) {
    width: 5em;
  }
`

const WithUnderline = styled.span`
  display: inline;
  padding-bottom: 1px;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid orange;
  }
`
