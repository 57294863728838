import React from 'react'
import styled from 'styled-components'
import Cancel from '../../ActionsDropdown/Cancel'
import { Container, Overlay } from '../../DropdownStyledComponents'
import renderFooterPrice from '../../renderFooterPrice'

interface Props {
  paidInvoicesSum: number
  invoicesSum: number
  handleClose: () => void
  currencyName?: string
  totalCost?: boolean
}

export default (props: Props) => {
  function renderPriceSection(priceText: string, price: number, currency?: string) {
    return <Section>{renderFooterPrice(priceText, price, currency)}</Section>
  }

  return (
    <>
      <Overlay />
      <Container height={12}>
        {renderPriceSection(
          props.totalCost ? 'Total :' : 'Real :',
          props.invoicesSum,
          props.currencyName
        )}
        {renderPriceSection('Paid :', props.paidInvoicesSum, props.currencyName)}
        {renderPriceSection(
          'To be paid :',
          props.invoicesSum - props.paidInvoicesSum,
          props.currencyName
        )}
        <Cancel handleCancel={props.handleClose} />
      </Container>
    </>
  )
}

const Section = styled.section`
  display: flex;
  flex-direction: row;
  margin-bottom: 2.2em;
  margin-left: 1.5em;
  margin-top: 1em;
`
