import React from 'react'
import styled, { css } from 'styled-components'
import ButtonStyles from '../ButtonStyles'
import { ParamsType } from '../ParamsType'
import { useParams } from 'react-router'
import useLogin from '../../../hooks/useLogin'
import Footer from './Footer'
import { ButtonSection, PriceSection, CloseMonthButton, ReOpenButton } from './InvoiceSectionFooter'

interface Props {
  pendingSectionFooter: boolean
  displayMobile: boolean
  closedMonth?: boolean
  closeMonthButtonActive?: boolean
  handleMonthClose?: () => void
  handleMonthReOpen?: () => void
  emptyMonth?: boolean
}

export default ({
  pendingSectionFooter,
  displayMobile,
  closedMonth,
  closeMonthButtonActive,
  handleMonthClose,
  handleMonthReOpen
}: Props) => {
  const params = useParams<ParamsType>()

  const { ADMIN } = useLogin()

  const styledButtons = (
    <>
      {!closedMonth ? (
        <CloseMonthButton
          onClick={closeMonthButtonActive ? handleMonthClose : () => null}
          active={!!closeMonthButtonActive}
          disabled={!closeMonthButtonActive}
        >
          Close month
        </CloseMonthButton>
      ) : (
        <ReOpenButton active={ADMIN} onClick={ADMIN ? handleMonthReOpen : () => null}>
          Re-open
        </ReOpenButton>
      )}
    </>
  )

  return (
    <Footer
      displayMobile={displayMobile}
      isSticky={false}
      emptyMonth={true}
      pendingSectionFooter={pendingSectionFooter}
    >
      <PriceSection />
      <ButtonSection>
        {!pendingSectionFooter && params.month && params.year && ADMIN && styledButtons}
      </ButtonSection>
    </Footer>
  )
}
