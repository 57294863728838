import { gql } from '@apollo/client'

export default gql`
  query user($userId: Int!, $includeEmail: Boolean!) {
    user(userId: $userId) {
      username
      displayName
      notify
      adminLevel
      id
      email @include(if: $includeEmail)
    }
  }
`
