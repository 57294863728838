import React, { useState, useEffect, useContext } from 'react'
import Section from './index'
import { headerDataSettingsCategory } from '../../../../constants/sectionsData'
import { useMutation, useQuery } from '@apollo/react-hooks'
import categories, { CategoryItem } from '../../../../queries/categories'
import DeletePopUp from '../../../layout/PopUps/DeletePopUp'
import deleteCategory from '../../../../mutations/deleteCategory'
import addCategory from '../../../../mutations/addCategory'
import InputPopUp from '../PopUps/InputPopUp'
import { settingsDataCategory } from '../../../../constants/formsData'
import editCategory from '../../../../mutations/editCategory'
import inputValueReduce from '../../../layout/inputValueReduce'
import handleClose from './handleClose'
import handleOpen from './handleOpen'
import isItDuplicity from './isItDuplicity'
import { CurrencyItem } from '../../../../queries/currencies'
import { StatusItem } from '../../../../queries/statuses'
import { InputValueStateType } from '../../../../constants/types'
import { ErrorContext } from '../../../../context/errorContext'
import usePrevious from '../../../../hooks/usePrevious'

export default () => {
  const { data, loading } = useQuery(categories)
  const [closed, setClosed] = useState(true)
  const [editClosed, setEditClosed] = useState(true)
  const [addNewClosed, setAddNewClosed] = useState(true)
  const [error, setError] = useState(false)
  const { error: apiError, closedPopUp } = useContext(ErrorContext)
  const prevApiError = usePrevious(apiError)

  const options = [
    { value: 'Taxable', label: 'Taxable' },
    { value: 'Non-taxable', label: 'Non-taxable' }
  ]

  const [actualCategory, setActualCategory] = useState({
    id: 0,
    displayName: 'General',
    vat: 'TAXABLE_CATEGORY'
  })

  const refetchCategoryQueries = { refetchQueries: [{ query: categories }] }
  const [reducedInputValue, setReducedInputValue] = useState<InputValueStateType>({})

  const [deleteActualCategory] = useMutation(deleteCategory, {
    ...refetchCategoryQueries
  })

  const [addNewCategory] = useMutation(addCategory, {
    ...refetchCategoryQueries
  })

  const [editActualCategory] = useMutation(editCategory, {
    ...refetchCategoryQueries
  })

  const reduced = inputValueReduce(reducedInputValue)
  const buttonInactive = !reduced.categoryName || !reduced.taxationSelect

  useEffect(() => {
    if (closedPopUp && prevApiError === 'Category Duplicity') {
      setEditClosed(false)
    }
    if (!editClosed && prevApiError === 'Category Duplicity') {
      setEditClosed(true)
    }
  }, [closedPopUp, prevApiError])

  function getId(item: CurrencyItem | CategoryItem | StatusItem) {
    if (item.id !== actualCategory.id) {
      if ('displayName' in item && 'vat' in item) {
        setActualCategory({
          id: item.id,
          displayName: item.displayName.toString(),
          vat: item.vat.toString()
        })
      }
    }
  }

  function handleCategoryDelete() {
    const { id } = actualCategory

    deleteActualCategory({ variables: { id } }).then(() => setClosed(true))
  }

  function getInputValue(inputValue: InputValueStateType) {
    setReducedInputValue(inputValue)
  }

  function handleEdit() {
    if (buttonInactive) {
      return
    }

    const displayName = reduced.categoryName
    const vat =
      reduced.taxationSelect.toLowerCase().trim() === 'taxable'
        ? 'TAXABLE_CATEGORY'
        : 'NON_TAXABLE_CATEGORY'
    const { id } = actualCategory

    editActualCategory({ variables: { id, displayName: displayName, vat: vat } })
      .then(() => {
        setActualCategory({
          displayName: '',
          id: 0,
          vat: '',
        })
      })
      .catch(() => {})
      .finally(() => setEditClosed(true))
  }
  function handleAdd() {
    if (buttonInactive) {
      return
    }

    if (isItDuplicity(data.categories, 'displayName', reduced.categoryName)) {
      return setError(true)
    }

    const displayName = reduced.categoryName
    const vat =
      reduced.taxationSelect.toLowerCase().trim() === 'taxable'
        ? 'TAXABLE_CATEGORY'
        : 'NON_TAXABLE_CATEGORY'

    addNewCategory({ variables: { displayName: displayName, vat: vat } }).then(() =>
      setAddNewClosed(true)
    )
  }

  function getCategoryEditValues() {
    const isInvalid = false

    return {
      categoryName: { value: actualCategory.displayName, isInvalid },
      taxationSelect: {
        value: actualCategory.vat === 'TAXABLE_CATEGORY' ? 'Taxable' : 'Non-taxable',
        isInvalid
      }
    }
  }

  if (loading) {
    return null
  }

  return (
    <>
      <DeletePopUp
        handleDelete={handleCategoryDelete}
        closed={closed}
        deleteItemText={actualCategory.displayName}
        handleClosed={() => handleClose(closed, setClosed, setError)}
        kindOfDelete="category"
      />
      <InputPopUp
        error={error}
        errorKindText="category"
        closed={editClosed}
        orangeButtonFunction={handleEdit}
        handleClose={() => handleClose(editClosed, setEditClosed, setError)}
        actualCategory={actualCategory}
        data={settingsDataCategory}
        orangeButtonText="Save changes"
        titleText="Edit Category"
        getInputValue={getInputValue}
        editInputValues={getCategoryEditValues()}
        options={options}
        orangeButtonActive={!buttonInactive}
        forCategory={true}
      />
      <InputPopUp
        error={error}
        errorKindText="category"
        closed={addNewClosed}
        orangeButtonFunction={handleAdd}
        handleClose={() => handleClose(addNewClosed, setAddNewClosed, setError)}
        actualCategory={actualCategory}
        data={settingsDataCategory}
        orangeButtonText="Add"
        titleText="Add Category"
        getInputValue={getInputValue}
        editInputValues={getCategoryEditValues()}
        options={options}
        orangeButtonActive={!buttonInactive}
        forCategory={true}
      />
      <Section
        getId={getId}
        headerTitle="Category"
        handleAdd={() => handleOpen(addNewClosed, setAddNewClosed)}
        handleDelete={() => handleOpen(closed, setClosed)}
        forCategory={true}
        handleEdit={() => handleOpen(editClosed, setEditClosed)}
        headerData={headerDataSettingsCategory}
        data={data.categories}
        nameProperty="displayName"
      />
    </>
  )
}
