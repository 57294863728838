import { gql } from '@apollo/client'

export interface CategoryItem {
  [key: string]: string | number | boolean
  displayName: string
  id: number
  vat: string
}

export default gql`
  {
    categories {
      displayName
      id
      vat
      keyName
    }
  }
`
