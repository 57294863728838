import { InputValueItemType, InputValueStateType } from '../../constants/types'

function inputValueReduce(inputValue: InputValueStateType) {
  return Object.entries(inputValue).reduce(
    (newValues: { [key: string]: string }, currentValue: [string, InputValueItemType]) => {
      newValues[currentValue[0]] = currentValue[1].value
      return newValues as any
    },
    {}
  )
}
export default inputValueReduce
