import React, { useState } from 'react'
import Dropdown from 'rc-dropdown'
import 'rc-dropdown/assets/index.css'
import '../Dropdown.css'
import MenuOverlay from './MenuOverlay'

interface Props {
  children: React.ReactElement
  invoiceDate?: any
  setPopUpClosed?: any
  setPath?: any
}

export default (props: Props) => {
  const [visible, setVisible] = useState(false)

  function handleClose() {
    setVisible(false)
  }

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <MenuOverlay
          setMenuVisible={setVisible}
          handleClose={handleClose}
          invoiceDate={props.invoiceDate}
          setPopUpClosed={props.setPopUpClosed}
          setPath={props.setPath}
        />
      }
      visible={visible}
      onVisibleChange={setVisible}
    >
      {props.children}
    </Dropdown>
  )
}
