import styled from 'styled-components'

export default styled.header`
  background-color: #343741;
  width: 56.25em;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 960px) {
    width: 50.2em;
  }

  @media (max-width: 860px) {
    width: 95vw;
  }

  @media (max-width: 560px) {
    display: none;
  }
`
