import styled from 'styled-components'

interface StyledHeaderProps {
  displayMobile: boolean
}

export default styled.header<StyledHeaderProps>`
  background-color: #2b2d35;
  width: 100%;
  height: 8em;
  display: ${({ displayMobile }) => (displayMobile ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  color: white;
  font-display: swap;
  overflow: hidden;

  @media (max-width: 650px) {
    display: ${({ displayMobile }) => (displayMobile ? 'flex' : 'none')};
  }
`
