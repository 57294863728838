import React, { FC, useState } from 'react'

export const ErrorContext = React.createContext<{
  error: string | undefined
  setError: React.Dispatch<React.SetStateAction<any>>
  closedPopUp: boolean
  setClosedPopUp: React.Dispatch<React.SetStateAction<boolean>>
}>({ error: undefined, setError: () => {}, closedPopUp: true, setClosedPopUp: () => {} })

export const ErrorProvider: FC<any> = ({ children }) => {
  const [error, setError] = useState(undefined)
  const [closedPopUp, setClosedPopUp] = useState(true)

  return (
    <ErrorContext.Provider value={{ error, setError, closedPopUp, setClosedPopUp }}>
      {children}
    </ErrorContext.Provider>
  )
}

export default ErrorProvider
