import { ItemType } from '../../../layout/ItemType'

export default function handleStatusCategoryEdit(
  item: ItemType,
  selectedInvoicesIds: string[],
  editInvoices: any,
  property: string
) {
  const propertyId = `${property}Id`
  let dataToSent = {}

  if (selectedInvoicesIds.length === 1) {
    dataToSent = {
      id: selectedInvoicesIds[0],
      [propertyId]: item.id
    }

    return editInvoices({ variables: { data: dataToSent } })
  }

  if (selectedInvoicesIds.length > 1) {
    return selectedInvoicesIds.map((id: string) => {
      dataToSent = {
        id: id,
        [propertyId]: item.id
      }
      return editInvoices({ variables: { data: dataToSent } })
    })
  }
}
