import { useParams } from 'react-router'
import { ParamsType } from '../ParamsType'
import getActualMonth from '../../screens/Items/functions/getActualMonth'
import getActualYear from '../getActualYear'

interface Props {
  invoiceDate?: any
}

export default function getPathname(props: Props, params: any) {
  let itemsPathname: string
  let summaryPathname: string

  if (params.month && params.year) {
    itemsPathname = `/invoices/${params.month}-${params.year}`
    summaryPathname = `/invoices/${params.month}-${params.year}/summary`
  } else if (params.year && !params.month) {
    itemsPathname = `/invoices/${params.year}`
    summaryPathname = `/invoices/${params.year}/summary`
  } else if (props.invoiceDate) {
    if (props.invoiceDate.month && props.invoiceDate.year) {
      itemsPathname = `/invoices/${props.invoiceDate.month}-${props.invoiceDate.year}`
      summaryPathname = `/invoices/${props.invoiceDate.month}-${props.invoiceDate.year}/summary`
    } else {
      const date = new Date(props.invoiceDate)
      itemsPathname = `/invoices/${date.getMonth() + 1}-${date.getFullYear()}`
      summaryPathname = `/invoices/${date.getMonth() + 1}-${date.getFullYear()}/summary`
    }
  } else {
    itemsPathname = `/invoices/${getActualMonth() + 1}-${getActualYear()}`
    summaryPathname = `/invoices/${getActualMonth() + 1}-${getActualYear()}/summary`
  }

  return { itemsPathname, summaryPathname }
}
