import React from 'react'
import styled from 'styled-components'
import FilterPopUp from './FilterPopUp'
import ConnectedButtons from '../../layout/ConnectedButtons'
import { InvoiceType } from '../../../queries/invoices'
import useText from './functions/useText'

interface Props {
  invoiceFilterValues: any
  handleFilter: (property: string) => void
  totalPrice: boolean
  handleConnectedButtonLeftClick: () => void
  handleConnectedButtonRightClick: () => void
  invoiceData: InvoiceType[]
}

export default ({
  invoiceFilterValues,
  handleFilter,
  totalPrice,
  handleConnectedButtonLeftClick,
  handleConnectedButtonRightClick,
  invoiceData
}: Props) => {
  const categoryText = useText('category', invoiceFilterValues.categoryFilterValue, invoiceData)
  const statusText = useText('status', invoiceFilterValues.statusFilterValue, invoiceData)
  const userText = useText('issuers', invoiceFilterValues.userFilterValue, invoiceData)

  return (
    <FilterPopUpsContainer>
      <PopUps>
        {categoryText && (
          <FilterPopUp
            handleFilterCancel={() => handleFilter('categoryId')}
            filterText={categoryText}
          />
        )}
        {statusText && (
          <FilterPopUp
            handleFilterCancel={() => handleFilter('statusId')}
            filterText={statusText}
          />
        )}
        {userText && (
          <FilterPopUp handleFilterCancel={() => handleFilter('userId')} filterText={userText} />
        )}
      </PopUps>
      {(categoryText || statusText || userText) && (
        <FilterRightSection>
          <ConnectedButtons
            rightButtonActive={!totalPrice}
            leftButtonActive={totalPrice}
            leftButtonOnClick={handleConnectedButtonLeftClick}
            rightButtonOnClick={handleConnectedButtonRightClick}
          />
        </FilterRightSection>
      )}
    </FilterPopUpsContainer>
  )
}

const FilterPopUpsContainer = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 645px) {
    margin-left: 20px;
  }
`

const FilterRightSection = styled.section`
  display: flex;
  flex-direction: row;
  height: 3.4em;
  position: relative;
  bottom: -0.5em;
  white-space: nowrap;

  @media (max-width: 960px) {
    bottom: -1em;
  }

  @media (max-width: 540px) {
    display: none;
  }
`

const PopUps = styled.section`
  display: flex;
  flex-direction: row;
`
