import { useLocalStorage } from '@rehooks/local-storage'
import { UserAdminLevel } from '../constants/constants'
import { LoginData } from '../constants/types'

export default (): LoginData => {
  const [loginDataString] = useLocalStorage('loginData')

  if (!loginDataString) return {}

  const loginData: LoginData =
    typeof loginDataString === 'string' ? JSON.parse(loginDataString) : loginDataString

  if (!loginData.user) return {}

  loginData.ADMIN = loginData.user.adminLevel === UserAdminLevel.ADMIN
  loginData.ACCOUNTANT = loginData.user.adminLevel === UserAdminLevel.ACCOUNTANT
  loginData.USER =
    loginData.user.adminLevel === UserAdminLevel.USER ||
    loginData.user.adminLevel === UserAdminLevel.INACTIVE_USER

  return loginData
}
