import React, { useState } from 'react'
import Main from '../Main'
import Header from '../../../layout/Header'
import { useLocation } from 'react-router'

export default () => {
  const location = useLocation()
  const [previewVisible, setPreviewVisible] = useState(false)
  return (
    <>
      <Header invoiceDate={location.state} />
      <Main
        editingInvoice={false}
        setPreviewVisible={setPreviewVisible}
        previewVisible={previewVisible}
        editInvoiceFirstOptions={() => []}
      />
    </>
  )
}
