import React from 'react'
import styled from 'styled-components'
import ButtonStyles from '../../layout/ButtonStyles'

interface Props {
  handleExportButtonClick: any
}

export default ({ handleExportButtonClick }: Props) => (
  <Icons>
    <ExportButtonWithIcon>{handleExportButtonClick}</ExportButtonWithIcon>
  </Icons>
)

const Icons = styled.div`
  display: flex;
`

const ExportButtonWithIcon = styled.button`
  ${ButtonStyles}
  margin-left: 1.5em;
  margin-right: 0em;

  @media (max-width: 375px) {
    font-size: 0.7em;
  }

  &:hover {
    background-color: #414249;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`

const DownloadButton = styled(ExportButtonWithIcon)`
  margin-left: 1em;
`
