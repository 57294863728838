import React from 'react'
import styled from 'styled-components'

interface Props {
  text: string
}

export default (props: Props) => {
  return <MainTitle>{props.text}</MainTitle>
}

const MainTitle = styled.h2`
  font-family: 'Montserrat';
  font-weight: 500;
  color: white;
  font-size: 1.5em;
  line-height: 1.813em;
  margin-top: 4em;

  @media (max-width: 375px) {
    font-size: 1.1em;
  }
`
