import { NavigateFunction } from 'react-router-dom'
import canRedirect from './canRedirect'
import { REDIRECT_WARNING_ROUTES } from '../../constants/constants'

function handleRedirect(
  navigate: NavigateFunction,
  pathname: string,
  redirectTo: string,
  warning: boolean,
  setPopUpClosed?: (state: boolean) => void,
  setPathname?: (state: string) => void,
  month?: number | undefined,
  year?: number | undefined
) {
  if (setPopUpClosed && setPathname && REDIRECT_WARNING_ROUTES.includes(pathname) && warning) {
    setPopUpClosed(false)
    setPathname(redirectTo)
    return
  }

  if (warning && !canRedirect(pathname)) {
    return
  }
  return navigate(redirectTo, { state: { previousPath: pathname, month, year } })
}

export default handleRedirect
