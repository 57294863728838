import { REDIRECT_WARNING_ROUTES } from '../../constants/constants'

function canRedirect(pathname: string) {
  if (REDIRECT_WARNING_ROUTES.includes(pathname)) {
    const confirm = window.confirm('Are you sure you want to redirect ? All data will be lost')

    if (!confirm) {
      return false
    }
  }

  return true
}

export default canRedirect
