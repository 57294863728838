import { FormDataType } from '../../constants/formsData'
import { InputValueStateType } from '../../constants/types'

function areFormInputsValid(array: FormDataType[], inputValue: InputValueStateType) {
  return array.every((item: FormDataType) => {
    if (!item.required) {
      return true
    } else if (!inputValue[item.name] || inputValue[item.name].isInvalid) {
      return false
    }
    return true
  })
}

export default areFormInputsValid
