import { HeaderItem } from '../../constants/sectionsData'
import React from 'react'
import styled, { css } from 'styled-components'
import { deviceType, isTablet, isIPad13 } from 'react-device-detect'

interface Props {
  item: HeaderItem
  handleSort?: () => void
  image?: string
  activeFilterTexts?: string[]
  selectAllInvoices?: () => void
  isAllInvoicesSelected?: boolean
}

export default ({
  handleSort,
  image,
  item,
  activeFilterTexts,
  selectAllInvoices,
  isAllInvoicesSelected,
}: Props) => {
  function deviceActions() {
    const tablet = isTablet || isIPad13 || deviceType === 'mobile' || deviceType === 'tablet'
    const Component = tablet ? Actions : BrowserActions

    return <Component>ACTION</Component>
  }

  if (item.clickable) {
    return (
      <ClickableItem
        onClick={handleSort}
        marginLeft={item.marginLeft || 0}
        marginRight={item.marginRight || 0}
        mobileMarginRight={item.mobileMarginRight || 0}
        aria-label={`filter by ${item.text.toLowerCase()}`}
      >
        {item.text}
        <SortingArrows src={`/img/sorting-arrows-${image || ''}.svg`} />
      </ClickableItem>
    )
  }

  if (item.text === 'ALL') {
    return (
      <NoScrollableAll left={-1}>
        <StyledAllButton onClick={selectAllInvoices ? selectAllInvoices : () => null}>
          <StyledAll
            active={!!isAllInvoicesSelected}
            marginLeft={item.marginLeft || 0}
            marginRight={0}
            mobileMarginRight={item.mobileMarginRight || 0}
            style={{ width: '28px' }}
          >
            {item.text}
          </StyledAll>
        </StyledAllButton>
        {deviceActions()}
      </NoScrollableAll>
    )
  }

  return (
    <>
      <StyledItem
        marginLeft={item.marginLeft || 0}
        marginRight={item.marginRight || 0}
        mobileMarginRight={item.mobileMarginRight || 0}
      >
        {item.text}
        <ImageContainer>
          {item.withFilterImg &&
            activeFilterTexts &&
            activeFilterTexts.includes(
              item.filterText ? item.filterText : item.text.toLowerCase()
            ) && <FilterImage src="/img/filter-funnel.svg" />}
        </ImageContainer>
      </StyledItem>
    </>
  )
}

interface StyledItemProps {
  mobileMargin: number
  width: number
}

interface HeaderSpanProps {
  marginLeft: number
  marginRight: number
  mobileMarginRight: number
}

const itemStyles = css`
  font-family: 'Montserrat';
  font-weight: bold;
  color: white;
  font-size: 0.84em;
  display: flex;

  @media (max-width: 960px) {
    font-size: 0.8125em;
  }

  @media (max-width: 375px) {
    font-weight: 600;
    font-size: 0.75em;
  }
`

const StyledItem = styled.span<HeaderSpanProps>`
  ${itemStyles};
  margin-left: ${({ marginLeft }) => marginLeft}px;
  margin-right: ${({ marginRight }) => marginRight}px;

  @media (max-width: 830px) {
    margin-right: ${({ mobileMarginRight }) => mobileMarginRight}px;
  }
`

const ClickableItem = styled.button<HeaderSpanProps>`
  ${itemStyles};
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  margin-right: ${({ marginRight }) => marginRight}px;

  @media (max-width: 830px) {
    margin-right: ${({ mobileMarginRight }) => mobileMarginRight}px;
  }
`

const SortingArrows = styled.img`
  margin-left: 0.4em;
  position: relative;
  top: 0.1em;
  width: 0.48em;

  @media (max-width: 645px) {
    top: 0;
  }
`

const Actions = styled.span`
  ${itemStyles};
  margin-right: 15px;
  margin-left: 26px;
  padding: 0;

  @media (max-width: 830px) {
    margin-left: 16px;
    margin-right: 5px;
  }

  @media (max-width: 375px) {
    margin-left: 14px;
  }
`

const BrowserActions = styled(Actions)`
  @media (min-width: 960px) {
    display: none;
  }
`

interface NoScrollableProps {
  left: number
}

const NoScrollable = styled.div<NoScrollableProps>`
  display: flex;
  flex-direction: row;

  @media (max-width: 645px) {
    z-index: 2;
    width: 60px;
    position: sticky;
    left: ${({ left }) => left && left}px;
  }
`

const NoScrollableAll = styled(NoScrollable)`
  margin-right: 0;
`

interface StyledAllProps {
  active: boolean
}

const StyledAll = styled(StyledItem)<StyledAllProps>`
  padding-bottom: 1px;

  @media (max-width: 645px) {
    margin-right: 0;
  }

  @media (max-width: 375px) {
    margin-left: 14px;
  }

  ${({ active }) =>
    active &&
    css`
      border-bottom: 1px solid;
      color: #f29312;
    `}

  &:hover {
    color: #f29312;
    cursor: pointer;
  }
`

const StyledAllButton = styled.button`
  display: inherit;
  background-color: transparent;
  border: none;
  color: inherit;
  font: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

const FilterImage = styled.img`
  position: relative;
  left: 0.5em;
`

const ImageContainer = styled.div`
  width: 1px;
`
