import { css } from 'styled-components'

export default css`
  border: 1px solid #9198a0;
  color: #cbcfd3;
  background-color: transparent;
  opacity: 36%;
  cursor: default;

  &:hover {
    background-color: transparent;
    border: 1px solid #9198a0;
  }
`
