import React from 'react'

import { FileResponse } from '../../../../constants/types'
import { getFilePath, isPdf } from '../../../../utils/functions'
import { Preview, PreviewImage, PreviewPdf } from './FilesPreviewComponents'
import { InvoiceType } from '../../../../queries/invoices'
import useLogin from '../../../../hooks/useLogin'

interface Props {
  file: FileResponse
  invoice: InvoiceType
  onClick?: () => void
  selected: boolean
  imageChanged: number
}

const DisplayFilesPreview = ({ file, invoice, onClick, selected, imageChanged }: Props) => {
  const { token } = useLogin()
  const [wasSelected, setWasSelected] = React.useState(false)
  const [rotation, setRotation] = React.useState(0)

  React.useEffect(() => {
    if (selected && wasSelected) {
      setRotation(rotation - 90)
    } else setWasSelected(true)
  }, [imageChanged])

  return (
    <Preview onClick={onClick}>
      {isPdf(file.url) ? (
        <PreviewPdf src={getFilePath(invoice, file, token!)} selected={selected} />
      ) : (
        <PreviewImage
          src={getFilePath(invoice, file, token!)}
          selected={selected}
          rotation={rotation}
        />
      )}
    </Preview>
  )
}

export default DisplayFilesPreview
