import React from 'react'
import styled, { css } from 'styled-components'
import { FormDataType } from '../../constants/formsData'
import { OptionsType } from '../layout/Form/OptionsType'
import { HandleChangeEvent } from './Form/HandleChangeEventType'
import { SelectInput } from './Form/Input'
import { InputValueStateType, UserGroupInvoiceType } from '../../constants/types'
import { Dispatch, SetStateAction } from 'react'

interface IssuerFieldProps {
  isAdmin: boolean | undefined
  issuerLeftSection: FormDataType[]
  inputValue: InputValueStateType
  selectedUsers: UserGroupInvoiceType[]
  detail: boolean | undefined
  userId: SetStateAction<number | null>
  handleUserInputChange: (item: FormDataType, event: HandleChangeEvent) => void
  setClosedRemoveIssuerPopUp: (closed: boolean) => void
  setRemoveIssuerId: Dispatch<SetStateAction<number | null>>
  getSelectOptions: (item: FormDataType) => OptionsType[]
}

const IssuerField = ({
  isAdmin,
  issuerLeftSection,
  inputValue,
  selectedUsers,
  detail,
  userId,
  handleUserInputChange,
  setClosedRemoveIssuerPopUp,
  setRemoveIssuerId,
  getSelectOptions
}: IssuerFieldProps) => {
  return (
    <>
      {issuerLeftSection ? (
        issuerLeftSection.map((item: FormDataType) => (
          <IssuerContainer disabled={detail}>
            <SelectInput
              key={item.name}
              item={item}
              isInvalid={inputValue[item.name] && inputValue[item.name].isInvalid}
              value={inputValue[item.name] && inputValue[item.name].value}
              options={getSelectOptions(item)}
              handleChange={handleUserInputChange}
              mobileSmaller={false}
              fullWidth={true}
              disabled={detail}
              searchable={true}
            />
            {selectedUsers.length > 2 && !detail && (
              <RemoveIssuerAnchor
                onClick={() => {
                  setClosedRemoveIssuerPopUp(false)
                  setRemoveIssuerId(userId)
                }}
              >
                Remove issuer
              </RemoveIssuerAnchor>
            )}
          </IssuerContainer>
        ))
      ) : (
        <></>
      )}
    </>
  )
}

interface IssuerContaienrProps {
  disabled?: boolean
}

const IssuerContainer = styled.div<IssuerContaienrProps>`
  display: flex;
  flex-direction: column;
  gap: 1.68rem;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`

const RemoveIssuerAnchor = styled.a`
  color: #f45151;
  text-decoration: underline;
  cursor: pointer;
`

export default IssuerField
