import styled, { css } from 'styled-components'

interface PreviewProps {
  selected?: boolean
  rotation?: number
}

export const PreviewImage = styled.img<PreviewProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.3125rem;
  border: 0.125rem solid transparent;
  rotate: ${props => props.rotation}deg;

  ${props =>
    props.selected &&
    css`
      border: 0.125rem solid #f29312;
    `}
`

export const PreviewPdf = styled.iframe<PreviewProps>`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0.3125rem;
  pointer-events: none;
  border: 0.25rem solid transparent;

  ${props =>
    props.selected &&
    css`
      border: 0.125rem solid #f29312;
    `}
`

export const Preview = styled.div`
  height: 5rem;
  aspect-ratio: 1/1;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
`
