import React from 'react'
import { Navigate } from 'react-router-dom'
import Props from './Props'
import useLogin from '../hooks/useLogin'

const AdminRoute = ({ Component }: Props) => {
  const { user } = useLogin()

  return user && user.adminLevel === 'ADMIN' ? <Component /> : <Navigate to="/login" />
}

export default AdminRoute
