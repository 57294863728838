import React from 'react'
import styled from 'styled-components'
import { Container, Overlay } from '../../DropdownStyledComponents'
import StyledText from '../../ActionsDropdown/StyledText'

interface Props {
  exportFilePath: string
  exportButtonActive: boolean
}

export default (props: Props) => {
  return (
    <>
      <Overlay />
      <Container height={5}>
        <Center>
          <Text>ALL INVOICES</Text>
        </Center>
        <Items>
          <Export href={props.exportFilePath}>Export</Export>
        </Items>
      </Container>
    </>
  )
}

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Items = styled.section`
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 2em;
`

const Text = styled.span`
  ${StyledText}
  font-size: 0.84em;
  margin-bottom: 2em;
`

const Export = styled.a`
  ${StyledText}
  font-size: 0.84em;
  margin-bottom: 2em;
  text-decoration: none;
`
