import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Line from '../../layout/Line'
import { UserType } from '../../../queries/users'
import { Text } from '../../layout/Styled/Text'
import { ActualUserType } from '../../../constants/types'
import useLogin from '../../../hooks/useLogin'

interface Props {
  item: UserType
  handleEdit: () => void
  handlePasswordChange: () => void
  getActualUser: (actualUser: ActualUserType) => void
}

export default ({ item, handleEdit, handlePasswordChange, getActualUser }: Props) => {
  const { ADMIN } = useLogin()
  const [actualUser, setActualUser] = useState({
    id: 0,
    name: '',
    username: '',
    competency: ''
  })

  function getActualUserValues() {
    setActualUser({
      id: item.id,
      name: item.displayName,
      username: item.username,
      competency: item.adminLevel.toLowerCase()
    })
  }

  useEffect(() => {
    getActualUser(actualUser)
  }, [actualUser]) // eslint-disable-line

  return (
    <Container onMouseOver={getActualUserValues} aria-label={`user row of ${item.username}`}>
      <td>
        <Name>{item.displayName}</Name>
      </td>
      <td>
        <Username>{item.username}</Username>
      </td>
      <td>
        <Competency>{item.adminLevel}</Competency>
      </td>

      {ADMIN && (
        <td>
          <Actions>
            {item.adminLevel === 'ADMIN' ? (
              <StyledNA>N/A</StyledNA>
            ) : (
              <>
                <EditImage src="/img/edit-icon.svg" aria-label="edit user" onClick={handleEdit} />
                <PasswordImage
                  src="/img/password-icon.svg"
                  onClick={handlePasswordChange}
                  aria-label="edit password"
                />
              </>
            )}
          </Actions>
        </td>
      )}
    </Container>
  )
}

const Container = styled.tr`
  height: 3em;
`

const Name = styled(Text)`
  display: flex;
  justify-content: flex-start;
  color: #cbcfd3;
  font-size: 0.82em;

  @media (max-width: 480px) {
    width: 6em;
    font-size: 0.7em;
  }

  @media (max-width: 375px) {
    font-size: 0.7em;
  }
`

const Username = styled(Name)``

const Competency = styled(Name)``

const Actions = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 375px) {
    margin-left: -0.4em;
  }
`

const EditImage = styled.img`
  margin-right: 0.8em;
  cursor: pointer;

  @media (max-width: 375px) {
    margin-right: 0.3em;
  }
`

const PasswordImage = styled.img`
  cursor: pointer;
`

const StyledNA = styled(Name)`
  width: 4.5em;
`
