import { gql } from '@apollo/client'

export interface UserType {
  id: number
  displayName: string
  username: string
  adminLevel: string
}

export default gql`
  query users($paranoid: Boolean) {
    users(paranoid: $paranoid) {
      id
      displayName
      adminLevel
      username
    }
  }
`
