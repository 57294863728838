import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import Select, { components, CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select'
import { SelectInputType } from '../../../constants/formsData'
import InputText from './InputText'
import { HandleChangeEvent } from './Input'
import { OptionsType } from './OptionsType'
import useLogin from '../../../hooks/useLogin'

interface Props {
  item: SelectInputType
  value: string
  handleChange: (item: SelectInputType, event: HandleChangeEvent) => void
  options?: OptionsType[]
  fullWidth?: boolean
  disabled?: boolean
  searchable?: boolean
}

export default ({
  item,
  value,
  handleChange,
  options,
  fullWidth,
  disabled,
  searchable = false
}: Props) => {
  const { USER, ACCOUNTANT, ADMIN } = useLogin()
  const [menuOpened, setMenuOpened] = React.useState(false)
  const [valueState, setValueState] = React.useState<OptionsType | string | null | undefined>(value)

  if (!options) {
    return null
  }

  function filterOptions(options: OptionsType[], value: string): OptionsType[] {
    return options.filter((selectedOption: OptionsType) => selectedOption.value !== value)
  }

  function filterSearchableOptions(options: OptionsType[], value: string): OptionsType[] {
    return [
      ...options.filter((selectedOption: OptionsType) => selectedOption.value === value),
      ...options.filter((selectedOption: OptionsType) => selectedOption.value !== value),
    ]
  }

  function findValue(options: OptionsType[], value: string) {
    if (menuOpened && searchable) return null

    const updatedOptions = [...options, { value: 'Group', label: 'Group' }]
    return updatedOptions.find((selectedOption: OptionsType) => selectedOption.value === value)
  }

  function handleSelectChange(item: SelectInputType, selectedOption: OptionsType) {
    if (disabled) return

    handleChange(item, { target: { value: selectedOption ? selectedOption.value : '' } })
    setValueState(selectedOption)
  }

  useEffect(() => {
    if (typeof value === 'string' && value.length === 0) {
      handleChange(item, { target: { value: options[0].value } })
    }
    setValueState(findValue(options, value))
  }, [value])

  const DropdownIndicator = (props: any) => {
    if (disabled) return null
    return (
      <StyledDropdownIndicator {...props}>
        <Arrow />
      </StyledDropdownIndicator>
    )
  }

  function search(option: OptionsType, searchText: string) {
    const values = option.value.split(' ')
    for (const value of values) {
      if (
        value
          .toLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .startsWith(
            searchText
              .toLowerCase()
              .normalize('NFD')
              .replace(/\p{Diacritic}/gu, '')
          )
      ) {
        return true
      }
    }
    return false
  }

  return (
    <Container
      marginTop={item.marginTop}
      disabled={disabled}
      data-testid={`${item.text.toLowerCase()}-select`}
    >
      <InputText text={item.text} disabledText={disabled} />
      <StyledSelect
        width={item.width}
        mobileWidth={item.mobileWidth}
        components={{ DropdownIndicator }}
        height={item.height}
        maxMenuHeight={200}
        styles={selectStyles(disabled)}
        isSearchable={searchable}
        defaultValue={options[0]}
        value={menuOpened && searchable ? null : valueState}
        options={
          searchable
            ? filterSearchableOptions(options, value)
            : !disabled
            ? filterOptions(options, value)
            : options
        }
        onChange={(selectedOption: unknown) =>
          handleSelectChange(item, selectedOption as OptionsType)
        }
        onMenuOpen={() => {
          setMenuOpened(true)
        }}
        onMenuClose={() => {
          setMenuOpened(false)
        }}
        fullWidth={fullWidth}
        disabled={disabled && item.canBeDisabled}
        placeholder={'Select or search'}
        filterOption={search}
        menuShouldScrollIntoView={true}
      />
    </Container>
  )
}

interface ContainerProps {
  marginTop?: number
  disabled?: boolean
}

const Container = styled.div<ContainerProps>`
  display: flex;
  margin-top: ${({ marginTop }) => marginTop || 1}em;
  flex-direction: column;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

interface StyledSelectProps {
  width: number
  mobileWidth: number
  height: number
  fullWidth?: boolean
  disabled?: boolean
}

const StyledSelect = styled(Select)<StyledSelectProps>`
  width: ${props => (props.width ? props.width : 5)}em;
  height: ${props => (props.height ? props.height : 3)}em;
  line-height: 1.3125em;
  color: #6f6f6f;
  cursor: pointer;

  @media (max-width: 560px) {
    width: ${props => (props.mobileWidth ? props.mobileWidth : 5)}em;
  }

  ${props =>
    props.fullWidth &&
    css`
      width: auto;
    `}
  ${props =>
    props.disabled &&
    css`
      cursor: default;
    `}
`

const Arrow = styled.div`
  width: 20px;
  height: 20px;
  background-image: url('/img/select-arrow.svg');
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
`

const StyledDropdownIndicator = styled(components.DropdownIndicator)`
  pointer-events: none;
`

const selectStyles = (isDisabled?: boolean): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  return {
    indicatorSeparator: () => ({}),
    dropdownIndicator: (provided: any, state: { selectProps: { menuIsOpen: boolean } }) => ({
      ...provided,
      prop: '',
      transform: state.selectProps.menuIsOpen ? null : 'rotate(180deg)',
      position: 'relative',
      bottom: state.selectProps.menuIsOpen ? '3px' : '0',
      right: '0.2em'
    }),
    singleValue: (base: CSSObjectWithLabel) => ({
      ...base,
      pointerEvents: 'none',
      color: isDisabled ? '#C4C8CC' : 'white',
      '@media(max-width: 560px)': {
        fontSize: '0.85em'
      }
    }),
    option: (styles: CSSObjectWithLabel, state: { isFocused: boolean }) => ({
      ...styles,
      color: isDisabled ? '#C4C8CC' : 'white',
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 500,
      backgroundColor: state.isFocused ? '#636572' : '#4A4C57',
      cursor: 'pointer',
      borderRadius: '4px',
      padding: '0.6875em 1.1em',
      '&:focus': {
        backgroundColor: '#4A4C57'
      },
      '&:active': {
        backgroundColor: '#4A4C57'
      },
      '&:hover': {
        backgroundColor: '#636572'
      },
      '@media(max-width: 560px)': {
        fontSize: '0.75em'
      }
    }),
    noOptionsMessage: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: isDisabled ? '#C4C8CC' : 'white',
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 500,
      '@media(max-width: 560px)': {
        fontSize: '0.75em'
      }
    }),
    menu: (base: CSSObjectWithLabel) => ({
      ...base,
      position: 'absolute',
      border: 'none',
      width: '100%',
      borderRadius: '4px',
      marginTop: '0.2em',
      zIndex: 999,
      backgroundColor: '#4A4C57',
      boxShadow: '-8px -2px 16px 0 rgb(43 42 42 / 50%)'
    }),
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      boxShadow: 'none',
      color: isDisabled ? '#C4C8CC' : 'white',
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 500,
      backgroundColor: '#4A4C57',
      border: 'none',
      height: '3.3em',
      cursor: 'pointer'
    }),
    input: (base: CSSObjectWithLabel, state: { selectProps: { menuIsOpen: boolean } }) => ({
      ...base,
      color: isDisabled ? '#C4C8CC' : 'white',
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 500,
      caretColor: state.selectProps.menuIsOpen ? 'white' : 'transparent',
      marginLeft: '0.48125em',
      '& input': {
        font: 'inherit'
      }
    }),
    placeholder: (base: CSSObjectWithLabel) => ({
      ...base,
      marginLeft: '0.48125em'
    })
  }
}
