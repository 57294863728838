import React from 'react'
import styled from 'styled-components'

import useLogin from '../../../hooks/useLogin'
import { InvoiceFilesType, InvoiceType } from '../../../queries/invoices'
import { getFileExtension, getFilePath } from '../../../utils/functions'
import { Preview, PreviewImage, PreviewPdf } from '../Invoice/FilesPreview/FilesPreviewComponents'

interface Props {
  setFilePreview: React.Dispatch<React.SetStateAction<InvoiceFilesType | undefined>>
  setPreviewLoading: React.Dispatch<React.SetStateAction<boolean>>
  invoice: InvoiceType
  filePreview: InvoiceFilesType | undefined
  imageChanged?: number
}

const InvoiceDetailPreview = ({
  setFilePreview,
  setPreviewLoading,
  invoice,
  filePreview,
  imageChanged
}: Props) => {
  const { token } = useLogin()

  const handlePreviewClick = (file: InvoiceFilesType) => {
    if (filePreview === file) return
    setFilePreview(file)
    setPreviewLoading(true)
  }

  return (
    <PreviewContainer>
      {invoice.files.map(file => (
        <Preview onClick={() => handlePreviewClick(file)}>
          {getFileExtension(file) === '.pdf' ? (
            <PreviewPdf src={getFilePath(invoice, file, token!)} selected={filePreview === file} />
          ) : (
            <PreviewImage
              src={`${getFilePath(invoice, file, token!)}&imageChanged=${imageChanged}`}
              selected={filePreview === file}
            />
          )}
        </Preview>
      ))}
    </PreviewContainer>
  )
}

export default InvoiceDetailPreview

const PreviewContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;
  width: 21.5rem;

  > * {
    flex: 0 0 30%;
    max-width: 5rem;
  }

  @media (min-width: 560px) {
    > * {
      flex: 0 0 20%;
    }
  }
`
