import styled from 'styled-components'

export default styled.tr`
  height: 3.75em;

  @media (max-width: 645px) {
    width: 37em;
  }

  @media (max-width: 375px) {
    height: 2.5em;
    padding-left: 0;
  }
`
