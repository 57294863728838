function getActiveFilterText(filterBy: string[]) {
  let onlyFilterText: string[] = []

  filterBy.map((item: string) => {
    if (item.includes('_id')) {
      return onlyFilterText.push(item.replace('_id', ''))
    }

    return onlyFilterText.push(item)
  })
  return onlyFilterText
}

export default getActiveFilterText
