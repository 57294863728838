import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled, { StyledComponent } from 'styled-components'
import StyledSectionHeader from '../../layout/StyledSectionHeader'
import { headerData, HeaderItem } from '../../../constants/sectionsData'
import SectionHeaderItem from '../../layout/SectionHeaderItem'
import invoices, { InvoiceType } from '../../../queries/invoices'
import Invoice from './Invoice'
import Footer from '../../layout/Footer/InvoiceSectionFooter'
import invoicesSum from './functions/invoicesSum'
import FilterSection from './FilterSection'
import getFilterData from './functions/getFilterData'
import MainTitle from '../../layout/MainTitle'
import getImageForHeaderItem from '../../layout/getImageForHeaderItem'
import handleSort from '../../layout/handleSort'
import ConnectedButtons from '../../layout/ConnectedButtons'
import NoInvoicesFound from './NoInvoicesFound'
import { useNavigate, useLocation, useParams } from 'react-router'
import { ParamsType } from '../../layout/ParamsType'
import { useMutation } from '@apollo/react-hooks'
import approveInvoices from '../../../mutations/approveInvoices'
import disapproveInvoices from '../../../mutations/disapproveInvoices'
import deleteInvoices from '../../../mutations/deleteInvoices'
import getActiveFilterText from './functions/getActiveFilterText'
import summary from '../../../queries/summary'
import useLogin from '../../../hooks/useLogin'
import ContextMenu from '../../layout/InvoiceContextMenu/ContextMenu'
import { contextMenu } from 'react-contexify'
import EmptyMonthFooter from '../../layout/Footer/EmptyMonthFooter'
import Search from '../../layout/Search'
import { selectOptionsInterface } from '../../../constants/types'

const queryString = require('query-string')

interface Props {
  titleText: string
  containerRef: any
  invoicesData: InvoiceType[]
  invoicesForDetail: InvoiceType[]
  selectOptions: selectOptionsInterface
  Component: StyledComponent<'section', any, {}, never>
  pendingSectionFooter: boolean
  displayFooterMobile: boolean
  variables: any
  queries: any
  totalCost: boolean
  handleConnectedButtonRightClick: () => void
  handleConnectedButtonLeftClick: () => void
  closedMonth: boolean
  yearMonth: string
  closeMonthButtonActive: boolean
  handleExportButtonClick: (withText: boolean) => JSX.Element | 'Export'
  handleMonthClose: () => void
  handleMonthReOpen: () => void
  displaySearch?: boolean
  userQuery?: string | null
}

export type ContextMenuEvent = MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent

export default (props: Props) => {
  const navigate = useNavigate()
  const params = useParams<ParamsType>()
  const location = useLocation()
  const variableObject = props.variables.variables
  const { ADMIN } = useLogin()
  const [activeInvoice, setActiveInvoice] = useState<InvoiceType>()
  const [searchQuery, setSearchQuery] = useState('')
  const [issuerRow, setIssuerRow] = useState<boolean>(false)
  const [detailOnly, setDetailOnly] = useState<boolean | undefined>(undefined)
  const [contextGroupInvoice, setContextGroupInvoice] = useState<boolean | undefined>(undefined)
  const [filteredInvoiceData, setFilteredInvoiceData] = useState<InvoiceType[]>([])

  useEffect(() => {
    const invoicesData = props.invoicesData.filter((invoice: InvoiceType) => {
      if (invoice.isSalary && props.userQuery) {
        return invoice.user.id.toString() === props.userQuery
      }
      return true
    })
    setFilteredInvoiceData(invoicesData)
  }, [props.invoicesData, searchQuery, props.userQuery])

  const [approveInvoice] = useMutation(approveInvoices, {
    refetchQueries: [
      {
        query: invoices,
        ...props.variables,
      },
      {
        query: summary,
        variables: { yearMonth: props.yearMonth, sortBy: 'basePrice', order: 'ASCENDING' },
      },
    ],
  })
  const [disapproveInvoice] = useMutation(disapproveInvoices, {
    refetchQueries: [
      {
        query: invoices,
        ...props.variables,
      },
      {
        query: summary,
        variables: { yearMonth: props.yearMonth, sortBy: 'basePrice', order: 'ASCENDING' },
      },
    ],
  })

  const [removeInvoices] = useMutation(deleteInvoices, {
    refetchQueries: [
      {
        query: invoices,
        ...props.variables,
      },
    ],
  })

  const [footerIsSticky, setFooterIsSticky] = useState(false)
  const [checked, setChecked] = useState<string[]>([])
  const allSelected = checked.length === props.invoicesData.length

  const ref = useRef<HTMLDivElement>(null)

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>, invoice: InvoiceType) {
    const index = checked.findIndex((id) => id === invoice.id)

    if (index === -1) {
      setChecked((ids) => [...ids, invoice.id])
    } else {
      setChecked((ids) => ids.filter((id) => invoice.id !== id))
    }
  }

  function handleActionClick(invoiceId: string, menuOpening: boolean) {
    const index = checked.findIndex((id) => id === invoiceId)

    if (index === -1 && menuOpening) {
      setChecked((ids) => [...ids, invoiceId])
    } else if (!menuOpening && checked.length === 1) {
      setChecked((ids) => ids.filter((id) => invoiceId !== id))
    }
  }

  function handleScroll() {
    if (ref.current) {
      const distanceFromBottomElement =
        window.innerHeight - ref.current.getBoundingClientRect().bottom
      setFooterIsSticky(
        distanceFromBottomElement < 0 && !footerIsSticky ? true : footerIsSticky && false
      )
    }
  }

  useEffect(() => {
    props.containerRef.current &&
      props.containerRef.current.addEventListener('scroll', handleScroll)
    return () => {
      props.containerRef.current &&
        props.containerRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    setFooterIsSticky(false)
  }, [params.month])

  function getSearchParams(): ('totalPrice' | 'realPrice' | 'documentNumber')[] {
    const searchParams: ReturnType<typeof getSearchParams> = [
      props.totalCost ? 'totalPrice' : 'realPrice',
    ]

    if (props.closedMonth || props.yearMonth.length === 4) searchParams.push('documentNumber')

    return searchParams
  }

  function handleApprove(ids: string[]) {
    return approveInvoice({ variables: { ids: ids } }).finally(function () {
      setChecked([])
    })
  }

  function handleUnapprove(ids: string[]) {
    return disapproveInvoice({ variables: { ids: ids } }).finally(() => setChecked([]))
  }

  async function handleDelete(ids: string[]) {
    await removeInvoices({ variables: { ids } })
    setChecked([])
    navigate({ pathname: location.pathname || '', search: location.search })
  }

  function getSelectedInvoicesCategories() {
    const categories: string[] = []

    checked.map((id: string) =>
      props.invoicesData.map((invoice: InvoiceType) => {
        if (id === invoice.id) {
          categories.push(invoice.category.displayName)
        }
      })
    )

    return categories
  }

  function getSelectedInvoicesStatuses() {
    const statuses: string[] = []

    checked.map((id: string) =>
      props.invoicesData.map((invoice: InvoiceType) => {
        if (id === invoice.id) {
          statuses.push(invoice.status.displayName)
        }
      })
    )

    return statuses
  }

  const paidInvoices = props.invoicesData.filter(
    (item: InvoiceType) => item.status.paid || item.isSalary
  )

  function selectAllInvoices() {
    const ids = allSelected ? [] : props.invoicesData.map(({ id }) => id)

    setChecked(ids)
  }

  function handleFilter(filterText: string, filterId?: number | undefined) {
    const query = queryString.parse(location.search)
    if (query[filterText]) {
      delete query[filterText]
    } else {
      query[filterText] = filterId
    }

    navigate(`?${queryString.stringify(query)}`)
  }

  function handleRightClick(
    event: ContextMenuEvent,
    invoice: InvoiceType,
    issuerRow?: boolean,
    isGroupInvoice?: boolean
  ) {
    event.preventDefault()
    setActiveInvoice(invoice)
    setDetailOnly(issuerRow && !ADMIN)
    setContextGroupInvoice(isGroupInvoice)
    contextMenu.show({ id: props.titleText, event })
  }

  function search(items: InvoiceType[]) {
    return items.filter((item: InvoiceType) =>
      getSearchParams().some((newItem) =>
        item[newItem]
          ? item[newItem].toString().toLowerCase().includes(searchQuery.toLowerCase())
          : false
      )
    )
  }

  const SectionHeaderItemComponent = (item: HeaderItem) => (
    <SectionHeaderItem
      item={item}
      key={item.text}
      handleSort={() => handleSort(item, variableObject, navigate, location)}
      image={getImageForHeaderItem(item.text, variableObject.sortBy, variableObject.order)}
      activeFilterTexts={getActiveFilterText(variableObject.filterBy)}
      selectAllInvoices={selectAllInvoices}
      isAllInvoicesSelected={allSelected}
    />
  )
  function renderSectionHeader() {
    return headerData.map((item: HeaderItem, index: number) => {
      if (item.text === 'ALL') {
        return (
          <NoScrollable
            width={105.3}
            mobileWidth={10}
            key={`${item.text}_${index}`}
            closedMonth={props.closedMonth}
            aria-label={`select all ${props.titleText.toLowerCase()} invoices`}
          >
            {SectionHeaderItemComponent(item)}
          </NoScrollable>
        )
      }

      if (item.onlyOnMobile === false) {
        return (
          <DesktopOnlyHeaderItem
            width={item.width || 0}
            mobileWidth={item.mobileWidth || 0}
            key={`${item.text}_${index}`}
          >
            {SectionHeaderItemComponent(item)}
          </DesktopOnlyHeaderItem>
        )
      }

      if (item.onlyOnMobile) {
        return (
          <MobileOnlyHeaderItem
            width={item.width || 0}
            mobileWidth={item.mobileWidth || 0}
            key={`${item.text}_${index}`}
          >
            {SectionHeaderItemComponent(item)}
          </MobileOnlyHeaderItem>
        )
      }
      return (
        <SectionHeaderRow
          width={item.width || 0}
          mobileWidth={item.mobileWidth || 0}
          key={`${item.text}_${index}`}
        >
          {SectionHeaderItemComponent(item)}
        </SectionHeaderRow>
      )
    })
  }

  function renderInvoiceLine() {
    return headerData.map((item: HeaderItem, index: number) => {
      if (item.text === 'ALL') {
        return (
          <NoScrollableLine
            width={item.width || 0}
            mobileWidth={item.mobileWidth || 0}
            key={`${item.text}Line_${index}`}
          >
            <InvoiceLineAll
              style={{ marginLeft: item.marginLeft || 0, marginRight: item.marginRight || 0 }}
            />
          </NoScrollableLine>
        )
      }

      if (item.onlyOnMobile === false) {
        return (
          <DesktopOnlyInvoiceLine key={`${item.text}Line_${index}`}>
            <InvoiceLine
              style={{ marginLeft: item.marginLeft || 0, marginRight: item.marginRight || 0 }}
            />
          </DesktopOnlyInvoiceLine>
        )
      }

      if (item.onlyOnMobile) {
        return (
          <MobileOnlyInvoiceLine key={`${item.text}Line_${index}`}>
            <InvoiceLine
              style={{ marginLeft: item.marginLeft || 0, marginRight: item.marginRight || 0 }}
            />
          </MobileOnlyInvoiceLine>
        )
      }
      return (
        <td style={{ padding: 0 }} key={`${item.text}Line_${index}`}>
          <InvoiceLine
            style={{ marginLeft: item.marginLeft || 0, marginRight: item.marginRight || 0 }}
          />
        </td>
      )
    })
  }

  if (props.pendingSectionFooter && !params.month && params.year) {
    return null
  }

  const mobileConnectedButtons = (
    <Hidden>
      <ConnectedButtons
        rightButtonActive={!props.totalCost}
        leftButtonActive={props.totalCost}
        leftButtonOnClick={props.handleConnectedButtonLeftClick}
        rightButtonOnClick={props.handleConnectedButtonRightClick}
      />
    </Hidden>
  )
  return (
    <>
      <TitleContainer>
        <MainTitle text={props.titleText} />
        <RightSection>
          {props.displaySearch && (
            <Search
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              clearSearch={() => setSearchQuery('')}
            />
          )}
          {mobileConnectedButtons}
          {!props.queries.category && !props.queries.status && !props.queries.user && (
            <HiddenMobile>
              <ConnectedButtons
                rightButtonActive={!props.totalCost}
                leftButtonActive={props.totalCost}
                leftButtonOnClick={props.handleConnectedButtonLeftClick}
                rightButtonOnClick={props.handleConnectedButtonRightClick}
              />
            </HiddenMobile>
          )}
        </RightSection>
      </TitleContainer>
      <FilterSection
        invoiceData={props.invoicesData}
        totalPrice={props.totalCost}
        handleConnectedButtonLeftClick={props.handleConnectedButtonLeftClick}
        handleConnectedButtonRightClick={props.handleConnectedButtonRightClick}
        handleFilter={handleFilter}
        invoiceFilterValues={getFilterData(
          props.queries.category,
          props.queries.status,
          props.queries.user
        )}
      />
      {filteredInvoiceData.length !== 0 ? (
        <props.Component ref={ref}>
          <Scrollable>
            <SectionTable>
              <thead>
                <StyledSectionHeaderInvoice>{renderSectionHeader()}</StyledSectionHeaderInvoice>
              </thead>
              <tbody>
                {(searchQuery.length > 0 ? search(filteredInvoiceData) : filteredInvoiceData).map(
                  (invoice: InvoiceType) => (
                    <Invoice
                      key={invoice.id}
                      invoice={invoice}
                      isGroupInvoice={invoice.issuers && invoice.issuers.length > 1}
                      renderInvoiceLine={renderInvoiceLine}
                      handleCheckboxChange={handleCheckboxChange}
                      invoiceCount={checked.length}
                      handleDelete={handleDelete}
                      handleApprove={handleApprove}
                      handleUnapprove={handleUnapprove}
                      totalCost={props.totalCost}
                      selectedInvoicesIds={checked}
                      selectedInvoiceCategories={getSelectedInvoicesCategories()}
                      selectedInvoicesStatuses={getSelectedInvoicesStatuses()}
                      variables={props.variables}
                      handleFilter={handleFilter}
                      isChecked={checked.includes(invoice.id)}
                      closedMonth={props.closedMonth}
                      handleActionClick={handleActionClick}
                      invoicesForDetail={props.invoicesForDetail}
                      onContextMenu={handleRightClick}
                      selectOptions={props.selectOptions}
                      titleText={props.titleText}
                      setIssuerRow={setIssuerRow}
                    />
                  )
                )}
              </tbody>
            </SectionTable>
          </Scrollable>
          <Footer
            pendingSectionFooter={props.pendingSectionFooter}
            invoicesSum={invoicesSum(props.invoicesData, props.totalCost)}
            handleApprove={checked.length > 0 && ADMIN ? () => handleApprove(checked) : null}
            approveButtonActive={checked.length > 0 && !!ADMIN}
            isSticky={footerIsSticky}
            displayMobile={props.displayFooterMobile}
            invoicesPaidSum={invoicesSum(paidInvoices, props.totalCost)}
            closedMonth={props.closedMonth}
            closeMonthButtonActive={props.closeMonthButtonActive}
            handleExportButtonClick={(withText) => props.handleExportButtonClick(withText)}
            handleMonthClose={props.handleMonthClose}
            handleMonthReOpen={props.handleMonthReOpen}
            totalCost={props.totalCost}
          />
        </props.Component>
      ) : (
        <>
          <NoInvoicesFound
            forSummary={false}
            straightCorners={ADMIN && !props.pendingSectionFooter}
          />
          <EmptyMonthFooter
            pendingSectionFooter={props.pendingSectionFooter}
            displayMobile={props.displayFooterMobile}
            closedMonth={props.closedMonth}
            closeMonthButtonActive={props.closeMonthButtonActive}
            handleMonthClose={props.handleMonthClose}
            handleMonthReOpen={props.handleMonthReOpen}
          />
        </>
      )}
      <ContextMenu
        id={props.titleText}
        onHidden={() => console.log()}
        approvedInvoice={(activeInvoice && activeInvoice.approved) || false}
        unapproveInvoices={handleUnapprove}
        deleteInvoices={handleDelete}
        approveInvoices={handleApprove}
        invoiceId={(activeInvoice && activeInvoice.id) || ''}
        invoiceCategoryId={(activeInvoice && activeInvoice.category.id) || 1}
        invvoiceStatusId={(activeInvoice && activeInvoice.status.id) || 1}
        variables={props.variables}
        selectedInvoicesIds={checked}
        invoiceCount={checked.length}
        closedMonth={props.closedMonth}
        issuerRow={issuerRow}
        detailOnly={detailOnly ?? false}
        groupInvoice={contextGroupInvoice}
      />
    </>
  )
}
interface HeaderProps {
  width: number
  mobileWidth: number
  closedMonth?: boolean
}

const Scrollable = styled.div`
  @media (max-width: 645px) {
    width: 100vw;
    overflow-x: auto;
  }
`

export const SectionTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  overflow: hidden;

  @media (max-width: 645px) {
    overflow-x: scroll;
  }
`
export const SectionHeaderRow = styled.th<HeaderProps>`
  width: ${({ width }) => width}px;

  @media (max-width: 830px) {
    width: ${({ mobileWidth }) => mobileWidth}px;
  }

  @media (max-width: 645px) {
    min-width: 6em;
  }
`

const InvoiceContainer = styled.div``

const TitleContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 645px) {
    margin: 0 20px;
  }

  @media (max-width: 375px) {
    margin-right: 14px;
  }
`

const RightSection = styled.section`
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const HiddenMobile = styled.div`
  @media (max-width: 540px) {
    display: none;
  }
`

const Hidden = styled.div`
  display: none;

  @media (max-width: 540px) {
    display: block;
    margin-bottom: 0.2em;
  }
`

const StyledSectionHeaderInvoice = styled(StyledSectionHeader)`
  @media (max-width: 645px) {
    width: 41em;
  }

  @media (max-width: 375px) {
    width: 44em;
  }
`

export const InvoiceLine = styled.div`
  height: 0px;
  border-top: 1px solid #535562;
  margin: 0;
  padding: 0;
`
const InvoiceLineAll = styled(InvoiceLine)`
  @media (max-width: 645px) {
    width: 100%;
    margin-left: 14px !important;
  }
`

export const MobileOnlyHeaderItem = styled(SectionHeaderRow)`
  display: none;
  @media (max-width: 960px) {
    display: table-cell;
  }
`

export const DesktopOnlyHeaderItem = styled(SectionHeaderRow)`
  @media (max-width: 960px) {
    display: none;
  }
`

const MobileOnlyInvoiceLine = styled.td`
  padding: 0;
  display: none;
  @media (max-width: 960px) {
    display: table-cell;
  }
`

const DesktopOnlyInvoiceLine = styled.td`
  padding: 0;
  @media (max-width: 960px) {
    display: none;
  }
`
const NoScrollable = styled(SectionHeaderRow)`
  border-radius: 8px;

  @media (max-width: 645px) {
    height: 60px;
    display: table-cell;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    z-index: 4;
    position: sticky;
    min-width: 150px;
    left: 0px;
    background-color: #3a3c45;
  }

  @media (max-width: 375px) {
    min-width: 110px;
    max-width: 80px;
  }
`

const NoScrollableLine = styled(NoScrollable)`
  padding: 0;

  @media (max-width: 645px) {
    height: 0px;
  }
`
