import React from 'react'
import styled from 'styled-components'
import { RadioButtonType } from '../../../constants/formsData'
import { HandleChangeEvent } from './Input'

interface Props {
  item: RadioButtonType
  handleRadioButtonChange?: (event: HandleChangeEvent) => void
  disabled?: boolean
  value?: string
  index?: number
}

export default ({ item, handleRadioButtonChange, disabled, value, index }: Props) => {
  const [checked, setChecked] = React.useState<boolean | undefined>(value === item.text)

  React.useEffect(() => {
    setChecked(value === item.text)
  }, [value, item.text])

  if (!handleRadioButtonChange) {
    return null
  }

  return (
    <Container className="radio-container">
      <RadioButton
        type="radio"
        name={index ? `info-${index}` : 'info'}
        value={item.text}
        disabled={disabled}
        onChange={(event) => handleRadioButtonChange(event)}
        checked={checked}
      />
      <label htmlFor="info" />
      <InputTextRadioButton disabled={disabled}>{item.text}</InputTextRadioButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: -1em;
  margin-top: 0.5em;
  margin-bottom: 2.2em;
`

interface InputTextRadioButton {
  disabled?: boolean
}

const InputTextRadioButton = styled.span<InputTextRadioButton>`
  color: ${(props) => (props.disabled ? '#6D7481' : 'white')};
  font-family: Montserrat;
  font-size: 0.875rem;
  margin-left: 0.5em;

  @media (max-width: 560px) {
    font-size: 0.75em;
    position: relative;
    top: 0.2em;
  }
`

interface RadioButtonProps {
  disabled?: boolean
}

const RadioButton = styled.input<RadioButtonProps>`
  display: flex;
  opacity: 0;
  position: relative;
  left: 1.5em;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  + label {
    background-image: url('/img/radiobutton-unchecked${({ disabled }) =>
      disabled && '-disabled'}.svg');
    background-size: 100%;
    height: 20px;
    width: 20px;
    display: inline-block;
    padding: 0;
  }

  &:checked + label {
    background: url('/img/radiobutton-checked${({ disabled }) => disabled && '-disabled'}.svg')
      no-repeat;
    display: inline-block;
    padding: 0;
    background-size: 100%;
  }
`
