import { gql } from '@apollo/client'

export default gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        id
        username
        displayName
        keyName
        adminLevel
        notify
        email
        createdAt
        updatedAt
      }
    }
  }
`
