import React from 'react'
import Main from './Main'
import Header from '../../layout/Header'
import NoOverflowContainer from '../../layout/NoOverflowContainer'

export default () => {
  return (
    <NoOverflowContainer>
      <Header />
      <Main />
    </NoOverflowContainer>
  )
}
