import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import { ParamsType } from '../../../layout/ParamsType'
import styled from 'styled-components'
import axios from 'axios'
import { LocationType } from '../../../../constants/types'
import BigOrangeButton from '../../../layout/BigOrangeButton'
import DatePicker from '../../../layout/Calendar/DatePicker'
import Header from '../../../layout/CenterSectionHeader'
import disabledButtonStyles from '../../../layout/disabledButtonStyles'
import InputsContainer from '../../Invoice/InputsContainer'
import StyledHeader from '../../Invoice/StyledHeader'
import Dropzone from '../Dropzone'
import { TYPES } from '../../../../constants/formsData'
import Select from '../../../layout/Form/Select'
import handleHeaderClosePath from '../../../../utils/handleHeaderClose'
const _ = require('lodash')

type FilePreview = (File & { preview: string })[]

const ImportSalaries = () => {
  const params = useParams<ParamsType>()
  const location: LocationType = useLocation() as LocationType
  const navigate = useNavigate()
  const [files, setFiles] = useState<FilePreview>()

  const getDefaultStartDate = (): Date => {
    const today = new Date()

    const year = parseInt(params?.year ?? '') || today.getFullYear()
    const month = parseInt(params?.month ?? '') || today.getMonth() + 1

    if (month < 1 || month > 12) {
      return new Date(today.getFullYear(), today.getMonth(), 15)
    }

    return new Date(year, month - 1, 15)
  }

  const onSubmit = () => {
    if (!files) return

    const formData = new FormData()
    files.forEach(file => {
      formData.append('file', file)
    })

    try {
      axios({
        method: 'post',
        url: process.env.REACT_APP_GO_API_URL + '/upload',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(() => {
        navigate('/invoices')
      })
    } catch (error) {
      alert('Something went wrong')
    }
  }

  return (
    <MainContainer>
      <Items>
        <Header
          Container={StyledHeader}
          handleClose={() =>
            navigate(handleHeaderClosePath(location), {
              state: {
                previousPath: location.pathname,
                month: location.state.month,
                year: location.state.year
              }
            })
          }
          text={'Import Salaries'}
        />
        <StyledInputsContainer>
          <LeftSection>
            <Dropzone file={files} setFile={setFiles} />
          </LeftSection>
          <StyledRightSection>
            <DatePicker
              getDate={() => {}}
              disabled={true}
              defaultStartDate={getDefaultStartDate()}
            />
            <StyledSelect
              item={{
                type: TYPES.SELECT,
                text: 'STATUS',
                required: true,
                width: 21.5,
                mobileWidth: 18.25,
                height: 3.5,
                name: 'statusSelect',
                canBeDisabled: true
              }}
              value={'Paid'}
              handleChange={() => {}}
              options={[{ value: 'Paid', label: 'Paid' }]}
              fullWidth={true}
              disabled={true}
              searchable={false}
            />
            <StyledSelect
              item={{
                type: TYPES.SELECT,
                text: 'CATEGORY',
                required: true,
                width: 21.5,
                mobileWidth: 18.25,
                height: 3.5,
                name: 'categorySelect',
                canBeDisabled: true
              }}
              value={'Salary'}
              handleChange={() => {}}
              options={[{ value: 'Salary', label: 'Salary' }]}
              fullWidth={true}
              disabled={true}
              searchable={false}
            />
            <SubmitButton active={true} onClick={onSubmit}>
              IMPORT INVOICES
            </SubmitButton>
          </StyledRightSection>
        </StyledInputsContainer>
      </Items>
    </MainContainer>
  )
}

export default ImportSalaries

const StyledInputsContainer = styled(InputsContainer)`
  flex-direction: row;

  @media (max-width: 860px) {
    flex-direction: column;
  }
`

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2em 0;

  @media (max-width: 560px) {
    margin: 0;
  }
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
`

const LeftSection = styled.div`
  margin-left: 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 860px) {
    margin-left: 0em;
    margin-bottom: 2em;
  }
`

const RightSection = styled(LeftSection)`
  margin-left: 4em;

  @media (max-width: 860px) {
    margin-left: 0.5em;
  }
`

export const LoaderOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.31);
  display: flex;
  justify-content: center;
  align-items: center;
`

interface SubmitButtonProps {
  active: boolean
}

const SubmitButton = styled(BigOrangeButton)<SubmitButtonProps>`
  pointer-events: initial;

  @media (max-width: 560px) {
    width: 21em;
  }

  ${({ active }) => !active && disabledButtonStyles}
`

const StyledSelect = styled(Select)`
  pointer-events: none;
`

const StyledRightSection = styled(RightSection)`
  pointer-events: none;

  @media (min-width: 860px) {
    margin-right: 4rem;
  }
`
