import React from 'react'
import Dropdown from 'rc-dropdown'
import 'rc-dropdown/assets/index.css'
import '../../Dropdown.css'
import DownloadExportSection from './DownloadExportSection'
import { StyledButton } from '../../DropdownStyledComponents'
import styled from 'styled-components'

interface Props {
  exportFilePath: string
  exportButtonActive: boolean
}

export default ({ ...props }: Props) => {
  return (
    <Dropdown trigger={['click']} overlay={<DownloadExportSection {...props} />}>
      <SmallButton>. . .</SmallButton>
    </Dropdown>
  )
}

const SmallButton = styled(StyledButton)`
  background-color: transparent;
  width: 4em;
`
