import React, { useEffect, useState } from 'react'
import PopUpButtons from '../../../layout/PopUps/PopUpButtons'
import Center from '../../../layout/PopUps/Center'
import BottomSection from '../../../layout/PopUps/BottomSection'
import TopSectionPopUp from '../../../layout/PopUps/TopSectionPopUp'
import StyledContainer from '../../../layout/PopUps/StyledContainer'
import styled, { css } from 'styled-components'
import FullViewportOverlay from '../../../layout/FullViewportOverlay'
import { FormDataType } from '../../../../constants/formsData'
import { HandleChangeEvent, SelectInput } from '../../../layout/Form/Input'
import { OptionsType } from '../../../layout/Form/OptionsType'
import { InputValueStateType } from '../../../../constants/types'

interface Props {
  titleText: string
  orangeButtonText: string
  orangeButtonFunction: () => void
  handleClose: () => void
  closed: boolean
  data: FormDataType[]
  actualCategory?: any
  getInputValue: (inputValue: InputValueStateType) => void
  error: boolean
  errorKindText: string
  forCurrency?: boolean
  editInputValues?: InputValueStateType
  forUser?: boolean
  forAddUser?: boolean
  forPassword?: boolean
  options?: OptionsType[]
  orangeButtonActive: boolean
  closeButton?: boolean
  transparentButtonFunction?: () => void
  transparentButtonText?: string
  transparentButtonActive?: boolean
  forCategory?: boolean
  keepData?: boolean
}

export default (props: Props) => {
  const [inputValue, setInputValue] = useState<InputValueStateType>({})

  const firstSelectOptions = {
    taxationSelect: {
      value: 'Taxable',
      isInvalid: false
    },
    paidSelect: {
      value: 'Paid',
      isInvalid: false
    },
    competencySelect: { value: 'user', isInvalid: false }
  }

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        props.handleClose()
      }
    }

    window.addEventListener('keydown', onKeyDown)

    return () => window.removeEventListener('keydown', onKeyDown)
  }, [])

  useEffect(() => {
    props.getInputValue(inputValue)
  }, [inputValue])

  useEffect(() => {
    if (props.closed && Object.entries(inputValue).length !== 0 && !props.keepData) {
      setInputValue({})
    }
  }, [props.closed, inputValue, props.keepData])

  if (props.closed) {
    return null
  }

  function handleChange(item: FormDataType, event: HandleChangeEvent) {
    const inputData = {
      ...inputValue,
      ...{
        [item.name]: {
          value: event.target.value,
          isInvalid:
            (item.required && event.target.value === '') ||
            (item.regExp && !item.regExp.test(event.target.value)) ||
            props.error
        }
      }
    }
    setInputValue(inputData)
  }

  if (
    props.titleText.toLowerCase().includes('edit') &&
    Object.entries(inputValue).length === 0 &&
    props.editInputValues
  ) {
    setInputValue(props.editInputValues)
  }

  if (
    Object.entries(inputValue).length === 0 &&
    !props.titleText.toLowerCase().includes('edit') &&
    props.options
  ) {
    setInputValue({ ...firstSelectOptions })
  }

  return (
    <>
      <FullViewportOverlay />
      <Center>
        <StyledPopUpContainer forAddUser={!!props.forAddUser}>
          <TopSectionPopUp
            text={props.titleText}
            closeButton={props.closeButton}
            handleClose={props.handleClose}
          />
          <InputsBottomSection
            forAddUser={!!props.forAddUser}
            forUser={!!props.forUser}
            forPassword={!!props.forPassword}
          >
            <Inputs
              forUser={!!props.forUser}
              forAddUser={!!props.forAddUser}
              forPassword={!!props.forPassword}
            >
              {props.data.map((item: FormDataType) => (
                <SelectInput
                  key={item.name}
                  item={item}
                  isInvalid={inputValue[item.name] && inputValue[item.name].isInvalid}
                  value={inputValue[item.name] && inputValue[item.name].value}
                  handleChange={handleChange}
                  mobileSmaller={false}
                  inputValue={inputValue}
                  options={props.options}
                  error={props.error}
                />
              ))}
              {props.error && !props.forPassword && (
                <ErrorText forCurrency={props.forCurrency} forCategory={props.forCategory}>
                  This {props.errorKindText} already exist
                </ErrorText>
              )}
              {props.error && props.forPassword && (
                <ErrorTextPassword>Passwords didn’t match</ErrorTextPassword>
              )}
            </Inputs>
            <Buttons
              forUser={!!props.forUser}
              forAddUser={!!props.forAddUser}
              forPassword={!!props.forPassword}
            >
              <PopUpButtons
                orangeButtonActive={props.orangeButtonActive}
                tranparentButtonText={props.transparentButtonText || 'Cancel'}
                transparentButtonActive={props.transparentButtonActive}
                orangeButtonText={props.orangeButtonText}
                orangeButtonFunction={props.orangeButtonFunction}
                transparentButtonFunction={props.transparentButtonFunction || props.handleClose}
                closeButton={props.closeButton}
              />
            </Buttons>
          </InputsBottomSection>
        </StyledPopUpContainer>
      </Center>
    </>
  )
}
interface StyledPopUpContainer {
  forAddUser?: boolean
}

const StyledPopUpContainer = styled(StyledContainer)<StyledPopUpContainer>`
  ${props =>
    props.forAddUser &&
    css`
      @media (max-height: 750px) {
        top: 14px;
      }
    `}
`

interface InputBottomSectionProps {
  forUser: boolean
  forAddUser: boolean
  forPassword: boolean
}

const InputsBottomSection = styled(BottomSection)<InputBottomSectionProps>`
  height: auto;
`

const Inputs = styled.section<InputBottomSectionProps>`
  width: 90%;
  margin-left: 4em;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 43px;

  > div > div {
    flex: 1;
  }

  @media (max-width: 750px) {
    margin-left: 0;
  }

  @media (max-width: 650px) {
    margin-top: 1em;
  }

  ${props =>
    props.forUser &&
    css`
      margin-top: 2em;
      margin-left: 1em;
    `}

  ${props =>
    props.forAddUser &&
    css`
      margin-top: 3em;
      margin-left: 1em;

      @media (max-width: 750px) {
        margin-top: 3em;
      }
    `}

  ${props =>
    props.forPassword &&
    css`
      margin-left: 0;
      margin-top: 2em;
    `}
`

const Buttons = styled.section<InputBottomSectionProps>`
  position: relative;
  right: -7.8em;
  margin: 26px 0 30px 0;

  @media (max-width: 750px) {
    right: -5.9em;
    margin: 20px 0 30px 0;
  }

  @media (max-width: 650px) {
    right: 0;
  }

  ${props =>
    props.forUser &&
    css`
      position: static;
    `}

  ${props =>
    props.forAddUser &&
    css`
      position: static;
    `}
    
    ${props =>
      props.forPassword &&
      css`
        margin-top: 1em;
        position: static;
        @media (max-width: 750px) {
          margin: 0 0 30px;
        }
      `}
`

interface ErrorTextProps {
  forCurrency?: boolean
  forCategory?: boolean
}

const ErrorText = styled.span<ErrorTextProps>`
  font-family: Montserrat;
  font-size: 0.81em;
  font-weight: 500;
  color: #fc6969;
  position: relative;
  height: 20px;
  top: -1.3em;

  ${props =>
    props.forCurrency
      ? css`
          width: 17.5em;
          right: 0;
        `
      : css`
          width: 100%;

          @media (max-width: 650px) {
            width: 17.5em;
            top: 0.3em;
            left: 0.5em;
          }
        `}
`

const ErrorTextPassword = styled.span`
  font-family: Montserrat;
  font-size: 0.81em;
  font-weight: 500;
  color: #fc6969;
  position: relative;
  height: 20px;
  top: -1.3em;
  width: 13rem;
`
