import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 20px 20px;
  background-color: #3a3c45;
  padding-left: 4em;
  padding-right: 4em;
  padding-bottom: 2em;
  padding-top: 2em;
  width: 48.3em;

  @media (max-width: 960px) {
    padding-left: 1.5em;
    padding-right: 0.5em;
  }

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: center;
    width: 95vw;
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 560px) {
    width: 97vw;
    padding-bottom: 0;
  }
`
