import React, { ReactNode, useContext } from 'react'
import { useMemo } from 'react'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { InMemoryCache, ApolloProvider, ApolloClient } from '@apollo/client'

import { resolvers, typeDefs } from './resolvers'
import { ErrorContext } from './context/errorContext'
import ErrorPopUp from './components/layout/PopUps/ErrorPopUp'
import { ClientName } from './constants/constants'
import { ApolloLink, HttpLink } from '@apollo/react-hooks'

const cache = new InMemoryCache()

const link = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
})

const goApiLink = new HttpLink({
  uri: `${process.env.REACT_APP_GO_API_URL}/query`,
})

let token: string

const authLink = setContext((_, { headers }) => {
  if (!token) {
    const loginData = localStorage.getItem('loginData')
    token = loginData ? JSON.parse(loginData).token : ''
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const CustomApolloProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const { closedPopUp, setClosedPopUp, setError, error } = useContext(ErrorContext)

  const errorLink = onError(({ graphQLErrors, networkError }): any => {
    const ignoredErrors = ['BAD_CREDENTIALS']
    // @ts-ignore
    if (graphQLErrors && graphQLErrors[0].errorKey) {
      // @ts-ignore
      if (graphQLErrors[0].errorKey === 'UNAUTHORIZED') {
        window.localStorage.clear()
        return window.location.reload()
      }
      // @ts-ignore
      if (!ignoredErrors.includes(graphQLErrors[0].errorKey)) {
        // @ts-ignore
        setError(graphQLErrors[0].message)
        setClosedPopUp(false)
      }
    } else {
      setError('Something went wrong.')
      setClosedPopUp(false)
    }
  })

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        cache,
        link: ApolloLink.split(
          (operation) => operation.getContext().clientName === ClientName.GO_API,
          errorLink.concat(authLink.concat(goApiLink)),
          errorLink.concat(authLink.concat(link))
        ),
        typeDefs,
        resolvers,
      }),
    [setError]
  )

  return (
    <ApolloProvider client={apolloClient}>
      <>
        <ErrorPopUp
          errorText={error}
          closed={closedPopUp}
          handleClosed={() => {
            setClosedPopUp(true)
          }}
        />
        {children}
      </>
    </ApolloProvider>
  )
}

export default CustomApolloProvider
