import React, { useEffect } from 'react'
import styled from 'styled-components'
import FullViewportOverlay from '../../FullViewportOverlay'
import TopSectionPopUp from '../TopSectionPopUp'
import { Text } from '../../Styled/Text'
import PopUpButtons from '../PopUpButtons'
import StyledContainer from '../StyledContainer'
import BottomSection from '../BottomSection'
import Center from '../Center'
import useOutsideClick from '../../../../hooks/useOutsideClick'

interface Props {
  closed: boolean
  isModalClosed?: boolean
  handleConfirm: () => void
  handleCancel: () => void
  customText?: string
}

export default ({ closed, isModalClosed, handleConfirm, handleCancel, customText }: Props) => {
  const ref = useOutsideClick(handleCancel)

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && !isModalClosed) {
        handleCancel()
      }
    }

    window.addEventListener('keydown', onKeyDown)

    return () => window.removeEventListener('keydown', onKeyDown)
  }, [])

  if (closed) {
    return null
  }

  return (
    <>
      <FullViewportOverlay />
      <Center>
        <StyledContainer ref={ref}>
          <TopSectionPopUp text={`Unsaved changes`} />
          <BottomSection>
            <CenterSection>
              <PopUpText>
                {customText ?? "You have some unsaved changes, are you sure you want to discard them?"}
              </PopUpText>
              <Buttons>
                <PopUpButtons
                  tranparentButtonText="Cancel"
                  orangeButtonText="OK"
                  orangeButtonFunction={handleConfirm}
                  transparentButtonFunction={handleCancel}
                  orangeButtonActive={true}
                />
              </Buttons>
            </CenterSection>
          </BottomSection>
        </StyledContainer>
      </Center>
    </>
  )
}

const CenterSection = styled.section`
  padding-left: 1em;
  padding-right: 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

const PopUpText = styled(Text)`
  font-weight: 500;
  display: inline-block;
  text-align: center;

  @media (max-width: 375px) {
    font-size: 0.81em;
  }
`

const Buttons = styled.div`
  margin: 0 auto;
`
