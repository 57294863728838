import React from 'react'
import styled from 'styled-components'
import TopSection from '../../../layout/TopSection'
import CustomSettingsSectionHeader from '../CustomSettingsSectionHeader'
import { HeaderItem } from '../../../../constants/sectionsData'
import StyledSettingItem from '../StyledSettingItem'
import { CategoryItem } from '../../../../queries/categories'
import { CurrencyItem } from '../../../../queries/currencies'
import { StatusItem } from '../../../../queries/statuses'
import { SectionTable, InvoiceLine } from '../../Items/InvoiceSection'

interface Props {
  headerTitle: string
  handleAdd: () => void
  handleDelete: () => void
  handleEdit: () => void
  headerData: HeaderItem[]
  data: Array<CurrencyItem | CategoryItem | StatusItem>
  forCategory?: boolean
  forStatus?: boolean
  forCurrency?: boolean
  nameProperty: string
  getId: (item: CurrencyItem | CategoryItem | StatusItem) => void
}

function renderSettingsLine(headerData: HeaderItem[]) {
  return headerData.map((item: HeaderItem) => {
    return (
      <td style={{ padding: 0 }}>
        <InvoiceLine
          style={{ marginLeft: item.marginLeft || 0, marginRight: item.marginRight || 0 }}
        />
      </td>
    )
  })
}

export default (props: Props) => {
  return (
    <Container>
      <TopSection
        handleAdd={props.handleAdd}
        mainTitleText={props.headerTitle}
        label={props.headerTitle.toLowerCase()}
      />
      <SettingsTable>
        <thead>
          <CustomSettingsSectionHeader headerData={props.headerData} />
        </thead>
        <tbody>
          {props.data.map((item: CurrencyItem | CategoryItem | StatusItem, i: number) => (
            <React.Fragment key={i}>
              <tr>{renderSettingsLine(props.headerData)}</tr>
              <StyledSettingItem
                getId={props.getId}
                item={item}
                nameProperty={props.nameProperty}
                forCategory={props.forCategory}
                forCurrency={props.forCurrency}
                forStatus={props.forStatus}
                handleDelete={props.handleDelete}
                handleEdit={props.handleEdit}
              />
            </React.Fragment>
          ))}
        </tbody>
      </SettingsTable>
    </Container>
  )
}

const Container = styled.section`
  width: 35.9em;

  @media (max-width: 645px) {
    margin: 0 20px;
  }

  @media (max-width: 645px) {
    width: auto;
    margin: 0 20px;
  }
`

const SettingsTable = styled(SectionTable)`
  background-color: #3a3c45;
  border-radius: 8px 8px 0 0;
  padding: 0 30px;

  @media (max-width: 420px) {
    padding: 0 10px;
  }
`
