import styled from 'styled-components'
import { Text } from './Styled/Text'

export default styled(Text)`
  font-size: 0.9em;
  margin-left: 1em;
  line-height: 0;
  font-weight: 500;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  cursor: pointer;
`
