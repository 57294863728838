import getActualMonth from '../components/screens/Items/functions/getActualMonth'
import getActualYear from '../components/layout/getActualYear'

export enum UserAdminLevel {
  ADMIN = 'ADMIN',
  ACCOUNTANT = 'ACCOUNTANT',
  USER = 'USER',
  INACTIVE_USER = 'INACTIVE_USER'
}

export enum ClientName {
  GO_API
}

export const SalaryProps = {
  categoryId: 23,
  statusId: 2
}

export const REDIRECT_WARNING_ROUTES = [
  '/addInvoice',
  '/addGroupInvoice',
  '/editInvoice',
  `/userSettings`
]

let zero = ''
let year = getActualYear()
let month = getActualMonth()

if (month === 0) {
  year = year - 1
  month = 11
}

if (month < 10) {
  zero = '0'
}

export const PAST_MONTH = `${year}-${zero}${month}`
