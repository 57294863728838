import { InvoiceType } from '../../../../queries/invoices'
import formatPrice from '../../../layout/formatPrice'

export default function invoicesSum(invoices: InvoiceType[], totalCost?: boolean) {
  return invoices.reduce((sum: number, currentValue: InvoiceType) => {
    if (currentValue.currency.symbol === 'CZK' || !totalCost)
      sum += totalCost
        ? parseFloat(currentValue.totalPrice.toString().replace(' ', ''))
        : parseFloat(currentValue.realPrice.toString().replace(' ', ''))

    return sum
  }, 0)
}
