import { gql } from '@apollo/client'

export interface StatusItem {
  [key: string]: string | number | boolean
  displayName: string
  id: number
  paid: boolean
}

export default gql`
  {
    statuses {
      displayName
      id
      paid
    }
  }
`
