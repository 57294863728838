import styled from 'styled-components'

export const Overlay = styled.div`
  opacity: 0.4;
  background: #000;
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  pointer-events: none;
`

interface ContainerProps {
  height?: number
}

export const Container = styled.div<ContainerProps>`
  height: ${props => props.height}em;
  background-color: #3a3c45;
  width: 100vw;
  border-radius: 1.25em 1.25em 0 0;
  position: fixed;
  bottom: 0;
  z-index: 999;
  padding-top: 1em;
  padding-bottom: 3em;
`

export const DownSection = styled.div`
  display: flex;
  width: 90vw;
  flex-direction: column;
  justify-content: flex-start;
`

export const LeftArrowImg = styled.div`
  transform: rotate(180deg);
  position: absolute;
  background-image: url('/img/arrow-right-dropdown.svg');
  background-repeat: no-repeat;
  background-position: center;
  left: 1.25em;
  top: 1.563em;
  background-color: rgba(216, 216, 216, 0.2);
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
`

export const StyledButton = styled.button`
  color: #9198a0;
  font-family: Montserrat;
  font-size: 0.875em;
  font-weight: 800;
  background-color: #535562;
  border: 1px solid #6d7481;
  border-radius: 0.5em;
  width: 6.9em;
  height: 2em;
  outline: none;
`
