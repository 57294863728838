import { LocationType } from '../constants/types'

const handleHeaderClosePath = (location: LocationType): string => {
  const ignoredPaths = ['/addInvoice', '/addGroupInvoice', '/import/salaries']

  return location.state &&
    location.state.previousPath &&
    !ignoredPaths.includes(location.state.previousPath.toString())
    ? location.state.previousPath.toString()
    : '/'
}

export default handleHeaderClosePath
