import React from 'react'
import styled from 'styled-components'
import Div100vh from 'react-div-100vh'

import Main from './Main'
import Header from '../../layout/Header/index'

export default () => {
  return (
    <StyledContainer>
      <Header />
      <Main />
    </StyledContainer>
  )
}

const StyledContainer = styled(Div100vh)`
  width: 100vw;
  overflow: hidden auto;
`
