import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Main from '../Main'
import Header from '../../../layout/Header/index'
import { useNavigate, useParams } from 'react-router'
import getActualMonth from '../../Items/functions/getActualMonth'
import getFirstOptions from '../../Items/functions/getFirstOptions'
import { useQuery } from '@apollo/react-hooks'
import users from '../../../../queries/users'
import invoice from '../../../../queries/invoice'
import Loader from '../../../layout/Loader'
import NoOverflowContainer from '../../../layout/NoOverflowContainer'
import getActualYear from '../../../layout/getActualYear'
import useLogin from '../../../../hooks/useLogin'
import dateFormat from 'dateformat'
import { UserType } from '../../../../constants/types'

export default () => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const { id: detailInvoiceId } = useParams()
  const navigate = useNavigate()
  const { data, loading, error } = useQuery(invoice, { variables: { id: detailInvoiceId } })
  const selectOptions = useQuery(users)

  const loginData = useLogin()

  if (!detailInvoiceId || error) {
    navigate(`/invoices/${getActualMonth() + 1}-${getActualYear()}`)
    return null
  }

  if (loading) {
    return <Loader />
  }

  const getFirstOptionsCallback = (selectOptions: UserType[]) => {
    if (data && selectOptions) {
      return getFirstOptions(data, selectOptions)
    }
  }

  return (
    <Container previewVisible={previewVisible}>
      <Header invoiceDate={data.invoice.date} />
      <Main
        fileType={data.invoice.fileType}
        previewFiles={data.invoice.files}
        editingInvoice={false}
        defaultStartDate={data ? new Date(dateFormat(data.invoice.date, 'mm/dd/yyyy')) : new Date()}
        editInvoiceFirstOptions={getFirstOptionsCallback}
        invoiceData={data.invoice}
        detail={true}
        onlyActionButtons={true}
        setPreviewVisible={setPreviewVisible}
        previewVisible={previewVisible}
        hideRotate={true}
        hideDelete={true}
      />
    </Container>
  )
}

interface ContainerProps {
  previewVisible: boolean
}

const Container = styled(NoOverflowContainer)<ContainerProps>`
  ${props =>
    props.previewVisible &&
    css`
      overflow: hidden;
    `}
`
