const getDecimalPlaces = (num: number): number => {
  const numStr = num.toString()
  const decimalIndex = numStr.indexOf('.')
  return decimalIndex === -1 ? 0 : numStr.length - decimalIndex - 1
}

const addFloats = (num1: number, num2: number): number => {
  const precision = Math.max(getDecimalPlaces(num1), getDecimalPlaces(num2))
  const scale = Math.pow(10, precision)
  return (num1 * scale + num2 * scale) / scale
}

export default addFloats
