import React from 'react'
import styled from 'styled-components'
import path from 'path-browserify'

import InvoiceDetail from '../../Items/InvoiceDetail'
import { FileResponse, FileTypes } from '../../../../constants/types'
import { InvoiceType } from '../../../../queries/invoices'

interface Props {
  handleRotate: () => void
  hideRotate?: boolean
  hideDelete?: boolean
  fileUrl: string
  filePreviewUrl: string
  fileTypes: FileTypes
  coloredButtons: boolean
  invoice: InvoiceType
  previewVisible: boolean
  setPreviewVisible: React.Dispatch<React.SetStateAction<boolean>>
  disabled?: boolean
  handleDelete: () => void
  displayDelete: boolean
  urlToDownload: string
  activePreview?: FileResponse
  imageChanged?: number
}

export default (props: Props) => {
  const fileExtension = path.extname(props.activePreview?.url ?? '').toLowerCase()

  const download = async (url: string) => {
    const file = await fetch(url)
    const fileBlob = await file.blob()
    const fileURL = URL.createObjectURL(fileBlob)

    const link = document.createElement('a')
    link.href = fileURL
    link.download = `${props.invoice.id}-${props.activePreview?.order ?? 0}${fileExtension}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <Container>
        <Download src="/img/download.svg" onClick={() => download(props.urlToDownload)} />
        <Zoom onClick={() => props.setPreviewVisible(true)} src="/img/zoom.svg" />
        {props.fileTypes.edit !== '.pdf' &&
          props.fileTypes.file !== 'application/pdf' &&
          !props.filePreviewUrl.startsWith('blob') &&
          !props.hideRotate && <Rotate onClick={props.handleRotate} src="/img/rotate.svg" />}
        {!props.filePreviewUrl.startsWith('blob') && props.displayDelete && !props.hideDelete && (
          <Delete onClick={props.handleDelete} src="/img/delete-icon-white.svg" />
        )}
      </Container>
      {props.previewVisible && (
        <InvoiceDetail
          handleClose={() => props.setPreviewVisible(false)}
          invoicesForDetail={[props.invoice]}
          invoiceId={props.invoice.id}
          disableDescription={true}
          imageChanged={props.imageChanged}
        />
      )}
    </>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 0.5em;
`

const Download = styled.img`
  width: 3em;
  height: 3em;
  cursor: pointer;
`

const Zoom = styled(Download)``

const Rotate = styled(Download)``

const Delete = styled(Download)``
