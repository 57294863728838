import { gql } from '@apollo/client'

export default gql`
  mutation addInvoice($data: AddInvoiceInput!, $issuers: [invoiceIssuerInputType!]!  $files: [Upload!]!${
    process.env.REACT_APP_ENABLE_TEST_INVOICES ? ', $count: Int' : ''
  }) {
    addInvoice(data: $data, issuers: $issuers, files: $files${
      process.env.REACT_APP_ENABLE_TEST_INVOICES ? ', count: $count' : ''
    }) {
      issuers {
          user {
             id
        }
      }
    }
  }
`
