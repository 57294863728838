import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Main from '../Main'
import Header from '../../../layout/Header/index'
import { useNavigate, useParams } from 'react-router'
import getActualMonth from '../../Items/functions/getActualMonth'
import { useQuery } from '@apollo/react-hooks'
import invoice from '../../../../queries/invoice'
import Loader from '../../../layout/Loader'
import NoOverflowContainer from '../../../layout/NoOverflowContainer'
import getActualYear from '../../../layout/getActualYear'
import getFirstOptions from '../../Items/functions/getFirstOptions'
import dateFormat from 'dateformat'
import { UserType } from '../../../../constants/types'

export default () => {
  const { id: invoiceId } = useParams()
  const [previewVisible, setPreviewVisible] = useState(false)
  const navigate = useNavigate()
  const { data, loading, error } = useQuery(invoice, { variables: { id: invoiceId } })

  if (!invoiceId || error) {
    navigate(`/invoices/${getActualMonth() + 1}-${getActualYear()}`)
    return null
  }

  if (loading) {
    return <Loader />
  }

  const getFirstOptionsCallback = (selectOptions: UserType[]) => {
    if (data && selectOptions) {
      return getFirstOptions(data, selectOptions)
    }
  }

  const invoiceDate = new Date()
  const invoiceDay = data.invoice.date.split('-')[2]
  invoiceDate.setDate(Number(invoiceDay))

  return (
    <Container previewVisible={previewVisible}>
      <Header invoiceDate={data.invoice.date} />
      <Main
        editingInvoice={false}
        hideDelete={true}
        duplicatingInvoice={true}
        defaultStartDate={invoiceDate}
        editInvoiceFirstOptions={getFirstOptionsCallback}
        invoiceData={data.invoice}
        setPreviewVisible={setPreviewVisible}
        previewVisible={previewVisible}
      />
    </Container>
  )
}

interface ContainerProps {
  previewVisible: boolean
}

const Container = styled(NoOverflowContainer)<ContainerProps>`
  ${(props) =>
    props.previewVisible &&
    css`
      overflow: hidden;
    `}
`
