import React from 'react'
import styled from 'styled-components'
import { StyledButton } from './Components'

interface Props {
  filterText: string
  handleFilterCancel: () => void
}

export default ({ filterText, handleFilterCancel }: Props) => {
  return (
    <Container>
      <StyledFilterText>{filterText}</StyledFilterText>
      <StyledButton onClick={handleFilterCancel}>
        <CancelFilterImage src="/img/cancel-filter.svg" />
      </StyledButton>
    </Container>
  )
}

const Container = styled.div`
  border-radius: 1.063em;
  background-color: #3a3c45;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5em;
  margin-top: 1em;
  margin-right: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;

  @media (max-width: 645px) {
    margin-right: 0.7em;
  }

  @media (max-width: 540px) {
    margin-right: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  &:hover {
    background-color: #535562;
  }
`

const StyledFilterText = styled.span`
  color: #cbcfd3;
  font-family: Montserrat;
  font-size: 0.75em;
  font-weight: 500;
  margin-right: 2.2em;

  @media (max-width: 645px) {
    margin-right: 1em;
  }
`

const CancelFilterImage = styled.img`
  cursor: pointer;
`
