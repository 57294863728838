import React from 'react'
import styled from 'styled-components'

interface Props {
  text: string
  disabledText?: boolean
}

export default ({ text, disabledText }: Props) => {
  return <InputText disabledText={disabledText}>{text}</InputText>
}

interface InputTextProps {
  disabledText?: boolean
}

const InputText = styled.span<InputTextProps>`
  display: flex;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.81em;
  line-height: 1em;
  justify-content: flex-start;
  color: ${props => (props.disabledText ? '#6D7481' : 'white')};
  padding-bottom: 0.8em;

  @media (max-width: 560px) {
    font-size: 0.688em;
  }
`
