import React, { useState, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { InvoiceType, InvoiceUserType } from '../../../queries/invoices'
import { ContextMenuEvent } from './InvoiceSection'
import { selectOptionsInterface, variablesType } from '../../../constants/types'
import StyledCheckbox from '../../layout/StyledCheckbox'
import Dropdown from '../../layout/ActionsDropdown/Dropdown'
import WithUnderline from '../../layout/WithUnderline'
import optionsData from '../../../utils/optionsData'
import {
  StyledGroupInvoice,
  Details,
  NoScrollable,
  CheckboxContainer,
  StickyContainer,
  StyledCategory,
  StyledStatus,
  StyledDate,
  StyledUser,
  DesktopOnly,
  MobileOnly,
  StyledPrice,
} from './Components'

interface InvoiceGroupSectionProps {
  issuer: InvoiceUserType
  invoice: InvoiceType
  isGroupInvoice: boolean
  isGroupInvoiceOpen: boolean
  renderInvoiceLine: () => JSX.Element[]
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, invoice: InvoiceType) => void
  handleActionClick: (invoiceId: string, menuOpening: boolean) => void
  handleDelete: (ids: string[]) => void
  handleApprove: (ids: string[]) => void
  handleUnapprove: (ids: string[]) => void
  invoiceCount: number
  totalCost: boolean
  selectedInvoiceCategories: string[]
  selectedInvoicesIds: string[]
  selectedInvoicesStatuses: string[]
  variables: variablesType
  handleFilter: (filterBy: string, filterId: number | undefined) => void
  isChecked: boolean
  closedMonth: boolean
  invoicesForDetail: InvoiceType[]
  onContextMenu: (event: ContextMenuEvent, invoice: InvoiceType) => void
  selectOptions: selectOptionsInterface
  titleText?: string
  setIssuerRow: Dispatch<SetStateAction<boolean>>
  renderDocumentNumber: () => JSX.Element
  handleRightClick: (event: ContextMenuEvent, isGroupInvoice: boolean) => void
  color: boolean
  deviceActions: () => JSX.Element
  setInvoiceDetailVisible: Dispatch<SetStateAction<boolean>>
  showInvoiceDetail: () => void
  getIssuerPrice: (issuer: InvoiceUserType) => string | number
  setIsIssuerHovered: Dispatch<SetStateAction<boolean>>
}

const InvoiceGroupSection = (props: InvoiceGroupSectionProps) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <StyledGroupInvoice
      data-testid={`${props.titleText?.toLowerCase()}-invoice-row`}
      colored={props.color}
      onContextMenu={(event: ContextMenuEvent) => props.handleRightClick(event, true)}
      onMouseEnter={() => {
        setIsHovered(true)
        props.setIsIssuerHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
        props.setIsIssuerHovered(false)
      }}
    >
      <NoScrollable>
        <StickyContainer>
          <CheckboxContainer isSalary={props.invoice.isSalary ?? false}>
            <StyledCheckbox
              type="checkbox"
              name="image"
              checked={props.isChecked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.handleCheckboxChange(event, props.invoice)
              }
            />
            <label htmlFor="image"></label>
          </CheckboxContainer>
          <Dropdown
            number={props.invoiceCount}
            invoiceId={props.invoice.id}
            unapproveInvoice={() => props.handleUnapprove(props.selectedInvoicesIds)}
            deleteInvoices={() => props.handleDelete(props.selectedInvoicesIds)}
            approveInvoices={() => props.handleApprove(props.selectedInvoicesIds)}
            approvedInvoice={props.invoice.approved}
            selectedInvoiceCategories={props.selectedInvoiceCategories}
            selectedInvoiceStatuses={props.selectedInvoicesStatuses}
            selectedInvoicesIds={props.selectedInvoicesIds}
            variables={props.variables}
            handleActionClick={props.handleActionClick}
            showInvoiceDetail={props.showInvoiceDetail}
            closedMonth={props.closedMonth}
          >
            {props.deviceActions()}
          </Dropdown>
        </StickyContainer>
      </NoScrollable>

      <DesktopOnly>{props.renderDocumentNumber()}</DesktopOnly>
      <td>
        <StyledDate>{props.invoice.date}</StyledDate>
      </td>
      <td>
        <StyledStatus>
          <WithUnderline onClick={() => props.handleFilter('statusId', props.invoice.status.id)}>
            {props.invoice.status.displayName}
          </WithUnderline>
        </StyledStatus>
      </td>
      <td>
        <StyledCategory>
          <WithUnderline onClick={() => props.handleFilter('categoryId', props.issuer.categoryId)}>
            {props.issuer.category.displayName}
          </WithUnderline>
        </StyledCategory>
      </td>
      <td>
        <StyledUser>
          <WithUnderline onClick={() => props.handleFilter('userId', props.issuer.user.id)}>
            {props.issuer.user.displayName}
          </WithUnderline>
        </StyledUser>
      </td>
      <td>
        <StyledPrice>
          {props.getIssuerPrice(props.issuer)}&nbsp;
          {props.totalCost ? props.invoice.currency.symbol : 'CZK'}
        </StyledPrice>
      </td>
      <DesktopOnly>
        <Details onClick={() => props.setInvoiceDetailVisible(true)}>Preview</Details>
      </DesktopOnly>
      <MobileOnly>{props.renderDocumentNumber()}</MobileOnly>
      <td></td>
    </StyledGroupInvoice>
  )
}

export default InvoiceGroupSection
