export enum TYPES {
  SELECT = 'select',
  TEXT = 'text',
  NUMBER = 'number',
  HORIZONTAL_BLOCK = 'horizontal_block',
  RADIO_BUTTON = 'radio_button',
  PASSWORD = 'password',
  TEXTAREA = 'textarea'
}

export interface FormDataItemType {
  text: string
  required: boolean
  width: number
  mobileWidth: number
  height: number
  name: string
  desktopHidden?: boolean
  regExp?: RegExp
}

export interface SelectInputType extends FormDataItemType {
  type: TYPES.SELECT
  marginTop?: number
  canBeDisabled?: boolean
}

export interface TextInputType extends FormDataItemType {
  type: TYPES.TEXT
  canBeDisabled?: boolean
  disabledText?: string
  marginTop?: number
}

export interface NumberInputType extends FormDataItemType {
  type: TYPES.NUMBER
  canBeDisabled?: boolean
  disabledText?: string
  marginTop?: number
}

export interface PasswordInputType extends FormDataItemType {
  type: TYPES.PASSWORD
  canBeDisabled?: boolean
  disabledText?: string
  marginTop?: number
}

export interface RadioButtonType extends FormDataItemType {
  type: TYPES.RADIO_BUTTON
  defaultChecked: boolean
}

export interface TextAreaInputType extends FormDataItemType {
  type: TYPES.TEXTAREA
}

export interface HorizontalBlockType {
  type: TYPES.HORIZONTAL_BLOCK
  required: boolean
  items: FormDataType[]
  name: string
  mobileHidden?: boolean
  regExp?: any
  mobileColumn?: boolean
}

export type FormDataType =
  | SelectInputType
  | TextInputType
  | RadioButtonType
  | HorizontalBlockType
  | PasswordInputType
  | TextAreaInputType
  | NumberInputType

const onlyNumberRegExp = /[+-]?([0-9]+\s*([.,][0-9]*)?|[.,][0-9]+)$/
const emailRegExp = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,6}))$/

export const loginFormData: FormDataType[] = [
  {
    type: TYPES.TEXT,
    text: 'USERNAME',
    required: true,
    width: 21.5,
    mobileWidth: 20.625,
    height: 2.875,
    name: 'username'
  },
  {
    type: TYPES.PASSWORD,
    text: 'PASSWORD',
    required: true,
    width: 21.5,
    mobileWidth: 20.625,
    height: 2.875,
    name: 'password'
  }
]

export const addInvoiceRightSectionData: FormDataType[] = [
  {
    type: TYPES.SELECT,
    text: 'STATUS',
    required: true,
    width: 21.5,
    mobileWidth: 18.25,
    height: 3.5,
    name: 'statusSelect'
  },
  {
    type: TYPES.SELECT,
    text: 'CATEGORY',
    required: true,
    width: 21.5,
    mobileWidth: 18.25,
    height: 3.5,
    name: 'categorySelect'
  },
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'horizontal',
    required: false,
    items: [
      {
        type: TYPES.RADIO_BUTTON,
        text: 'Deductible',
        required: true,
        width: 21.5,
        mobileWidth: 21.5,
        height: 2.875,
        name: 'deductible',
        defaultChecked: true
      },
      {
        type: TYPES.RADIO_BUTTON,
        text: 'Non-Deductible',
        required: true,
        width: 21.5,
        mobileWidth: 21.5,
        height: 2.875,
        name: 'nonDeductible',
        defaultChecked: false
      }
    ]
  },
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'horizontal',
    required: false,
    mobileHidden: true,
    items: [
      {
        type: TYPES.TEXT,
        text: 'PRICE',
        required: true,
        width: 9,
        mobileWidth: 5,
        height: 2.63,
        name: 'price',
        regExp: onlyNumberRegExp
      },
      {
        type: TYPES.TEXT,
        text: 'W/O VAT',
        required: true,
        width: 9,
        mobileWidth: 5,
        height: 2.63,
        name: 'vatPrice',
        regExp: onlyNumberRegExp
      }
    ]
  },
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'selectText',
    required: false,
    mobileHidden: true,
    items: [
      {
        type: TYPES.SELECT,
        text: 'CURRENCY',
        required: true,
        width: 10.125,
        mobileWidth: 10,
        height: 2.63,
        name: 'currencySelect'
      },
      {
        type: TYPES.NUMBER,
        text: 'REAL PRICE',
        canBeDisabled: true,
        marginTop: 1,
        required: true,
        width: 9,
        mobileWidth: 4,
        height: 2.63,
        name: 'realPrice',
        regExp: onlyNumberRegExp
      }
    ]
  },
  {
    type: TYPES.SELECT,
    text: 'ISSUER',
    required: true,
    width: 21.5,
    mobileWidth: 18.25,
    height: 3.5,
    name: 'issuerSelect',
    desktopHidden: true,
    canBeDisabled: true
  },
  {
    type: TYPES.TEXTAREA,
    text: 'DESCRIPTION',
    required: false,
    width: 22.57,
    mobileWidth: 18.86,
    height: 7,
    name: 'descriptions'
  }
]

export const addInvoiceLeftSectionData: FormDataType[] = [
  {
    type: TYPES.SELECT,
    text: 'ISSUER',
    required: true,
    width: 21.5,
    mobileWidth: 21,
    height: 2.8871875,
    name: 'issuerSelect',
    canBeDisabled: true
  }
]

export const addGroupInvoiceRightSectionData: FormDataType[] = [
  {
    type: TYPES.SELECT,
    text: 'STATUS',
    required: true,
    width: 21.5,
    mobileWidth: 18.25,
    height: 3.5,
    name: 'statusSelect'
  },
  {
    type: TYPES.SELECT,
    text: 'CATEGORY',
    required: true,
    width: 21.5,
    mobileWidth: 18.25,
    height: 3.5,
    name: 'categorySelect'
  },
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'horizontalStatus',
    required: false,
    items: [
      {
        type: TYPES.RADIO_BUTTON,
        text: 'Deductible',
        required: true,
        width: 21.5,
        mobileWidth: 21.5,
        height: 2.875,
        name: 'deductible',
        defaultChecked: true
      },
      {
        type: TYPES.RADIO_BUTTON,
        text: 'Non-Deductible',
        required: true,
        width: 21.5,
        mobileWidth: 21.5,
        height: 2.875,
        name: 'nonDeductible',
        defaultChecked: false
      }
    ]
  },
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'horizontalPrice',
    required: false,
    mobileHidden: true,
    items: [
      {
        type: TYPES.TEXT,
        text: 'PRICE',
        required: true,
        canBeDisabled: true,
        width: 9,
        mobileWidth: 5,
        height: 2.63,
        name: 'price',
        regExp: onlyNumberRegExp
      },
      {
        type: TYPES.TEXT,
        text: 'W/O VAT',
        required: true,
        canBeDisabled: true,
        width: 9,
        mobileWidth: 5,
        height: 2.63,
        name: 'vatPrice',
        regExp: onlyNumberRegExp
      }
    ]
  },
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'selectText',
    required: false,
    mobileHidden: true,
    items: [
      {
        type: TYPES.SELECT,
        text: 'CURRENCY',
        canBeDisabled: false,
        required: true,
        width: 10.125,
        mobileWidth: 10,
        height: 2.63,
        name: 'currencySelect'
      },
      {
        type: TYPES.NUMBER,
        text: 'REAL PRICE',
        canBeDisabled: true,
        marginTop: 1,
        required: true,
        width: 9,
        mobileWidth: 4,
        height: 2.63,
        name: 'realPrice',
        regExp: onlyNumberRegExp
      }
    ]
  },
  {
    type: TYPES.SELECT,
    text: 'ISSUER',
    required: true,
    width: 21.5,
    mobileWidth: 18.25,
    height: 3.5,
    name: 'issuerSelect',
    desktopHidden: true,
    canBeDisabled: true
  },
  {
    type: TYPES.TEXTAREA,
    text: 'DESCRIPTION',
    required: false,
    width: 22.57,
    mobileWidth: 18.86,
    height: 7,
    name: 'descriptions'
  }
]

export const addGroupInvoiceLeftSectionData: FormDataType[] = [
  {
    type: TYPES.SELECT,
    text: 'ISSUER',
    required: true,
    width: 21.5,
    mobileWidth: 20,
    height: 2.8871875,
    name: 'issuerSelect',
    canBeDisabled: true
  }
]

export const issuerRightSection: FormDataType[] = [
  {
    type: TYPES.SELECT,
    text: 'CATEGORY',
    required: true,
    width: 21.5,
    mobileWidth: 18.25,
    height: 3.5,
    name: 'categorySelect'
  },
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'horizontalStatus',
    required: false,
    items: [
      {
        type: TYPES.RADIO_BUTTON,
        text: 'Deductible',
        required: true,
        width: 21.5,
        mobileWidth: 21.5,
        height: 2.875,
        name: 'deductible',
        defaultChecked: true
      },
      {
        type: TYPES.RADIO_BUTTON,
        text: 'Non-Deductible',
        required: true,
        width: 21.5,
        mobileWidth: 21.5,
        height: 2.875,
        name: 'nonDeductible',
        defaultChecked: false
      }
    ]
  },
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'horizontal',
    required: false,
    items: [
      {
        type: TYPES.TEXT,
        text: 'PRICE',
        required: true,
        width: 9,
        mobileWidth: 7,
        height: 2.63,
        name: 'price',
        regExp: onlyNumberRegExp
      },
      {
        type: TYPES.TEXT,
        text: 'W/O VAT',
        required: true,
        width: 9,
        mobileWidth: 7.5,
        height: 2.63,
        name: 'vatPrice',
        regExp: onlyNumberRegExp
      }
    ]
  },
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'horizontalTotal',
    required: false,
    items: [
      {
        type: TYPES.TEXT,
        text: 'REAL PRICE',
        required: true,
        canBeDisabled: true,
        width: 9,
        mobileWidth: 7,
        height: 2.63,
        name: 'realPrice',
        regExp: onlyNumberRegExp
      }
    ]
  }
]

export const issuerLeftSection: FormDataType[] = [
  {
    type: TYPES.SELECT,
    text: 'ISSUER',
    required: true,
    width: 21.5,
    mobileWidth: 18.25,
    height: 2.8871875,
    name: 'issuerSelect',
    canBeDisabled: true
  }
]

export const settingsDataCategory: FormDataType[] = [
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'inputs',
    required: true,
    mobileColumn: true,
    items: [
      {
        type: TYPES.TEXT,
        text: 'NAME',
        required: true,
        width: 12.1,
        mobileWidth: 10,
        height: 2.65,
        name: 'categoryName'
      },
      {
        type: TYPES.SELECT,
        text: 'TAXATION',
        required: true,
        width: 13.13,
        mobileWidth: 13.13,
        height: 2.875,
        name: 'taxationSelect',
        marginTop: 0.001
      }
    ]
  }
]

export const settingsDataStatus: FormDataType[] = [
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'inputs',
    required: false,
    mobileColumn: true,
    items: [
      {
        type: TYPES.TEXT,
        text: 'NAME',
        required: true,
        width: 12.1,
        mobileWidth: 10,
        height: 2.65,
        name: 'statusName'
      },
      {
        type: TYPES.SELECT,
        text: 'PAID',
        required: true,
        width: 13.13,
        mobileWidth: 13.13,
        height: 3,
        name: 'paidSelect',
        marginTop: 0.001
      }
    ]
  }
]

export const settingsDataCurrency: FormDataType[] = [
  {
    type: TYPES.TEXT,
    text: 'NAME',
    required: true,
    width: 13.2,
    mobileWidth: 10,
    height: 3,
    name: 'currencyName'
  }
]

export const addInvoiceMobileData: FormDataType[] = [
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'horizontal',
    required: false,
    items: [
      {
        type: TYPES.NUMBER,
        text: 'PRICE',
        required: true,
        width: 7,
        mobileWidth: 5,
        height: 2.875,
        name: 'price',
        regExp: onlyNumberRegExp
      },
      {
        type: TYPES.NUMBER,
        text: 'W/O VAT',
        required: true,
        width: 7.5,
        mobileWidth: 5,
        height: 2.875,
        name: 'vatPrice',
        regExp: onlyNumberRegExp
      }
    ]
  },
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'selectText',
    required: false,
    mobileHidden: false,
    items: [
      {
        type: TYPES.SELECT,
        text: 'CURRENCY',
        required: true,
        width: 7,
        mobileWidth: 8.2,
        height: 3.55,
        name: 'currencySelect'
      },
      {
        type: TYPES.NUMBER,
        text: 'REAL PRICE',
        canBeDisabled: true,
        marginTop: 1,
        required: true,
        width: 7.5,
        mobileWidth: 6,
        height: 2.6,
        name: 'realPrice',
        regExp: onlyNumberRegExp
      }
    ]
  }
]

export const addGroupInvoiceMobileData: FormDataType[] = [
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'horizontal',
    required: false,
    items: [
      {
        type: TYPES.NUMBER,
        text: 'PRICE',
        required: true,
        canBeDisabled: true,
        width: 7,
        mobileWidth: 5,
        height: 2.875,
        name: 'price',
        regExp: onlyNumberRegExp
      },
      {
        type: TYPES.NUMBER,
        text: 'W/O VAT',
        required: true,
        canBeDisabled: true,
        width: 7.5,
        mobileWidth: 5,
        height: 2.875,
        name: 'vatPrice',
        regExp: onlyNumberRegExp
      }
    ]
  },
  {
    type: TYPES.HORIZONTAL_BLOCK,
    name: 'selectText',
    required: false,
    mobileHidden: false,
    items: [
      {
        type: TYPES.SELECT,
        text: 'CURRENCY',
        required: true,
        width: 7.5,
        mobileWidth: 8.2,
        height: 3.55,
        name: 'currencySelect'
      },
      {
        type: TYPES.NUMBER,
        text: 'REAL PRICE',
        canBeDisabled: true,
        marginTop: 1,
        required: true,
        width: 7.5,
        mobileWidth: 6,
        height: 2.6,
        name: 'realPrice',
        regExp: onlyNumberRegExp
      }
    ]
  }
]

export const userSettingsData: FormDataType[] = [
  {
    type: TYPES.TEXT,
    text: 'DISPLAY NAME',
    required: true,
    width: 20.5,
    mobileWidth: 15.375,
    height: 2.6,
    name: 'displayName'
  },
  {
    type: TYPES.TEXT,
    text: 'USER NAME',
    required: true,
    width: 20.5,
    mobileWidth: 15.375,
    height: 2.6,
    name: 'username'
  },
  {
    type: TYPES.TEXT,
    text: 'EMAIL',
    required: true,
    width: 20.5,
    mobileWidth: 15.375,
    height: 2.6,
    name: 'email'
  }
]

export const userSettingsDataPassword: FormDataType[] = [
  {
    type: TYPES.PASSWORD,
    text: 'CURRENT PASSWORD',
    required: true,
    width: 12.1,
    mobileWidth: 15.375,
    height: 2.6,
    name: 'password'
  },
  {
    type: TYPES.PASSWORD,
    text: 'NEW PASSWORD',
    required: true,
    width: 12.1,
    mobileWidth: 15.375,
    height: 2.6,
    name: 'newPassword'
  },
  {
    type: TYPES.PASSWORD,
    text: 'REPEAT NEW PASSWORD',
    required: true,
    width: 12.1,
    mobileWidth: 15.375,
    height: 2.6,
    name: 'newPasswordRepeat'
  }
]

export const editUserData: FormDataType[] = [
  {
    type: TYPES.TEXT,
    text: 'NAME',
    required: true,
    width: 12.1,
    mobileWidth: 10,
    height: 2.65,
    name: 'name'
  },
  {
    type: TYPES.TEXT,
    text: 'USER NAME',
    required: true,
    width: 12.1,
    mobileWidth: 10,
    height: 2.65,
    name: 'username'
  },
  {
    type: TYPES.SELECT,
    text: 'COMPETENCY',
    required: true,
    width: 13.13,
    mobileWidth: 13.13,
    height: 2.6,
    name: 'competencySelect',
    marginTop: 0.001
  }
]

export const editUserPasswordData: FormDataType[] = [
  {
    type: TYPES.PASSWORD,
    text: 'NEW PASSWORD',
    required: true,
    width: 12.1,
    mobileWidth: 15.375,
    height: 2.6,
    name: 'newPassword'
  },
  {
    type: TYPES.PASSWORD,
    text: 'REPEAT NEW PASSWORD',
    required: true,
    width: 12.1,
    mobileWidth: 15.375,
    height: 2.6,
    name: 'newPasswordRepeat'
  }
]

export const addUserData: FormDataType[] = [
  {
    type: TYPES.TEXT,
    text: 'NAME',
    required: true,
    width: 12.1,
    mobileWidth: 10,
    height: 2.65,
    name: 'name'
  },
  {
    type: TYPES.TEXT,
    text: 'USER NAME',
    required: true,
    width: 12.1,
    mobileWidth: 10,
    height: 2.65,
    name: 'username'
  },
  {
    type: TYPES.PASSWORD,
    text: 'PASSWORD',
    required: true,
    width: 12.1,
    mobileWidth: 10,
    height: 2.65,
    name: 'password'
  },
  {
    type: TYPES.TEXT,
    text: 'EMAIL',
    required: true,
    width: 12.1,
    mobileWidth: 10,
    height: 2.65,
    regExp: emailRegExp,
    name: 'email'
  },
  {
    type: TYPES.SELECT,
    text: 'COMPETENCY',
    required: true,
    width: 13.13,
    mobileWidth: 13.13,
    height: 2.6,
    name: 'competencySelect',
    marginTop: 0.001
  }
]
