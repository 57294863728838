import { HeaderItem } from '../../../constants/sectionsData'
import SectionHeaderItem from '../../layout/SectionHeaderItem'
import React from 'react'
import styled from 'styled-components'
import StyledSectionHeader from '../../layout/StyledSectionHeader'
import { SectionHeaderRow } from '../Items/InvoiceSection'

interface Props {
  headerData: HeaderItem[]
}

export default ({ headerData }: Props) => (
  <SettingSectionHeader>
    {headerData.map((item: HeaderItem, index: number) => (
      <SectionHeaderRow width={item.width || 0} mobileWidth={item.mobileWidth || 0}>
        <SectionHeaderItem item={item} key={index} />
      </SectionHeaderRow>
    ))}
  </SettingSectionHeader>
)

const SettingSectionHeader = styled(StyledSectionHeader)`
  @media (max-width: 375px) {
    height: 2.5em;
    font-size: 0.92em;
  }
`
