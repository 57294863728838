import React from 'react'
import { FormDataType, TYPES } from '../../../constants/formsData'
import InputText from './InputText'
import styled, { css } from 'styled-components'
import Select from './Select'
import { OptionsType } from './OptionsType'
import RadioButton from './RadioButton'
import Textarea from './Textarea'
import { InputValueStateType } from '../../../constants/types'

export interface HandleChangeEvent {
  target: { value: string }
}

interface Props {
  key: string
  item: FormDataType
  isInvalid: boolean
  value: string
  handleChange: (item: FormDataType, event: HandleChangeEvent) => void
  options?: OptionsType[]
  handleRadioButtonChange?: (event: HandleChangeEvent) => void
  mobileSmaller: boolean
  isRadioButtonDisabled?: boolean
  isInputDisabled?: boolean
  disabledInputValue?: string
  inputValue?: InputValueStateType
  radioButtonValue?: string
  error?: boolean
  fullWidth?: boolean
  disabled?: boolean
  searchable?: boolean
  index?: number
}

export const SelectInput = ({
  item,
  isInvalid,
  value,
  handleChange,
  options,
  handleRadioButtonChange,
  mobileSmaller,
  isRadioButtonDisabled,
  isInputDisabled,
  disabledInputValue,
  inputValue,
  radioButtonValue,
  error,
  fullWidth,
  disabled,
  searchable,
  index,
}: Props) => {
  let Container = Visible
  if (disabledInputValue && isNaN(parseInt(disabledInputValue))) {
    disabledInputValue = '0'
  }

  switch (item.type) {
    case TYPES.SELECT:
      if (item.desktopHidden) {
        Container = Hidden
      }
      return (
        <Container>
          <Select
            item={item}
            value={value}
            handleChange={handleChange}
            options={options}
            fullWidth={fullWidth}
            disabled={disabled}
            searchable={searchable}
          />
        </Container>
      )

    case TYPES.RADIO_BUTTON:
      return (
        <RadioButton
          disabled={isRadioButtonDisabled}
          item={item}
          handleRadioButtonChange={handleRadioButtonChange}
          value={radioButtonValue}
          index={index}
        />
      )

    case TYPES.HORIZONTAL_BLOCK:
      if (!inputValue) {
        return null
      }

      return (
        <HorizontalContainer mobileColumn={item.mobileColumn} mobileHidden={item.mobileHidden}>
          {item.items.map((item) => (
            <SelectInput
              isRadioButtonDisabled={isRadioButtonDisabled}
              key={item.name}
              item={item}
              isInvalid={inputValue[item.name] && inputValue[item.name].isInvalid}
              value={inputValue[item.name] && inputValue[item.name].value}
              handleChange={handleChange}
              handleRadioButtonChange={handleRadioButtonChange}
              mobileSmaller={mobileSmaller}
              isInputDisabled={isInputDisabled}
              disabledInputValue={disabledInputValue}
              options={options}
              radioButtonValue={radioButtonValue}
              error={error}
              disabled={disabled}
              index={index}
            />
          ))}
        </HorizontalContainer>
      )

    case TYPES.TEXTAREA:
      return (
        <Textarea
          handleChange={handleChange}
          item={item}
          value={value}
          isInvalid={isInvalid}
          disabled={disabled}
        />
      )

    case TYPES.PASSWORD:
    case TYPES.TEXT:
    case TYPES.NUMBER:
      return (
        <InputContainer marginTop={item.marginTop}>
          <InputText
            disabledText={disabled || item.canBeDisabled || isInputDisabled}
            text={
              item.canBeDisabled || isInputDisabled
                ? item.disabledText
                  ? item.disabledText
                  : item.text
                : item.text
            }
          />
          <Input
            canBeDisabled={item.canBeDisabled ? item.canBeDisabled : false}
            isInvalid={isInvalid}
            value={item.canBeDisabled && !value ? disabledInputValue : value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(item, event)}
            mobileWidth={item.mobileWidth}
            width={item.width}
            height={item.height}
            type={item.type}
            id={item.name}
            mobileSmaller={mobileSmaller}
            disabled={
              disabled || (item.canBeDisabled ? isInputDisabled || item.canBeDisabled : false)
            }
            isDisabled={disabled || item.canBeDisabled || isInputDisabled ? true : false}
            error={error}
          />
        </InputContainer>
      )

    default:
      return null
  }
}

interface InputProps {
  mobileWidth: number
  isInvalid: boolean
  width: number
  height: number
  mobileSmaller: boolean
  isDisabled: boolean
  canBeDisabled: boolean
  error?: boolean
}

const Input = styled.input<InputProps>`
  background-color: #4a4c57;
  border: 1px solid ${(props) => (props.isInvalid || props.error ? 'red' : '#4a4c57')};
  line-height: 1.1875em;
  width: ${(props) => props.width}rem;
  height: ${(props) => props.height}rem;
  display: inline-block;
  border-radius: 5px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  padding-left: 1em;
  -moz-appearance: textfield;
  color: ${(props) => (props.isDisabled ? '#C4C8CC' : 'white')};

  @media (max-width: 560px) {
    width: ${(props) => props.mobileSmaller && props.mobileWidth}rem;
    font-size: 0.75em;
  }

  @media (max-width: 450px) {
    ${({ mobileSmaller }) =>
      mobileSmaller && window.location.pathname === '/login' && 'width: 75vw;'}
  }

  &:focus {
    outline: none;
  }
`

interface InputContainerProps {
  marginTop: number | undefined
}

const InputContainer = styled.div<InputContainerProps>`
  margin-bottom: 1.5em;
  margin-top: ${(props) => props.marginTop && props.marginTop}em;
`

interface HorizontalContainerProps {
  mobileHidden?: boolean
  mobileColumn?: boolean
}

const HorizontalContainer = styled.div<HorizontalContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 560px) {
    ${({ mobileHidden }) => mobileHidden && 'display: none'}
  }

  @media (max-width: 650px) {
    ${(props) =>
      props.mobileColumn &&
      css`
        flex-direction: column;
        align-items: center;
      `};
  }
`

const Visible = styled.div``

const Hidden = styled.div`
  display: none;

  @media (max-width: 860px) {
    display: block;
  }
`
