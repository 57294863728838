import React from 'react'
import styled from 'styled-components'
import MainTitle from './MainTitle'
import useLogin from '../../hooks/useLogin'

interface Props {
  mainTitleText: string
  handleAdd: () => void
  label?: string
}

export default ({ handleAdd, mainTitleText, label }: Props) => {
  const { ADMIN } = useLogin()

  return (
    <Container>
      <MainTitle text={mainTitleText} />
      {ADMIN && (
        <AddButton onClick={handleAdd} aria-label={`Add a new ${label?.toLowerCase()}`}>
          <StyledSpan>
            <StyledPlus>&#43;</StyledPlus> Add new
          </StyledSpan>
        </AddButton>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`

const AddButton = styled.button`
  height: 2.125rem;
  width: 7.25rem;
  border: 1px solid #9198a0;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #414249;
  }

  @media (max-width: 375px) {
    width: 7.1em;
    height: 2em;
  }
`

const StyledSpan = styled.span`
  color: #cbcfd3;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 375px) {
    font-size: 0.9em;
  }
`

const StyledPlus = styled.span`
  font-size: 23px;
  margin-right: 0.3rem;

  @media (max-width: 375px) {
    margin-right: 0.15rem;
  }
`
