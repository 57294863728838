import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { headerDataSummary, SummaryHeaderItem } from '../../../constants/sectionsData'
import MainTitle from '../../layout/MainTitle'
import ConnectedButtons from '../../layout/ConnectedButtons'
import SectionHeaderItem from '../../layout/SectionHeaderItem'
import StyledSectionHeader from '../../layout/StyledSectionHeader'
import { useQuery } from '@apollo/react-hooks'
import Loader from '../../layout/Loader'
import Summary from './Summary'
import Footer from '../../layout/Footer/SummaryFooter'
import summary, { SummaryItem } from '../../../queries/summary'
import { useParams } from 'react-router'
import { ParamsType } from '../../layout/ParamsType'
import { useNavigate, useLocation } from 'react-router'
import MobileFooterSection from './MobileFooterSection'
import useQueryParams from '../Items/functions/useQueryParams'
import handleSort from '../../layout/handleSort'
import getImageForHeaderItem from '../../layout/getImageForHeaderItem'
import { CategorySectionType } from '../../../queries/summary'
import NoInvoicesFound from '../Items/NoInvoicesFound'
import { SectionContainer } from '../Items/Sections'
import {
  SectionTable,
  MobileOnlyHeaderItem,
  DesktopOnlyHeaderItem,
  SectionHeaderRow,
  InvoiceLine
} from '../Items/InvoiceSection'
import salariesInvoices, { SalariesType } from '../../../queries/salariesInvoices'
import formatPrice from '../../layout/formatPrice'
import { CategoryItem } from '../../../queries/categories'
import { ClientName, SalaryProps } from '../../../constants/constants'

const _ = require('lodash')

export default () => {
  const params = useParams<ParamsType>()
  const navigate = useNavigate()
  const location = useLocation()
  const [summaryData, setSummaryData] = useState<SummaryItem[]>([])

  let yearMonth = `${params.year}-${_.padStart(params.month, 2, '0')}`

  if (params.year && !params.month) {
    yearMonth = `${params.year}`
  }

  const queryParams = useQueryParams()
  const sortByQuery = queryParams.get('sort') || ''
  const orderBy = queryParams.get('order') || 'ASCENDING'

  const variableObject = {
    sortBy: sortByQuery,
    order: orderBy
  }

  const getSummary = useQuery(summary, {
    variables: { yearMonth, sortBy: sortByQuery, order: orderBy }
  })

  const { data: salariesData } = useQuery(salariesInvoices, {
    variables: { yearMonth },
    pollInterval: 600000,
    context: { clientName: ClientName.GO_API }
  })

  const salariesSummarysData = salariesData
    ? salariesData.Salary.map((salary: SalariesType) => {
        return {
          vatPrice: salary.CistaMzda,
          realPrice: salary.CistaMzda,
          totalPrice: salary.CistaMzda,
          category: {
            displayName: 'Salary'
          }
        }
      })
    : []

  const summedCategorySection = salariesSummarysData.reduce(
    (acc: CategorySectionType, curr: CategorySectionType) => {
      const vatPrice = parseInt(curr.vatPrice.toString().replace(/\s/g, ''))
      const realPrice = parseInt(curr.realPrice.toString().replace(/\s/g, ''))
      const totalPrice = parseInt(curr.totalPrice.toString().replace(/\s/g, ''))

      acc.vatPrice += vatPrice
      acc.realPrice += realPrice
      acc.totalPrice += totalPrice

      return acc
    },
    {
      vatPrice: 0,
      realPrice: 0,
      totalPrice: 0,
      category: { displayName: 'Salary', id: SalaryProps.categoryId }
    }
  )

  const ref = useRef<HTMLDivElement>(null)
  const [sticky, setSticky] = useState(false)
  const [vatPrice, setVatPrice] = useState(true)
  const [activeButtons, setActiveButtons] = useState({
    leftButton: true,
    rightButton: false
  })

  const realPrice = vatPrice ? 1 : 0
  const footerTotalPrice = vatPrice ? 'totalPrice' : 'totalRealPrice'

  function handleScroll() {
    if (ref.current) {
      const distanceFromBottom = window.innerHeight - ref.current.getBoundingClientRect().bottom
      setSticky(distanceFromBottom < 0 && !sticky ? true : sticky && false)
    }
  }

  function handleLeftButtonClick() {
    setVatPrice(true)
    setActiveButtons({
      leftButton: true,
      rightButton: false
    })
  }

  function handleRightButtonClick() {
    setVatPrice(false)
    setActiveButtons({
      leftButton: false,
      rightButton: true
    })
  }

  function renderSummaryLine() {
    return headerDataSummary.map((item: SummaryHeaderItem, index: number) => {
      if (!item.summaryMobileDisplay && !item.summaryMobileOnly) {
        return (
          <DesktopOnlySummaryLine key={`${item.text}Line_${index}`}>
            <SummaryLine
              style={{ marginLeft: item.marginLeft || 0, marginRight: item.marginRight || 0 }}
            />
          </DesktopOnlySummaryLine>
        )
      }

      if (item.summaryMobileDisplay && item.summaryMobileOnly) {
        return (
          <MobileOnlySummaryLine key={`${item.text}Line_${index}`}>
            <SummaryLine
              style={{ marginLeft: item.marginLeft || 0, marginRight: item.marginRight || 0 }}
            />
          </MobileOnlySummaryLine>
        )
      }
      return (
        <td style={{ padding: 0 }} key={`${item.text}Line_${index}`}>
          <SummaryLine
            style={{ marginLeft: item.marginLeft || 0, marginRight: item.marginRight || 0 }}
          />
        </td>
      )
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    let mergedData: SummaryItem[] | undefined

    if (!getSummary?.data) return

    if (
      getSummary.data.summary[realPrice].every(
        (item: SummaryItem) => item.currency.symbol !== 'CZK'
      ) &&
      salariesSummarysData.length > 0
    ) {
      mergedData = [
        ...getSummary.data.summary[realPrice],
        {
          currency: {
            symbol: 'CZK'
          },
          categorySection: []
        }
      ]
    }

    setSummaryData(mergedData || getSummary.data?.summary[realPrice])
  }, [getSummary, salariesSummarysData])

  if (getSummary.loading) {
    return <Loader />
  }

  function handleClick() {
    return
  }

  const titleSection = (
    <TitleSection>
      <MainTitle text="Summary" />
      <ConnectedButtons
        rightButtonOnClick={handleRightButtonClick}
        leftButtonOnClick={handleLeftButtonClick}
        leftButtonActive={activeButtons.leftButton}
        rightButtonActive={activeButtons.rightButton}
      />
    </TitleSection>
  )

  return (
    <>
      {summaryData.map((item: SummaryItem, index: number) => {
        let combinedCategorySection = item.categorySection.map(categorySectionItem => ({
          ...categorySectionItem
        }))

        if (summedCategorySection.realPrice > 0 && item.currency.symbol === 'CZK') {
          combinedCategorySection = combinedCategorySection.concat({
            ...summedCategorySection,
            isSalary: true
          })
        }

        const totalSection = {
          totalVatPrice: combinedCategorySection.reduce((acc, curr) => acc + curr.vatPrice, 0),
          totalRealPrice: combinedCategorySection.reduce((acc, curr) => acc + curr.realPrice, 0),
          totalPrice: combinedCategorySection.reduce((acc, curr) => acc + curr.totalPrice, 0)
        }

        return (
          <MainContainer key={index}>
            <SummarySectionContainer ref={ref} key={index}>
              {index === 0 && titleSection}
              <SummarySection>
                <thead>
                  <SectionHeaderSummary>
                    {headerDataSummary.map((item: SummaryHeaderItem, index: number) => {
                      const headerItem = (
                        <SectionHeaderItem
                          selectAllInvoices={() => null}
                          item={item}
                          activeFilterTexts={['']}
                          image={getImageForHeaderItem(item.text, sortByQuery, orderBy)}
                          handleSort={() => handleSort(item, variableObject, navigate, location)}
                          key={item.text}
                        />
                      )

                      if (item.summaryMobileDisplay && item.summaryMobileOnly) {
                        return (
                          <MobileOnlySummaryHeaderItem
                            width={item.width || 0}
                            mobileWidth={item.mobileWidth || 0}
                            key={`${item.text}_${index}`}
                          >
                            <Mobile>{headerItem}</Mobile>
                          </MobileOnlySummaryHeaderItem>
                        )
                      }

                      if (!item.summaryMobileDisplay && !item.summaryMobileOnly) {
                        return (
                          <DesktopOnlySummaryHeaderItem
                            width={item.width || 0}
                            mobileWidth={item.mobileWidth || 0}
                            key={`${item.text}_${index}`}
                          >
                            <MobileHidden>{headerItem}</MobileHidden>
                          </DesktopOnlySummaryHeaderItem>
                        )
                      }

                      return (
                        <SectionHeaderRow
                          width={item.width || 0}
                          mobileWidth={item.mobileWidth || 0}
                          key={`${item.text}_${index}`}
                        >
                          {headerItem}
                        </SectionHeaderRow>
                      )
                    })}
                  </SectionHeaderSummary>
                </thead>
                <tbody>
                  {combinedCategorySection.map((summary: any, summaryIndex: number) => {
                    if (!summary?.category) {
                      return null
                    }

                    if (summary.isSalary && item.currency.symbol !== 'CZK') {
                      return null
                    }

                    return (
                      <React.Fragment key={summaryIndex}>
                        <tr>{renderSummaryLine()}</tr>
                        <Summary
                          key={summaryIndex}
                          summary={summary}
                          vatPrice={vatPrice}
                          currencyName={item.currency.symbol}
                        />
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </SummarySection>
              <MobileFooterSection
                currencyName={item.currency.symbol}
                totalPrice={totalSection[footerTotalPrice]}
                vatPrice={totalSection.totalVatPrice}
              />

              <Footer
                invoicesSum={totalSection[footerTotalPrice]}
                displayMobile={true}
                isSticky={sticky}
                vatPrice={totalSection.totalVatPrice}
                summaryCurrencyName={item.currency.symbol}
              />
            </SummarySectionContainer>
          </MainContainer>
        )
      })}
      {getSummary.data.summary[realPrice].length === 0 && (
        <MainContainer>
          <SummarySectionContainer>
            {titleSection}
            <NoInvoicesFound forSummary={true} />
          </SummarySectionContainer>
        </MainContainer>
      )}
    </>
  )
}

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100vw;
  overflow: hidden;

  @media (max-width: 645px) {
    margin: 0 20px;
    align-items: unset;
  }
`

const SummarySection = styled(SectionTable)`
  background-color: #3a3c45;
  display: table;
  flex-direction: column;
  border-radius: 8px 8px 0 0;
  padding: 0 30px;

  @media (max-width: 440px) {
    padding: 0 10px;
  }
`

const TitleSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

const SummarySectionContainer = styled(SectionContainer)`
  margin-bottom: 6em;

  @media (max-width: 645px) {
    width: 100%;
  }
`

export const SectionHeaderSummary = styled(StyledSectionHeader)`
  display: table-row;

  @media (max-width: 375px) {
    height: 2.5em;
    font-size: 0.92em;
  }
`

const Mobile = styled.span`
  display: none;
  @media (max-width: 375px) {
    display: inline;
  }
`

const MobileHidden = styled.span`
  display: inline;
  @media (max-width: 375px) {
    display: none;
  }
`

const DesktopOnlySummaryLine = styled.td`
  @media (max-width: 960px) {
    display: none;
  }
`

const MobileOnlySummaryLine = styled.td`
  padding: 0;
  display: none;
  @media (max-width: 960px) {
    display: table-cell;
  }
`

const SummaryLine = styled(InvoiceLine)``

const MobileOnlySummaryHeaderItem = styled(MobileOnlyHeaderItem)`
  @media (max-width: 960px) {
    display: none;
  }

  @media (max-width: 375px) {
    display: table-cell;
  }
`

const DesktopOnlySummaryHeaderItem = styled(DesktopOnlyHeaderItem)`
  @media (max-width: 960px) {
    display: table-cell;
  }

  @media (max-width: 375px) {
    display: none;
  }
`
