import { gql } from '@apollo/client'

interface UserType {
  id: number
  Name: string
}

export interface SalariesType {
  id: string
  HrubaMzda: number
  CistaMzda: number
  Obdobi: string
  UserID: number
  User: UserType
}

export default gql`
  query salaries($yearMonth: String!) {
    Salary(yearMonth: $yearMonth) {
      id
      HrubaMzda
      CistaMzda
      Obdobi
      UserID
      User {
        id
        Name
      }
    }
  }
`
