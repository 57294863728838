import { NavigateFunction } from 'react-router-dom'
import { ParamsType } from './ParamsType'

function handleRedirectToDuplication(
  invoiceId: string,
  navigate: NavigateFunction,
  pathname: string,
  params: ParamsType
) {
  const redirectTo = `/invoices/${invoiceId}/duplicate`
  return navigate(redirectTo, {
    state: {
      previousPath: pathname,
      month: parseInt(params.month),
      year: parseInt(params.year),
    },
  })
}

export default handleRedirectToDuplication
