import React from 'react'
import styled from 'styled-components'

interface Props {
  forSummary: boolean
  straightCorners?: boolean
}

export default ({ forSummary, straightCorners }: Props) => (
  <Container straightCorners={straightCorners}>
    <NotFoundText>
      {forSummary ? 'No data for summary' : 'No invoices found with these parameters.'}
    </NotFoundText>
  </Container>
)

interface ContainerProps {
  straightCorners?: boolean
}

const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 3em;
  background-color: #3a3c45;
  border-radius: 8px;

  ${({ straightCorners }) => straightCorners && `border-radius: 8px 8px 0 0`};

  @media (max-width: 645px) {
    border-radius: 0;
    width: 100vw;
  }
`

const NotFoundText = styled.span`
  color: #cbcfd3;
  font-family: Montserrat;
  font-size: 0.8em;
`
