import React from 'react'
import ItemsOverlay from './ItemsOverlay'
import { useQuery } from '@apollo/react-hooks'
import categories from '../../../../queries/categories'

interface Props {
  selectedInvoiceIds: string[]
  variables: any
  handleClose: () => void
  handleBack: () => void
  selectedInvoiceCategories: string[]
}

export default (props: Props) => {
  const { data, loading } = useQuery(categories)

  return (
    <ItemsOverlay
      selectedInvoicesIds={props.selectedInvoiceIds}
      variables={props.variables}
      handleClose={props.handleClose}
      data={data.categories}
      handleBack={props.handleBack}
      title="CATEGORY"
      loading={loading}
      property="category"
      selectedInvoiceCategoriesStatuses={props.selectedInvoiceCategories}
    />
  )
}
