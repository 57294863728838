import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { writeStorage } from '@rehooks/local-storage'
import { useMutation } from '@apollo/react-hooks'
import Div100vh from 'react-div-100vh'

import { SelectInput } from '../../layout/Form/Input'
import { FormDataType, loginFormData } from '../../../constants/formsData'
import BigOrangeButton from '../../layout/BigOrangeButton'
import login from '../../../mutations/login'

export default () => {
  const [inputValue, setInputValue] = useState<StateType>({})
  const ref = useRef<HTMLButtonElement>(null)
  const [logIn, { error }] = useMutation(login, {
    onCompleted({ login }) {
      writeStorage('loginData', login)
    }
  })

  interface StateItem {
    value: string
    isInvalid: boolean
  }

  interface StateType {
    [item: string]: StateItem
  }

  interface HandleChangeEvent {
    target: { value: string }
  }

  function handleChange(item: FormDataType, event: HandleChangeEvent) {
    const inputData = {
      ...inputValue,
      ...{
        [item.name]: {
          value: event.target.value,
          isInvalid: item.required && event.target.value === ''
        }
      }
    }
    setInputValue(inputData)
  }

  function handleSubmit() {
    const canLogIn = loginFormData.every((item: FormDataType) => {
      if (!item.required) {
        return true
      } else if (!inputValue[item.name]) {
        return false
      } else if (inputValue[item.name].isInvalid) {
        return false
      }
      return true
    })

    if (canLogIn) {
      const submitData = Object.entries(inputValue).reduce(
        (newValues: { [key: string]: string }, currentValue: [string, StateItem]) => {
          newValues[currentValue[0]] = currentValue[1].value
          return newValues
        },
        {}
      )

      //for some reason this gives TypeError in this branch
      // @ts-ignore
      const { username, password } = submitData

      return logIn({ variables: { username, password } })
    }
  }

  function handleEnter(event: any) {
    if (event.keyCode === 13 && ref && ref.current) {
      event.preventDefault()
      ref.current.click()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEnter)

    return () => {
      window.removeEventListener('keyup', handleEnter)
    }
  }, [])

  return (
    <Container>
      <Logo src="./img/logo.svg" alt="logo" />
      <FormContainer>
        {loginFormData.map((item: FormDataType) => (
          <SelectInput
            key={item.name}
            item={item}
            isInvalid={inputValue[item.name] && inputValue[item.name].isInvalid}
            value={(inputValue[item.name] && inputValue[item.name].value) || ''}
            handleChange={handleChange}
            mobileSmaller={true}
          />
        ))}
        {error && <LoginError>credentials do not match</LoginError>}
        <LogInButton ref={ref} onClick={handleSubmit}>
          LOG IN
        </LogInButton>
      </FormContainer>
    </Container>
  )
}

const FormContainer = styled.div`
  background-color: #3a3c45;
  width: 27em;
  height: 21.5em;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-top: 2.5em;

  @media (max-width: 450px) {
    width: 90vw;
  }
`

const Container = styled(Div100vh)`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const LoginError = styled.span`
  color: #fc6969;
  width: 80%;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.81em;
  margin-top: -2em;
  margin-bottom: 0.75em;
  display: flex;
  justify-content: flex-start;
`

const Logo = styled.img`
  @media (max-width: 450px) {
    width: 183px;
  }
`

const LogInButton = styled(BigOrangeButton)``
