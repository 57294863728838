import styled from 'styled-components'

export const Text = styled.span`
  color: white;
  font-family: Montserrat;
  font-size: 0.875em;
  line-height: 18px;
`

export const TextBold = styled(Text)`
  font-weight: 600;
`
