import { css } from 'styled-components'

export default css`
  background-color: transparent;
  border: 1px solid #535562;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.875em;
  border-radius: 0.938em;
  color: white;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 1.2em;
  cursor: pointer;

  @media (max-width: 375px) {
    font-size: 0.687em;
  }
`
