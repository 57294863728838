import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Menu, Item, Submenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.min.css'
import './ContextMenu.css'
import { useMutation, useQuery } from '@apollo/react-hooks'
import categories from '../../../queries/categories'
import statuses from '../../../queries/statuses'
import { ItemType } from '../ItemType'
import invoices from '../../../queries/invoices'
import { useNavigate, useLocation } from 'react-router'
import handleRedirectToEdit from '../handleRedirectToEdit'
import handleRedirectToDetail from '../handleRedirectToDetail'
import handleStatusCategoryEdit from '../../screens/Items/functions/handleStatusCategoryEdit'
import OrangeDot from '../OrangeDot'
import editInvoiceNotRequired from '../../../mutations/editInvoiceNotRequired'
import DeletePopUp from '../PopUps/DeletePopUp'
import invoice from '../../../queries/invoice'
import useLogin from '../../../hooks/useLogin'
import { LocationType } from '../../../constants/types'
import { useParams } from 'react-router'
import { ParamsType } from '../ParamsType'
import handleRedirectToDuplication from '../handleRedirectToDuplication'

interface Props {
  id: string
  onHidden: () => void
  approvedInvoice: boolean
  unapproveInvoices: (ids: string[]) => void
  deleteInvoices: (ids: string[]) => void
  approveInvoices: (ids: string[]) => void
  invoiceId: string
  invoiceCategoryId: number
  invvoiceStatusId: number
  variables: any
  selectedInvoicesIds: string[]
  invoiceCount: number
  closedMonth: boolean
  issuerRow?: boolean
  detailOnly?: boolean
  groupInvoice?: boolean
}

interface ScrollEvent extends Event {
  path?: [{ className: string }]
  target: any
}

export default (props: Props) => {
  const ref = React.useRef<Menu>(null)
  const getCategories = useQuery(categories)
  const getStatuses = useQuery(statuses)
  const selectIds =
    props.selectedInvoicesIds.length === 0 ? [props.invoiceId] : props.selectedInvoicesIds
  const { ADMIN, ACCOUNTANT, USER } = useLogin()

  const [closedPopUp, setClosedPopUp] = useState(true)

  const navigate = useNavigate()
  const params: ParamsType = useParams() as ParamsType
  const location = useLocation()

  let queries = [{ query: invoice, variables: { id: props.invoiceId } }]

  if (props.selectedInvoicesIds.length > 0) {
    props.selectedInvoicesIds.forEach((invoiceId: string) =>
      queries.push({ query: invoice, variables: { id: invoiceId } })
    )
  }

  const [invoiceEdit] = useMutation(editInvoiceNotRequired, {
    refetchQueries: [{ query: invoices, ...props.variables }, ...queries],
  })

  useEffect(() => {
    function hideOnScroll(event: ScrollEvent) {
      if (event.path) {
        for (const path of event.path) {
          if (path.className === 'react-contexify') {
            return
          }
        }
      }

      if (
        event &&
        event.target &&
        event.target.parentElement &&
        event.target.parentElement.className.includes('react-contexify')
      ) {
        return
      }

      if (ref.current) {
        ref.current.hide()
      }
    }
    document.addEventListener('scroll', hideOnScroll, true)
    return () => document.removeEventListener('scroll', hideOnScroll, true)
  }, [])

  function handleDelete() {
    props.deleteInvoices(selectIds)
    setClosedPopUp(true)
  }

  function renderClickableItems(items: [], Component: any, category: boolean) {
    return items.map((item: ItemType, index: number) => {
      const activeItem = (
        <ActiveItemContainer
          onClick={() =>
            category
              ? handleStatusCategoryEdit(item, selectIds, invoiceEdit, 'category')
              : handleStatusCategoryEdit(item, selectIds, invoiceEdit, 'status')
          }
          key={index}
        >
          <ActiveItem>
            {item.displayName}
            <OrangeDot />
          </ActiveItem>
        </ActiveItemContainer>
      )

      if (
        (category && item.id === props.invoiceCategoryId) ||
        (!category && item.id === props.invvoiceStatusId)
      ) {
        return activeItem
      }

      return (
        <Component
          onClick={() =>
            category
              ? handleStatusCategoryEdit(item, selectIds, invoiceEdit, 'category')
              : handleStatusCategoryEdit(item, selectIds, invoiceEdit, 'status')
          }
          key={index}
        >
          {item.displayName}
        </Component>
      )
    })
  }

  if (getStatuses.loading || getCategories.loading) {
    return null
  }

  if ((USER && props.approvedInvoice) || props.detailOnly || ACCOUNTANT) {
    return (
      <>
        <ContextMenu id={props.id} onHidden={props.onHidden} ref={ref}>
          <Item
            onClick={() =>
              handleRedirectToDetail(props.invoiceId, navigate, location.pathname, params)
            }
          >
            Detail
          </Item>
          <Item
            onClick={() =>
              handleRedirectToDuplication(props.invoiceId, navigate, location.pathname, params)
            }
          >
            <p>Duplicate</p>
          </Item>
        </ContextMenu>
      </>
    )
  }

  if (props.issuerRow) {
    return (
      <>
        <ContextMenu id={props.id} onHidden={props.onHidden} ref={ref}>
          <Item
            onClick={() =>
              handleRedirectToDetail(props.invoiceId, navigate, location.pathname, params)
            }
          >
            Detail
          </Item>
          {!props.detailOnly && (
            <Item
              onClick={() =>
                handleRedirectToEdit(props.invoiceId, navigate, location.pathname, params)
              }
            >
              <p>Edit</p>
            </Item>
          )}
        </ContextMenu>
      </>
    )
  }

  if (props.closedMonth && ADMIN) {
    return (
      <>
        <ContextMenu id={props.id} onHidden={props.onHidden} ref={ref}>
          <Item
            onClick={() =>
              handleRedirectToDetail(props.invoiceId, navigate, location.pathname, params)
            }
          >
            Detail
          </Item>
          <Submenu
            label="Status"
            arrow={<Arrow src="/img/arrow-right-dropdown.svg" />}
            style={SubmenuStyle}
          >
            <Scrollable>{renderClickableItems(getStatuses.data.statuses, Item, false)}</Scrollable>
          </Submenu>
        </ContextMenu>
      </>
    )
  }

  return (
    <>
      <DeletePopUp
        handleDelete={() => handleDelete()}
        deleteItemText={props.invoiceCount > 1 ? 'these' : 'this'}
        handleClosed={() => setClosedPopUp(true)}
        closed={closedPopUp}
        kindOfDelete={props.invoiceCount > 1 ? 'invoices' : 'invoice'}
      />
      <ContextMenu id={props.id} onHidden={props.onHidden} ref={ref}>
        <Item
          onClick={() =>
            handleRedirectToDetail(props.invoiceId, navigate, location.pathname, params)
          }
        >
          <p>Detail</p>
        </Item>
        <Item
          onClick={() => handleRedirectToEdit(props.invoiceId, navigate, location.pathname, params)}
        >
          <p>Edit</p>
        </Item>
        {!props.groupInvoice && (
          <Submenu
            label="Category"
            arrow={<Arrow src="/img/arrow-right-dropdown.svg" />}
            style={SubmenuStyle}
          >
            <Scrollable>
              {renderClickableItems(getCategories.data.categories, Item, true)}
            </Scrollable>
          </Submenu>
        )}
        <Submenu
          label="Status"
          arrow={<Arrow src="/img/arrow-right-dropdown.svg" />}
          style={SubmenuStyle}
        >
          <Scrollable>{renderClickableItems(getStatuses.data.statuses, Item, false)}</Scrollable>
        </Submenu>
        {props.approvedInvoice
          ? ADMIN && (
              <Item onClick={() => props.unapproveInvoices(selectIds)}>
                <p>Unapprove</p>
              </Item>
            )
          : ADMIN && (
              <Item onClick={() => props.approveInvoices(selectIds)}>
                <p>Approve</p>
              </Item>
            )}
        <Item
          onClick={() =>
            handleRedirectToDuplication(props.invoiceId, navigate, location.pathname, params)
          }
        >
          <p>Duplicate</p>
        </Item>
        <Item onClick={() => setClosedPopUp(false)}>
          <p>Delete</p>
        </Item>
      </ContextMenu>
    </>
  )
}

const Arrow = styled.img``

const SubmenuStyle = {
  boxShadow: 'none',
}

const Scrollable = styled.div`
  height: 10em;
  overflow-y: auto;
  overflow-x: hidden;
`

const ActiveItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 2.5em;
  border-radius: 5px;
  align-items: center;
  -webkit-overflow-scrolling: touch;

  &:hover {
    background-color: #636572;
  }
`

const ActiveItem = styled.span`
  color: white;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 0.875em;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 6px 12px;
`

interface ContextMenuProps {
  ref: React.RefObject<Menu>
}

const ContextMenu = styled(Menu)<ContextMenuProps>``
