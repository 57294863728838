import styled from 'styled-components'

export default styled.div`
  display: inline;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid orange;
  }
`
