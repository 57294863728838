import React from 'react'
import styled from 'styled-components'
import StyledLink from '../StyledLink'
import { useMutation } from '@apollo/react-hooks'
import { userDropdownLinks } from '../../../constants/links'
import handleRedirect from '../handleRedirect'
import { useNavigate, useLocation } from 'react-router'
import useQueryParams from '../../screens/Items/functions/useQueryParams'
import useLogin from '../../../hooks/useLogin'

interface Props {
  setPopUpClosed: (state: boolean) => void
  setPath: (state: string) => void
  month: number | undefined
  year: number | undefined
}

const OptionDropdownMenu = (props: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { ADMIN, ACCOUNTANT } = useLogin()

  const redirect = useQueryParams().get('redirect')

  return (
    <Container isTall={ADMIN}>
      <>
        {ADMIN && (
          <StyledLinkSmallContainer
            onClick={() =>
              handleRedirect(
                navigate,
                location.pathname,
                '/addGroupInvoice',
                false,
                props.setPopUpClosed,
                props.setPath,
                props.month,
                props.year
              )
            }
          >
            <StyledLinkSmall>Add group invoice</StyledLinkSmall>
          </StyledLinkSmallContainer>
        )}
        {(ADMIN || ACCOUNTANT) && (
          <StyledLinkSmallContainer
            onClick={() =>
              handleRedirect(
                navigate,
                location.pathname,
                '/import/salaries',
                redirect === 'false',
                props.setPopUpClosed,
                props.setPath,
                props.month,
                props.year
              )
            }
          >
            <StyledLinkSmall>Salaries</StyledLinkSmall>
          </StyledLinkSmallContainer>
        )}
      </>
    </Container>
  )
}

interface ContainerProps {
  isTall?: boolean
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #535562;
  width: 13em;
  height: ${props => (props.isTall ? '6em' : '3em')};
  border-radius: 0.313em;
  position: fixed;
  right: 3.125em;
  top: 5.875em;
  box-shadow: -8px -2px 16px 0 rgba(43, 42, 42, 0.2);

  @media (max-width: 675px) {
    right: 1.5em;
  }
`

const StyledLinkSmall = styled(StyledLink)`
  font-size: 0.9em;
  margin-left: 1em;
`
const StyledLinkSmallContainer = styled.div`
  height: 100%;
  color: #fff;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.875em;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #636572;
    border-radius: 5px;
  }
`

export default OptionDropdownMenu
