import styled from 'styled-components'

export default styled.div`
  opacity: 0.31;
  background: #000;
  width: 100%;
  height: 100%;
  z-index: 200;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  right: 0;
`
