import React from 'react'
import { Navigate } from 'react-router-dom'
import Props from './Props'
import useLogin from '../hooks/useLogin'

const AuthorizedRoute = ({ Component }: Props) => {
  const { user } = useLogin()

  return user ? <Component /> : <Navigate to="/login" />
}

export default AuthorizedRoute
