import React from 'react'
import styled, { css } from 'styled-components'
import StyledLink from '../StyledLink'
import { ParamsType } from '../ParamsType'
import { useParams, useLocation, useNavigate } from 'react-router'
import { useMutation } from '@apollo/react-hooks'
import logout from '../../../mutations/logout'
import handleLogout from '../handleLogout'
import handleRedirect from '../handleRedirect'
import useQueryParams from '../../screens/Items/functions/useQueryParams'
import useLogin from '../../../hooks/useLogin'
import Div100vh from 'react-div-100vh'
import getPathname from '../Header/getPathname'

interface Props {
  handleClose: () => void
  invoiceDate?: any
  setPopUpClosed: (state: boolean) => void
  setPath: (state: string) => void
  setMenuVisible: (state: boolean) => void
}

export default (props: Props) => {
  const params = useParams<ParamsType>()
  const location = useLocation()
  const navigate = useNavigate()
  const [logoutUser] = useMutation(logout)
  const redirect = useQueryParams().get('redirect')
  const { ADMIN, user } = useLogin()

  const { itemsPathname, summaryPathname } = getPathname(props, params)

  return (
    <Container>
      <CloseImage src="/img/header-menu-close.svg" onClick={props.handleClose} />
      <Logo src="/img/logo-small.svg" />
      <TopSection>
        <StyledLinkItems
          onClick={() => {
            props.setMenuVisible(false)
            props.setPath(itemsPathname)
            redirect === 'false'
              ? props.setPopUpClosed(false)
              : handleRedirect(navigate, location.pathname, itemsPathname, redirect === 'false')
          }}
          active={
            parseInt(params.month ? params.month : '1') >= 1 &&
            parseInt(params.month ? params.month : '1') <= 12 &&
            !location.pathname.includes('summary')
          }
        >
          ITEMS
        </StyledLinkItems>
        <SummaryLink
          onClick={() => {
            props.setMenuVisible(false)
            props.setPath(summaryPathname)
            redirect === 'false'
              ? props.setPopUpClosed(false)
              : handleRedirect(navigate, location.pathname, summaryPathname, redirect === 'false')
          }}
          active={location.pathname.includes('summary')}
        >
          SUMMARY
        </SummaryLink>
      </TopSection>
      <MenuLine src="/img/header-line.svg" />
      <OptionsSection>
        <SectionWithImage>
          <UserImage src="/img/user-image.svg" />
          <Item>{user ? user.displayName : ''}</Item>
        </SectionWithImage>
        {ADMIN && (
          <SmallItemLink
            onClick={() => {
              props.setMenuVisible(false)
              props.setPath('/settings')
              redirect === 'false'
                ? props.setPopUpClosed(false)
                : handleRedirect(navigate, location.pathname, '/settings', redirect === 'false')
            }}
          >
            Settings
          </SmallItemLink>
        )}
        <SmallItemLink
          onClick={() => {
            props.setPath('/users')
            redirect === 'false'
              ? props.setPopUpClosed(false)
              : handleRedirect(navigate, location.pathname, '/users', redirect === 'false')
          }}
        >
          Users
        </SmallItemLink>
        <SmallItemLink
          onClick={() => {
            props.setMenuVisible(false)
            props.setPath('/userSettings')
            redirect === 'false'
              ? props.setPopUpClosed(false)
              : handleRedirect(navigate, location.pathname, '/userSettings', redirect === 'false')
          }}
        >
          User Settings
        </SmallItemLink>
        <SmallItem onClick={() => handleLogout(logoutUser)}>Logout</SmallItem>
      </OptionsSection>
    </Container>
  )
}

const Container = styled(Div100vh)`
  position: fixed;
  width: 100vw;
  display: flex;
  align-items: center;
  background-color: #2b2d35;
  flex-direction: column;
  padding-top: 3.25em;
`

const TopSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 11em;
`

const Logo = styled.img`
  margin-bottom: 5.2em;

  @media (max-width: 375px) {
    width: 92px;
  }
`

const MenuLine = styled.img`
  width: 272px;
  margin-top: 1.875em;
  margin-bottom: 1.875em;
`

const OptionsSection = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const textStyles = css`
  color: white;
  font-family: Montserrat;
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 1.875em;
`

const Item = styled.span`
  ${textStyles}
`

const SmallItemLink = styled.span`
  ${textStyles}
  font-size: 0.9em;
  text-decoration: none;
  cursor: pointer;
`

const SmallItem = styled(Item)`
  font-size: 0.9em;
`

const SectionWithImage = styled.section`
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: center;
`

const UserImage = styled.img`
  margin-top: -2.2em;
  margin-right: 0.4em;
`

const CloseImage = styled.img`
  width: 1.563em;
  position: absolute;
  left: 1.875em;
  cursor: pointer;

  @media (max-width: 375px) {
    width: 21px;
  }
`

interface StyledLinkItemsProps {
  active: boolean
}

const StyledLinkItems = styled(StyledLink)<StyledLinkItemsProps>`
  ${({ active }) =>
    active &&
    css`
      border-bottom: 2px solid #f29312;
      padding-bottom: 0.1em;
      color: #f29312;
    `}
`

interface SummaryLinkProps {
  active: boolean
}

const SummaryLink = styled(StyledLink)<SummaryLinkProps>`
  ${({ active }) =>
    active &&
    css`
      border-bottom: 2px solid #f29312;
      padding-bottom: 0.1em;
      color: #f29312;
    `}
`
