import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

import './index.css'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import TokenVerifyComponent from './tokenValidation/TokenVerifyComponent'
import ErrorProvider from './context/errorContext'
import CustomApolloProvider from './CustomApolloProvider'

const render = (Component: React.FC) => {
  const root = ReactDOM.createRoot(document.getElementById('root')!)

  root.render(
    <ErrorProvider>
      <CustomApolloProvider>
        <Router>
          <Component />
          <TokenVerifyComponent />
        </Router>
      </CustomApolloProvider>
    </ErrorProvider>
  )
}

render(App)

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept('./App', () => {
    render(App)
  })
}

serviceWorker.unregister()
