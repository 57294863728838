import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/react-hooks'
import invoices, { InvoiceType } from '../../../queries/invoices'
import { useParams } from 'react-router'
import { ParamsType } from '../../layout/ParamsType'
import Loader from '../../layout/Loader'
import InvoiceSection from './InvoiceSection'
import useQueryParams from './functions/useQueryParams'
import getFilterData from './functions/getFilterData'
import MobileFooter from '../../layout/Footer/MobileFooter/index'
import invoicesSum from './functions/invoicesSum'
import isMonthClosed from '../../../queries/isMonthClosed'
import users from '../../../queries/users'
import statuses from '../../../queries/statuses'
import categories from '../../../queries/categories'
import currencies from '../../../queries/currencies'
import openMonth from '../../../mutations/openMonth'
import closeMonth from '../../../mutations/closeMonth'
import { ClientName, PAST_MONTH } from '../../../constants/constants'
import useLogin from '../../../hooks/useLogin'
import { useApolloClient } from '@apollo/react-hooks'
import salariesInvoices, { SalariesType } from '../../../queries/salariesInvoices'
import { SalaryProps } from '../../../constants/constants'
import { selectOptionsInterface } from '../../../constants/types'

const _ = require('lodash')

interface Props {
  containerRef: any
}

export default (props: Props) => {
  const params = useParams<ParamsType>()
  const queryParams = useQueryParams()
  const orderBy = queryParams.get('order') || 'DESCENDING'
  const sortBy = queryParams.get('sort') || 'date'

  const selectOptions = {
    categories: useQuery(categories),
    currencies: useQuery(currencies),
    users: useQuery(users)
  }

  const { ADMIN, USER, token } = useLogin()
  const apolloClient = useApolloClient()

  const categoryQuery = queryParams.get('categoryId') || ''
  const statusQuery = queryParams.get('statusId') || ''
  const userQuery = queryParams.get('userId') || ''

  const [totalCost, setTotalCost] = useState(true)

  let filterBy: string[]
  let filterValues: number[]

  if (categoryQuery === '' && statusQuery === '' && userQuery === '') {
    filterValues = []
    filterBy = []
  } else {
    const dataToSent = getFilterData(categoryQuery, statusQuery, userQuery)

    let filterByNoFiltered: string[] = [
      dataToSent.categoryFilterBy,
      dataToSent.statusFilterBy,
      dataToSent.userFilterBy
    ]
    filterBy = filterByNoFiltered.filter((item: string) => item !== '')

    let filterValuesNoFiltered: number[] = [
      dataToSent.categoryFilterValue,
      dataToSent.statusFilterValue,
      dataToSent.userFilterValue
    ]
    filterValues = filterValuesNoFiltered.filter((item: number) => item !== 0)
  }

  let yearMonth = `${params.year}-${_.padStart(params.month, 2, '0')}`

  if (params.year && !params.month) {
    yearMonth = `${params.year}`
  }

  const variables = {
    variables: { yearMonth, sortBy, order: orderBy, filterBy, filterValues }
  }

  const isMonthClosedData = useQuery(isMonthClosed, { variables: { yearMonth } })

  const isPastMonthClosedData = useQuery(isMonthClosed, { variables: { yearMonth: PAST_MONTH } })

  const { data, loading, startPolling, stopPolling, refetch } = useQuery(invoices, {
    variables: { yearMonth, sortBy, order: orderBy, filterBy, filterValues },
    pollInterval: 600000
  })

  const {
    data: salariesData,
    error: salariesError,
    loading: salariesLoading,
    startPolling: salariesStartPolling,
    stopPolling: salariesStopPolling,
    refetch: salariesRefecth
  } = useQuery(salariesInvoices, {
    variables: { yearMonth },
    pollInterval: 600000,
    context: { clientName: ClientName.GO_API }
  })

  React.useEffect(() => {
    if (typeof document === 'undefined') return

    const handleVisbilityChange = () => {
      if (document.hidden) {
        stopPolling()
        salariesStopPolling()
      } else {
        refetch()
        salariesRefecth()
        startPolling(600000)
        salariesStartPolling(600000)
      }
    }

    document.addEventListener('visibilitychange', handleVisbilityChange)

    return () => document.removeEventListener('visibilitychange', handleVisbilityChange)
  }, [params])

  const getExportFilePath = `${process.env.REACT_APP_API_URL}/exports/${yearMonth}/file?access_token=${token}`

  const refetchQueriesMonthMutations = {
    refetchQueries: [
      {
        query: isMonthClosed,
        variables: { yearMonth: yearMonth }
      },
      {
        query: invoices,
        ...variables
      }
    ]
  }

  const [reOpenMonth] = useMutation(openMonth, {
    ...refetchQueriesMonthMutations
  })

  const [closeActualMonth] = useMutation(closeMonth, {
    ...refetchQueriesMonthMutations
  })

  if (isMonthClosedData.loading || isPastMonthClosedData.loading) {
    return null
  }

  if (loading) {
    return <Loader />
  }

  const approvedInvoices = data && data.invoices.filter((item: InvoiceType) => item.approved)
  const pendingInvoices = data && data.invoices.filter((item: InvoiceType) => !item.approved)
  const salariesInvoicesData = salariesData
    ? salariesData.Salary.map((salary: SalariesType) => {
        return {
          id: salary.id,
          date: salary.Obdobi,
          basePrice: salary.HrubaMzda,
          totalPrice: salary.CistaMzda,
          realPrice: salary.CistaMzda,
          user: {
            id: salary.User.id,
            displayName: salary.User.Name
          },
          status: {
            displayName: 'Paid',
            id: SalaryProps.statusId
          },
          currency: {
            symbol: 'CZK'
          },
          category: {
            displayName: 'Salary',
            id: SalaryProps.categoryId
          },
          isSalary: true
        }
      })
    : []
  const invoicesForDetail = [...pendingInvoices, ...approvedInvoices]

  const closedMonth = isMonthClosedData.data.isMonthClosed

  function handleConnectedButtonLeftClick() {
    setTotalCost(true)
  }

  function handleConnectedButtonRightClick() {
    setTotalCost(false)
  }

  const closeMonthButtonActive =
    !!ADMIN && !closedMonth && !!params.month && !!params.year && pendingInvoices.length === 0

  const monthVariables = { variables: { yearMonth: yearMonth } }

  async function handleMonthReOpen() {
    await apolloClient.resetStore()
    return reOpenMonth(monthVariables)
  }

  async function handleMonthClose() {
    await apolloClient.resetStore()
    return closeActualMonth(monthVariables)
  }

  function handleExport(withText: boolean) {
    const ButtonText = 'Export'
    const exportIcon = <ExportIcon src="/img/export-footer.svg" />

    if (getExportFilePath && closedMonth && !USER) {
      return (
        <Export href={getExportFilePath} download>
          {withText ? ButtonText : exportIcon}
        </Export>
      )
    }

    return withText ? ButtonText : exportIcon
  }

  return (
    <Container>
      {!closedMonth && (
        <SectionContainer>
          <InvoiceSection
            invoicesData={pendingInvoices}
            titleText="Pending"
            containerRef={props.containerRef}
            Component={PendingSection}
            displayFooterMobile={true}
            pendingSectionFooter={true}
            handleConnectedButtonLeftClick={handleConnectedButtonLeftClick}
            handleConnectedButtonRightClick={handleConnectedButtonRightClick}
            variables={variables}
            totalCost={totalCost}
            closedMonth={closedMonth}
            queries={{ category: categoryQuery, status: statusQuery, user: userQuery }}
            yearMonth={yearMonth}
            handleMonthReOpen={handleMonthReOpen}
            handleMonthClose={handleMonthClose}
            handleExportButtonClick={handleExport}
            closeMonthButtonActive={closeMonthButtonActive}
            invoicesForDetail={invoicesForDetail}
            selectOptions={selectOptions}
          />
        </SectionContainer>
      )}
      <SectionContainer>
        <InvoiceSection
          invoicesData={
            (categoryQuery && categoryQuery !== SalaryProps.categoryId.toString()) ||
            (statusQuery && statusQuery !== SalaryProps.statusId.toString())
              ? [...approvedInvoices]
              : [...approvedInvoices, ...salariesInvoicesData]
          }
          titleText="Approved"
          containerRef={props.containerRef}
          Component={PendingSection}
          displayFooterMobile={false}
          pendingSectionFooter={false}
          variables={variables}
          totalCost={totalCost}
          handleConnectedButtonRightClick={handleConnectedButtonRightClick}
          handleConnectedButtonLeftClick={handleConnectedButtonLeftClick}
          closedMonth={closedMonth}
          queries={{ category: categoryQuery, status: statusQuery, user: userQuery }}
          yearMonth={yearMonth}
          handleMonthReOpen={handleMonthReOpen}
          handleMonthClose={handleMonthClose}
          handleExportButtonClick={handleExport}
          closeMonthButtonActive={closeMonthButtonActive}
          invoicesForDetail={invoicesForDetail}
          selectOptions={selectOptions}
          displaySearch={true}
          userQuery={userQuery}
        />
      </SectionContainer>
      <MobileFooter
        invoicesSum={invoicesSum(approvedInvoices, totalCost)}
        paidInvoicesSum={invoicesSum(
          approvedInvoices.filter((item: InvoiceType) => item.status.displayName === 'Paid'),
          totalCost
        )}
        closedMonth={closedMonth}
        handleMonthReOpen={handleMonthReOpen}
        handleMonthClose={handleMonthClose}
        closeMonthButtonActive={closeMonthButtonActive}
        handleExportButtonClick={handleExport(false)}
        exportFilePath={getExportFilePath ? getExportFilePath : ''}
        totalCost={totalCost}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
`

export const SectionContainer = styled.section`
  width: 72em;

  @media (max-width: 1200px) {
    width: 59em;
  }

  @media (max-width: 960px) {
    width: 50em;
  }

  @media (max-width: 830px) {
    width: 40em;
  }

  @media (max-width: 645px) {
    width: 100vw;
  }
`

const PendingSection = styled.section`
  width: 72em;
  border-radius: 8px;
  background-color: #3a3c45;

  @media (max-width: 1200px) {
    width: 59em;
  }

  @media (max-width: 960px) {
    width: 50em;
  }

  @media (max-width: 830px) {
    width: 40em;
  }

  @media (max-width: 645px) {
    margin-left: 20px;
  }
`

const Export = styled.a`
  text-decoration: none;
  color: white;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0 2em;
  margin: 0 -2em;
`

const ExportIcon = styled.img``
