import React, { useState, useEffect, useContext } from 'react'
import Section from './index'
import { headerDataSettingsStatus } from '../../../../constants/sectionsData'
import { useMutation, useQuery } from '@apollo/react-hooks'
import statuses, { StatusItem } from '../../../../queries/statuses'
import DeletePopUp from '../../../layout/PopUps/DeletePopUp'
import InputPopUp from '../PopUps/InputPopUp'
import { settingsDataStatus } from '../../../../constants/formsData'
import deleteStatus from '../../../../mutations/deleteStatus'
import inputValueReduce from '../../../layout/inputValueReduce'
import editStatus from '../../../../mutations/editStatus'
import addStatus from '../../../../mutations/addStatus'
import handleClose from './handleClose'
import handleOpen from './handleOpen'
import isItDuplicity from './isItDuplicity'
import { CurrencyItem } from '../../../../queries/currencies'
import { CategoryItem } from '../../../../queries/categories'
import { InputValueStateType } from '../../../../constants/types'
import { ErrorContext } from '../../../../context/errorContext'
import usePrevious from '../../../../hooks/usePrevious'

export default () => {
  const { data, loading } = useQuery(statuses)
  const [actualStatus, setActualStatus] = useState({
    id: 1,
    displayName: 'To be compensated',
    paid: false
  })
  const [deletePopUpClosed, setDeletePopUpClosed] = useState(true)
  const [editPopUpClosed, setEditPopUpClosed] = useState(true)
  const [addPopUpClosed, setAddPopUpClosed] = useState(true)
  const [inputValue, setInputValue] = useState<InputValueStateType>({})
  const [error, setError] = useState(false)
  const { error: apiError, closedPopUp } = useContext(ErrorContext)
  const prevApiError = usePrevious(apiError)

  const refetchStatusQueries = { refetchQueries: [{ query: statuses }] }

  const [deleteActualStatus] = useMutation(deleteStatus, {
    ...refetchStatusQueries
  })

  const [editActualStatus] = useMutation(editStatus, {
    ...refetchStatusQueries
  })

  const [addNewStatus] = useMutation(addStatus, {
    ...refetchStatusQueries
  })

  const options = [
    { value: 'Paid', label: 'Paid' },
    { value: 'Non-paid', label: 'Non-paid' }
  ]

  const reduced = inputValueReduce(inputValue)
  const buttonInactive = !reduced.statusName || !reduced.paidSelect

  useEffect(() => {
    if (closedPopUp && prevApiError === 'Status Duplicity') {
      setEditPopUpClosed(false)
    }
    if (!editPopUpClosed && prevApiError === 'Status Duplicity') {
      setEditPopUpClosed(true)
    }
  }, [closedPopUp, prevApiError])

  function handleStatusDelete() {
    deleteActualStatus({ variables: { id: actualStatus.id } }).then(() =>
      setDeletePopUpClosed(true)
    )
  }

  if (loading) {
    return null
  }

  function getId(item: CurrencyItem | CategoryItem | StatusItem) {
    if ('displayName' in item && 'paid' in item) {
      setActualStatus({
        id: item.id,
        displayName: item.displayName.toString(),
        paid: item.paid as boolean
      })
    }
  }

  function getInputValue(inputValue: InputValueStateType) {
    setInputValue(inputValue)
  }

  function handleEdit() {
    if (buttonInactive) {
      return null
    }

    const { id } = actualStatus
    const displayName = reduced.statusName
    const paid = reduced.paidSelect.toLowerCase().trim() === 'paid'

    editActualStatus({ variables: { id, displayName: displayName, paid } }).then(() =>
      setEditPopUpClosed(true)
    )
  }

  function handleAdd() {
    if (buttonInactive) {
      return null
    }

    const displayName = reduced.statusName
    const paid = reduced.paidSelect.toLowerCase().trim() === 'paid'

    if (isItDuplicity(data.statuses, 'displayName', displayName)) {
      return setError(true)
    }

    addNewStatus({ variables: { displayName, paid } }).then(function() {
      setAddPopUpClosed(true)
      setActualStatus({
        id: 0,
        displayName: '',
        paid: false
      })
    })
  }

  function getStatusEditValues() {
    const isInvalid = false

    return {
      statusName: { value: actualStatus.displayName, isInvalid },
      paidSelect: { value: actualStatus.paid ? 'Paid' : 'Non-paid', isInvalid }
    }
  }

  return (
    <>
      <DeletePopUp
        handleDelete={handleStatusDelete}
        closed={deletePopUpClosed}
        deleteItemText={actualStatus.displayName}
        handleClosed={() => handleClose(deletePopUpClosed, setDeletePopUpClosed, setError)}
        kindOfDelete="status"
      />
      <InputPopUp
        error={error}
        errorKindText="status"
        closed={editPopUpClosed}
        orangeButtonFunction={handleEdit}
        handleClose={() => handleClose(editPopUpClosed, setEditPopUpClosed, setError)}
        data={settingsDataStatus}
        orangeButtonText="Save changes"
        titleText="Edit Status"
        getInputValue={getInputValue}
        editInputValues={getStatusEditValues()}
        options={options}
        orangeButtonActive={!buttonInactive}
        forCategory={true}
      />
      <InputPopUp
        error={error}
        errorKindText="status"
        closed={addPopUpClosed}
        orangeButtonFunction={handleAdd}
        handleClose={() => handleClose(addPopUpClosed, setAddPopUpClosed, setError)}
        data={settingsDataStatus}
        orangeButtonText="Add"
        titleText="Add status"
        getInputValue={getInputValue}
        editInputValues={getStatusEditValues()}
        options={options}
        orangeButtonActive={!buttonInactive}
        forCategory={true}
      />
      <Section
        getId={getId}
        headerTitle="Status"
        handleAdd={() => handleOpen(addPopUpClosed, setAddPopUpClosed)}
        handleDelete={() => handleOpen(deletePopUpClosed, setDeletePopUpClosed)}
        forStatus={true}
        handleEdit={() => handleOpen(editPopUpClosed, setEditPopUpClosed)}
        headerData={headerDataSettingsStatus}
        data={data.statuses}
        nameProperty="displayName"
      />
    </>
  )
}
