import styled from 'styled-components'

export default styled.div`
  position: fixed;
  z-index: 20;
  display: flex;
  flex-direction: column;
  top: 100px;
  max-height: calc(100% - 14px);
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-height: 560px) {
    top: 14px;
  }
`
