import { gql } from '@apollo/client'

export interface CurrencyItem {
  [key: string]: string | number
  id: number
  symbol: string
}

export default gql`
  {
    currencies {
      id
      symbol
    }
  }
`
