import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useParams, useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import Header from './StyledHeader'
import YearMonthSwitch from './YearMonthSwitch'
import UserDropdownMenu from '../UserDropdown/index'
import OptionDropdown from '../OptionDropdown'
import { ParamsType } from '../ParamsType'
import getActualMonth from '../../screens/Items/functions/getActualMonth'
import getActualYear from '../getActualYear'
import useQueryParams from '../../screens/Items/functions/useQueryParams'
import useLogin from '../../../hooks/useLogin'
import getPathname from './getPathname'
import ConfirmPopUp from '../PopUps/ConfirmPopUp'
import handleRedirect from '../handleRedirect'

interface Props {
  invoiceDate?: any
}

export default (props: Props) => {
  const params = useParams<ParamsType>()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const redirect = useQueryParams().get('redirect') || true
  const summaryInPathname = location.pathname.includes('/summary')
  const invoicesInPathname = location.pathname.includes('/invoices')
  const itemsActive = !summaryInPathname && invoicesInPathname
  const { USER, ACCOUNTANT } = useLogin()

  const [month, setMonth] = useState<number>()
  const [year, setYear] = useState<number>()
  const [popUpClosed, setPopUpClosed] = useState(true)
  const [path, setPath] = useState<string>('')

  const { itemsPathname, summaryPathname } = getPathname(props, params)

  function setYearMonth(year: number, month: number) {
    setYear(year)
    setMonth(month)
  }

  return (
    <Header displayMobile={false}>
      <LeftSection>
        <LogoSpan
          onClick={() => {
            setPath(itemsPathname)
            redirect === 'false'
              ? setPopUpClosed(false)
              : navigate(
                  {
                    pathname: itemsPathname,
                    search: location.search
                  },
                  { state: { year, month } }
                )
          }}
        >
          <Logo src="/img/logo-header.svg" />
        </LogoSpan>
      </LeftSection>
      <CenterSection>
        <YearMonthSwitch
          setYearMonth={setYearMonth}
          setPopUpClosed={setPopUpClosed}
          setPath={setPath}
        />
        <HeaderLine src="/img/header-line.svg" />
        <CenterBottom>
          <ItemLink
            active={itemsActive}
            onClick={() => {
              setPath(itemsPathname)
              redirect === 'false'
                ? setPopUpClosed(false)
                : navigate(
                    {
                      pathname: itemsPathname,
                      search: location.search
                    },
                    { state: { year, month } }
                  )
            }}
          >
            ITEMS
          </ItemLink>
          <ItemLink
            active={summaryInPathname}
            onClick={() => {
              setPath(summaryPathname)
              redirect === 'false'
                ? setPopUpClosed(false)
                : navigate(
                    {
                      pathname: summaryPathname,
                      search: location.search
                    },
                    { state: { year, month } }
                  )
            }}
          >
            SUMMARY
          </ItemLink>
        </CenterBottom>
      </CenterSection>
      <SectionRight>
        <UserDropdownMenu setPopUpClosed={setPopUpClosed} setPath={setPath} />
        <AddInvoiceButton
          aria-label="create new invoice"
          onClick={() =>
            !ACCOUNTANT
              ? handleRedirect(
                  navigate,
                  location.pathname,
                  '/addInvoice',
                  false,
                  setPopUpClosed,
                  setPath,
                  month,
                  year
                )
              : () => null
          }
        >
          <AddInvoiceImg src="/img/add-invoice.svg" />
        </AddInvoiceButton>
        {!USER && (
          <OptionDropdown
            setPopUpClosed={setPopUpClosed}
            setPath={setPath}
            month={month}
            year={year}
          >
            <AddInvoiceImg aria-label="show extended options" src="/img/option-button.svg" />
          </OptionDropdown>
        )}
      </SectionRight>
      <ConfirmPopUp
        closed={popUpClosed}
        handleCancel={() => setPopUpClosed(true)}
        handleConfirm={() => {
          setPopUpClosed(true)
          location.search = ''
          navigate(path.replace('?redirect=false', ''), {
            state: { previousPath: location.pathname, month, year }
          })
        }}
      />
    </Header>
  )
}

const AddInvoiceButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  width: 3rem;
  height: 3rem;
`

const Logo = styled.img`
  @media (min-width: 651px) and (max-width: 750px) {
    margin-right: -2em;
  }
`

const CenterSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (max-width: 650px) {
    margin-right: 0;
  }
`

const LeftSection = styled.section`
  margin-left: 3%;
  flex: 1;
`

const CenterBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SectionRight = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  margin-right: 3%;
  flex: 1;
  gap: 0.5rem;

  & > *:last-child {
    margin-left: 0.5rem;
  }
`

const HeaderLine = styled.img`
  margin-top: 0.7em;
  margin-bottom: 0.9em;
  height: 0.06em;

  @media (max-width: 750px) {
    max-width: 95%;
  }
`

const AddInvoiceImg = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
`

interface ItemLinkProps {
  active: boolean
}

const ItemLink = styled.span<ItemLinkProps>`
  text-decoration: none;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.2em;
  margin-right: 1em;
  color: white;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding-bottom: 0.1em;

  &:hover {
    color: #f29312;
  }

  ${({ active }) =>
    active &&
    css`
      border-bottom: 2px solid #f29312;
      padding-bottom: 0.1em;
      color: #f29312;
    `}
`

const LogoSpan = styled.span`
  cursor: pointer;
  width: 156px;
  display: inline-block;
`
