import { OptionsType } from '../components/layout/Form/OptionsType'
import { CategoryItem } from '../queries/categories'
import { UserType } from '../queries/users'
import { StatusItem } from '../queries/statuses'
import { CurrencyItem } from '../queries/currencies'

export default function optionsData(
  data: Array<CategoryItem | UserType | StatusItem | CurrencyItem>,
  property: keyof CategoryItem | keyof UserType | keyof StatusItem | keyof CurrencyItem
) {
  let option = { value: '', label: '' }
  let options: OptionsType[] = []

  if (!data) return options

  data.forEach((item: any) => {
    option = { value: item[property], label: item[property] }

    if (
      data &&
      data[0] &&
      Object.keys(data[0]).includes('adminLevel') &&
      item.adminLevel === 'ACCOUNTANT'
    ) {
      return null
    }

    return options.push(option)
  })
  return options
}
