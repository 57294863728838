export interface HeaderItem {
  text: string
  clickable: boolean
  width?: number
  mobileWidth?: number
  marginRight?: number
  marginLeft?: number
  mobileMarginRight?: number
  onlyOnMobile?: boolean
  withFilterImg: boolean
  filterText?: string
  onlyAdminInfo?: boolean
  sortByValue?: string
}

export const headerData: HeaderItem[] = [
  {
    text: 'ALL',
    clickable: false,
    width: 108,
    mobileWidth: 10,
    marginLeft: 30,
    withFilterImg: false
  },
  {
    text: 'NUMBER',
    clickable: true,
    width: 140,
    withFilterImg: false,
    onlyOnMobile: false,
    sortByValue: 'documentNumber'
  },
  {
    text: 'DATE',
    clickable: true,
    width: 120,
    mobileWidth: 60,
    withFilterImg: false,
    sortByValue: 'date'
  },
  {
    text: 'STATUS',
    clickable: false,
    width: 198,
    mobileWidth: 90,
    withFilterImg: true
  },
  {
    text: 'CATEGORY',
    clickable: false,
    width: 192,
    mobileWidth: 90,
    withFilterImg: true
  },
  {
    text: 'NAME',
    clickable: false,
    width: 175,
    mobileWidth: 70,
    withFilterImg: true,
    filterText: 'user'
  },
  {
    text: 'PRICE',
    clickable: true,
    width: 135,
    mobileWidth: 80,
    withFilterImg: false,
    sortByValue: 'realPrice'
  },
  {
    text: 'PREVIEW',
    clickable: false,
    width: 90,
    marginRight: 30,
    withFilterImg: false,
    onlyOnMobile: false
  },
  {
    text: 'NUMBER',
    clickable: true,
    width: 150,
    mobileWidth: 50,
    marginRight: 30,
    mobileMarginRight: 30,
    withFilterImg: false,
    onlyOnMobile: true,
    sortByValue: 'documentNumber'
  }
]

export interface SummaryHeaderItem extends HeaderItem {
  summaryMobileDisplay: boolean
  summaryMobileOnly: boolean
}

export const headerDataSummary: SummaryHeaderItem[] = [
  {
    text: 'CATEGORY',
    clickable: false,
    width: 550,
    mobileWidth: 400,
    withFilterImg: false,
    summaryMobileDisplay: true,
    summaryMobileOnly: false
  },
  {
    text: 'VAT',
    clickable: true,
    width: 500,
    mobileWidth: 300,
    withFilterImg: false,
    summaryMobileDisplay: true,
    summaryMobileOnly: false,
    filterText: 'vatprice',
    sortByValue: 'vatPrice'
  },
  {
    text: 'PRICE TOTAL',
    clickable: true,
    width: 103,
    mobileWidth: 100,
    withFilterImg: false,
    summaryMobileDisplay: false,
    summaryMobileOnly: false,
    filterText: 'price',
    sortByValue: 'totalPrice'
  },
  {
    text: 'PRICE',
    clickable: true,
    mobileMarginRight: 0.5,
    width: 50,
    mobileWidth: 50,
    withFilterImg: false,
    summaryMobileDisplay: true,
    summaryMobileOnly: true,
    filterText: 'price',
    sortByValue: 'totalPrice'
  }
]

const NameItem: HeaderItem = {
  text: 'NAME',
  clickable: false,
  width: 456,
  mobileWidth: 550,
  withFilterImg: false
}

const ActionItem: HeaderItem = {
  text: 'ACTION',
  clickable: false,
  withFilterImg: false,
  onlyAdminInfo: true
}

export const headerDataSettingsCategory: HeaderItem[] = [
  NameItem,
  {
    text: 'TAXATION',
    clickable: false,
    width: 300,
    withFilterImg: false
  },
  ActionItem
]

export const headerDataSettingsStatus: HeaderItem[] = [
  NameItem,
  {
    text: 'PAID',
    clickable: false,
    width: 184,
    withFilterImg: false
  },
  ActionItem
]

export const headerDataSettingsCurrencies: HeaderItem[] = [NameItem, ActionItem]

export const headerDataUsers: HeaderItem[] = [
  NameItem,
  {
    text: 'USER NAME',
    clickable: false,
    width: 365,
    mobileWidth: 600,
    withFilterImg: false
  },
  {
    text: 'COMPETENCY',
    clickable: false,
    width: 370,
    mobileWidth: 400,
    withFilterImg: false
  },
  ActionItem
]
