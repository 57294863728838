import styled from 'styled-components'

export default styled.section`
  width: 37.75rem;
  height: 11rem;
  background-color: #3a3c45;
  border-radius: 0 0 7px 7px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 750px) {
    width: 85vw;
  }
`
