import styled from 'styled-components'

export default styled.input`
  display: flex;
  opacity: 0;
  position: relative;
  margin: 0 0 1px 0px;
  width: 23px;
  height: 21px;
  left: 2em;
  bottom: 0.1em;

  @media (max-width: 375px) {
    width: 9px;
    height: 21px;
    left: 1.5em;
  }

  + label {
    background-image: url('/img/checkbox-unchecked.svg');
    background-size: 100%;
    height: 23px;
    width: 23px;
    display: inline-block;
  }

  &:checked + label {
    background: url('/img/checkbox-checked.svg') no-repeat;
  }
`
