import { NavigateFunction, useLocation } from 'react-router-dom'
const queryString = require('query-string')

function setRedirectFalse(navigate: NavigateFunction, locationSearch: string, locationState?: any) {
  const search = queryString.parse(locationSearch)

  if (!search.redirect) {
    search.redirect = 'false'
    navigate(`?${queryString.stringify(search)}`, locationState ?? {})
  }
}

export default setRedirectFalse
