import React from 'react'
import styled, { css } from 'styled-components'
import ConnectedButtonStyles from './ConnectedButtonStyles'

interface Props {
  leftButtonActive: boolean
  rightButtonActive: boolean
  leftButtonOnClick: () => void
  rightButtonOnClick: () => void
}

export default ({
  leftButtonActive,
  rightButtonActive,
  leftButtonOnClick,
  rightButtonOnClick
}: Props) => {
  return (
    <Container>
      <LeftButton onClick={leftButtonOnClick} leftButtonActive={leftButtonActive}>
        Total cost
      </LeftButton>
      <RightButton onClick={rightButtonOnClick} rightButtonActive={rightButtonActive}>
        Real cost
      </RightButton>
    </Container>
  )
}

interface RightButtonProps {
  rightButtonActive: boolean
}

const RightButton = styled.button<RightButtonProps>`
  ${ConnectedButtonStyles}
  border-radius: 0 12px 12px 0;
  border-left: none;
  padding-left: 1em;
  padding-right: 1em;
  position: relative;
  left: -0.3em;

  ${({ rightButtonActive }) =>
    rightButtonActive &&
    css`
      background-color: #535562;
    `}
`

interface LeftButtonProps {
  leftButtonActive: boolean
}

const LeftButton = styled.button<LeftButtonProps>`
  ${ConnectedButtonStyles}
  border-radius: 12px 0 0 12px;
  border-right: none;
  padding-right: 1em;
  padding-left: 1em;

  ${({ leftButtonActive }) =>
    leftButtonActive &&
    css`
      background-color: #535562;
    `}
`

const Container = styled.section`
  display: flex;
  flex-direction: flex-end;
`
