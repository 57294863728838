import getFilterValue from './getFilterValue'

function getFilterData(categoryQuery: string, statusQuery: string, userQuery: string) {
  let values: number[] = [
    getFilterValue(categoryQuery),
    getFilterValue(statusQuery),
    getFilterValue(userQuery)
  ]

  let data = {
    categoryFilterBy: values[0] !== 0 ? 'categoryId' : '',
    statusFilterBy: values[1] !== 0 ? 'statusId' : '',
    userFilterBy: values[2] !== 0 ? 'userId' : '',
    categoryFilterValue: values[0],
    statusFilterValue: values[1],
    userFilterValue: values[2]
  }

  return data
}

export default getFilterData
