import StyledPopUpTitle from './StyledPopUpTitle'
import React from 'react'
import styled from 'styled-components'

interface Props {
  text: string
  closeButton?: boolean
  handleClose?: () => void
  icon?: boolean
}

export default ({ text, closeButton, handleClose, icon }: Props) => (
  <StyledHeaderPopUp>
    <CenterSection closeButton={closeButton}>
      {icon && <ErrorIcon src="/img/errorPopUp.svg" />}
      <StyledPopUpTitle>{text}</StyledPopUpTitle>
    </CenterSection>
    {closeButton && <Close src="/img/add-invoice-close.svg" onClick={handleClose} />}
  </StyledHeaderPopUp>
)

interface CenterSection {
  closeButton?: boolean
}

const CenterSection = styled.section<CenterSection>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({ closeButton }) =>
    closeButton &&
    `
    margin-left: 1.3125em;
  `}
`

const Close = styled.img`
  cursor: pointer;
  position: relative;
  right: 2em;
  width: 1.5em;

  @media (max-width: 860px) {
    right: 3em;
  }
`
const ErrorIcon = styled.img`
  margin-right: 1em;
`

const StyledHeaderPopUp = styled.header`
  width: auto;
  border-radius: 5px 5px 0 0;
  height: 4.125rem;
  background-color: #343741;
  display: flex;
  justify-content: flex-end;
`
