import React from 'react'
import PropagateLoader from 'react-spinners/PropagateLoader'
import styled from 'styled-components'

export default () => (
  <Container>
    <PropagateLoader
      size={15}
      color="#F29312"
      css={'display:flex;justify-content:center;align-items:center;'}
    />
  </Container>
)

const Container = styled.div`
  position: fixed;
  height: 70vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`
