import formatPrice from './formatPrice'
import React from 'react'
import styled from 'styled-components'

function renderFooterPrice(priceText: string, price: number, currency?: string) {
  return (
    <>
      <StyledText>{priceText}</StyledText>
      <StyledTextBold>
        {formatPrice(price)}
        &nbsp;{currency ? currency : 'CZK'}
      </StyledTextBold>
    </>
  )
}

const StyledText = styled.span`
  color: white;
  font-family: 'Montserrat';
  font-size: 0.875em;
  margin-right: 0.4em;

  @media (max-width: 645px) {
    font-size: 0.8em;
  }

  @media (max-width: 440px) {
    font-size: 0.73em;
  }
`

const StyledTextBold = styled(StyledText)`
  font-weight: bold;
`

export default renderFooterPrice
