import { InvoiceType } from '../queries/invoices'
import { CategoryItem } from '../queries/categories'
import { UserType } from '../queries/users'
import { InvoiceStatusCategoryUserType } from '../queries/invoices'

const getUpdatedSelectOptions = (
  options: Array<CategoryItem | UserType>,
  invoiceData: InvoiceType | undefined,
  property: 'category' | 'user'
) => {
  if (!options || options?.length < 1) {
    return []
  }

  const addedOptions: InvoiceStatusCategoryUserType[] = []

  const isInOptions = (option: InvoiceStatusCategoryUserType) => {
    return (
      options.some((item) => item.displayName === option.displayName) ||
      addedOptions.some((item) => item.displayName === option.displayName)
    )
  }

  const getProperty = () => {
    const data = invoiceData?.[property]

    if (!data || isInOptions(data as InvoiceStatusCategoryUserType)) {
      return []
    }

    addedOptions.push(data as InvoiceStatusCategoryUserType)

    return [data]
  }

  const returnValue = [
    ...options,
    ...(invoiceData?.issuers ?? []).flatMap((issuer) => {
      const option = issuer[property]
      if (!isInOptions(option)) {
        addedOptions.push(option)
        return [option]
      }
      return []
    }),
    ...getProperty(),
  ]

  const forbiddenCategories = ['Group']

  const categoryReturnValue: CategoryItem[] = (returnValue as CategoryItem[]).filter(
    (item) => !forbiddenCategories.includes(item.displayName)
  )

  return property === 'category' ? categoryReturnValue : (returnValue as UserType[])
}

export default getUpdatedSelectOptions