import React from 'react'
import Header from './Header'
import MobileHeader from './MobileHeader'
interface Props {
  invoiceDate?: any
}
export default (props: Props) => {
  return (
    <>
      <Header invoiceDate={props.invoiceDate} />
      <MobileHeader invoiceDate={props.invoiceDate} />
    </>
  )
}
