import React from 'react'
import styled from 'styled-components'
import BigOrangeButton from '../../layout/BigOrangeButton'
import disabledButtonStyles from '../../layout/disabledButtonStyles'

interface Props {
  handleSave: () => void
  handleDelete: () => void
  saveButtonActive: boolean
}

export default ({ handleSave, handleDelete, saveButtonActive }: Props) => (
  <Container>
    <SaveButton active={saveButtonActive} onClick={saveButtonActive ? handleSave : () => null}>
      SAVE
    </SaveButton>
    <DeleteButton active={true} onClick={handleDelete}>
      DELETE
    </DeleteButton>
  </Container>
)

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface SaveButtonProps {
  active: boolean
}

const SaveButton = styled(BigOrangeButton)<SaveButtonProps>`
  width: 11em;

  @media (max-width: 560px) {
    font-size: 0.875em;
    width: 9em;
  }

  ${({ active }) => !active && disabledButtonStyles}
`

const DeleteButton = styled(SaveButton)`
  background-color: #3a3c45;
  border: 1px solid #f45151;

  &:hover {
    background-color: #f45151;
  }
`
