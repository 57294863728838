import React from 'react'
import { Navigate } from 'react-router-dom'
import Props from './Props'
import getActualYear from '../components/layout/getActualYear'
import useLogin from '../hooks/useLogin'

const LoginRoute = ({ Component }: Props) => {
  const actualMonth = (new Date().getMonth() + 1).toString()
  const loginData = useLogin()

  return loginData.user ? (
    <Navigate to={`/invoices/${actualMonth}-${getActualYear()}`} />
  ) : (
    <Component />
  )
}

export default LoginRoute
