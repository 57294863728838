import React from 'react'
import styled from 'styled-components'

interface Props {
  value: string
  onClick: () => void
  disabled?: boolean
}

export default ({ value, onClick, disabled }: Props) => {
  return (
    <StyledButton onClick={onClick}>
      <CalendarImage src="/img/calendar.svg" />
      <StyledDate isDisabled={disabled}>{value.replace(/\//g, '-')}</StyledDate>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  border-radius: 5px;
  background-color: #4a4c57;
  width: 21.5rem;
  height: 2.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1em;
  border: none;
  outline: none;
  cursor: pointer;
  justify-content: flex-start;

  @media (max-width: 560px) {
    font-size: 16px;
    width: 18.25em;
  }
`

const CalendarImage = styled.img`
  margin-right: 0.5em;
`

interface StyledDateProps {
  isDisabled: boolean | undefined
}

const StyledDate = styled.span<StyledDateProps>`
  color: ${props => (props.isDisabled ? '#C4C8CC' : 'white')};
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 500;
  width: 100%;
  text-align: start;

  @media (max-width: 560px) {
    font-size: 0.75rem;
  }
`
