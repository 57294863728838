import React from 'react'
import styled from 'styled-components'
import StyledLink from '../StyledLink'
import { userDropdownLinks } from '../../../constants/links'
import StyledLogout from '../StyledLogout'
import { useMutation } from '@apollo/react-hooks'
import logout from '../../../mutations/logout'
import handleLogout from '../handleLogout'
import handleRedirect from '../handleRedirect'
import { useNavigate, useLocation } from 'react-router'
import useQueryParams from '../../screens/Items/functions/useQueryParams'
import useLogin from '../../../hooks/useLogin'

interface Props {
  setPopUpClosed: (state: boolean) => void
  setPath: (state: string) => void
}

export default ({ setPopUpClosed, setPath }: Props) => {
  const [logoutUser] = useMutation(logout)
  const location = useLocation()
  const navigate = useNavigate()

  const redirect = useQueryParams().get('redirect')
  const { ADMIN } = useLogin()

  return (
    <Container>
      {userDropdownLinks.map(item => {
        if (!ADMIN && item.onlyAdminLink) {
          return null
        }

        return (
          <StyledLinkSmallContainer
            onClick={() => {
              setPath(item.url)
              redirect === 'false'
                ? setPopUpClosed(false)
                : handleRedirect(navigate, location.pathname, item.url, redirect === 'false')
            }}
            data-testid={`show-${item.text.toLowerCase()}`}
          >
            <StyledLinkSmall>{item.text}</StyledLinkSmall>
          </StyledLinkSmallContainer>
        )
      })}
      <StyledLinkSmallContainer
        data-testid="logout-button"
        onClick={() => handleLogout(logoutUser)}
      >
        <StyledLogout>Logout</StyledLogout>
      </StyledLinkSmallContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #535562;
  width: 13em;
  height: 10em;
  border-radius: 0.313em;
  position: fixed;
  right: 8em;
  top: 5.875em;
  box-shadow: -8px -2px 16px 0 rgba(43, 42, 42, 0.2);
`

const StyledLinkSmall = styled(StyledLink)`
  font-size: 0.9em;
  margin-left: 1em;
`
const StyledLinkSmallContainer = styled.div`
  height: 100%;
  color: #fff;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.875em;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #636572;
    border-radius: 5px;
  }
`
