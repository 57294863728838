import React from 'react'
import styled from 'styled-components'
import { HandleChangeEvent } from './Input'
import InputText from './InputText'
import { TextAreaInputType } from '../../../constants/formsData'

interface Props {
  item: TextAreaInputType
  isInvalid: boolean
  value: string
  handleChange: (item: TextAreaInputType, event: HandleChangeEvent) => void
  disabled?: boolean
}

export default ({ item, isInvalid, value, handleChange, disabled }: Props) => {
  return (
    <Container>
      <InputText text={item.text} disabledText={disabled} />
      <RegTextarea
        isInvalid={isInvalid}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(item, event)}
        mobileWidth={item.mobileWidth}
        width={item.width}
        height={item.height}
        disabled={disabled}
        data-testid={item.text.toLowerCase()}
      />
    </Container>
  )
}

const Container = styled.div`
  margin-top: 1.5em;
`

interface InputProps {
  mobileWidth: number
  isInvalid: boolean
  width: number
  height: number
}

const RegTextarea = styled.textarea<InputProps>`
  background-color: #4a4c57;
  border: none;
  padding-top: 0.5em;
  padding-right: 1em;
  line-height: 1.1875em;
  width: ${props => props.width}em;
  height: ${props => props.height}em;
  display: inline-block;
  border-radius: 5px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  padding-left: 1em;
  color: white;
  resize: none;

  @media (max-width: 560px) {
    width: ${props => (props.mobileWidth ? props.mobileWidth : 5)}em;
  }

  &:focus {
    outline: none;
  }
`
