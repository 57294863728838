import React, { useRef } from 'react'
import styled from 'styled-components'
import Div100vh from 'react-div-100vh'

import Header from '../../layout/Header'
import Sections from './Sections'

export default () => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    // @ts-ignore
    <StyledContainer ref={ref}>
      <Header />
      <Sections containerRef={ref} />
    </StyledContainer>
  )
}

const StyledContainer = styled(Div100vh)`
  width: 100vw;
  overflow: hidden auto;
`
