import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import './Calendar.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import CustomInput from './CustomInput'
import styled from 'styled-components'
import InputText from '../Form/InputText'
import { useLocation } from 'react-router'
import enGb from 'date-fns/locale/en-GB'
registerLocale('en-gb', enGb)

interface Props {
  getDate: (date: Date) => void
  defaultStartDate?: Date
  disabled?: boolean
}

export default ({ getDate, defaultStartDate, disabled }: Props) => {
  const [date, setDate] = useState(new Date())
  const location = useLocation()

  function handleChange(date: Date) {
    setDate(date)
  }

  useEffect(() => {
    getDate(date)
  }, [date])

  useEffect(() => {
    if (defaultStartDate) {
      setDate(new Date(defaultStartDate))
    }
  }, [])

  return (
    <Container>
      <InputText text="DATE" disabledText={disabled} />
      <DatePicker
        onChange={handleChange}
        disabled={disabled}
        selected={date}
        // @ts-ignore
        customInput={<CustomInput />}
        showPopperArrow={false}
        dateFormat="dd/MM/yyyy"
        locale="en-gb"
      />
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 1em;

  @media (max-width: 860px) {
    margin-top: 1em;
  }
`
