import React, { ReactComponentElement, ReactElement } from 'react'
import styled, { css, keyframes } from 'styled-components'
import Line from '../Line'
import useLogin from '../../../hooks/useLogin'

interface Props {
  pendingSectionFooter?: boolean
  isSticky: boolean
  displayMobile: boolean
  emptyMonth?: boolean
  children: ReactComponentElement<any>[] | ReactComponentElement<any>
}

interface FooterElement extends Element {
  style: React.CSSProperties
}

export default (props: Props) => {
  const { ADMIN } = useLogin()
  const displayFooter = !props.emptyMonth || (!props.pendingSectionFooter && ADMIN)

  window.addEventListener('resize', handleFooterResize)

  function handleFooterResize() {
    const footer: FooterElement | null = document.querySelector('.pendingSectionFooter')

    if (!footer) return

    footer.style.width =
      window.innerWidth < 645 ? (footer.style.width = `${window.innerWidth}px`) : ''
  }

  return (
    <>
      {displayFooter &&
        (props.pendingSectionFooter ? <Line width={100} /> : <SummaryLine width={100} />)}
      {displayFooter && (
        <FooterContainer isSticky={props.isSticky}>
          <Footer
            displayMobile={props.displayMobile}
            isSticky={props.isSticky}
            mobileWidth={props.pendingSectionFooter ? window.innerWidth : null}
            className={`${props.pendingSectionFooter ? 'pendingSectionFooter' : ''}`}
          >
            {props.children}
          </Footer>
        </FooterContainer>
      )}
    </>
  )
}

interface FooterProps {
  isSticky: boolean
  displayMobile: boolean
  mobileWidth: number | null
}

interface FooterContainer {
  isSticky: boolean
}

const FooterContainer = styled.div<FooterContainer>`
  position: sticky;
  bottom: 0px;
  background-color: #2c3138;
  padding-bottom: 15px;
  width: 100%;
  z-index: 3;
  border-radius: 0px;

  ${({ isSticky }) =>
    isSticky &&
    css`
      border-radius: 8px;
      box-shadow: 0 0 14px 6px rgba(44, 49, 56, 0.5);
      padding-bottom: 15px;
    `}
`
const animation = keyframes`
    from {
      box-shadow: inset rgba(0, 0, 0, 0) 0 0 0;
    }
    to {
      box-shadow: inset rgba(0, 0, 0, 0.0000001) 0 0 0 10px;
    }
`

const Footer = styled.footer<FooterProps>`
  height: 4em;
  background-color: #3a3c45;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  border-radius: 0 0 8px 8px;
  position: sticky;
  z-index: 4;
  white-space: nowrap;
  bottom: 15px;
  animation: 4s infinite ${animation};

  @media (max-width: 645px) {
    ${({ mobileWidth }) => mobileWidth && `width:${mobileWidth}px`};
    display: ${props => (props.displayMobile ? 'flex' : 'none')};
  }

  @media (max-width: 375px) {
    font-size: 0.68em;
  }

  @media (max-width: 450px) {
    font-size: 0.68em;
    padding: 0 10px;
  }

  ${({ isSticky }) =>
    isSticky &&
    css`
      border-radius: 0;
      box-shadow: 0 0 14px 6px rgba(44, 49, 56, 0.5);
    `}
`

const SummaryLine = styled(Line)`
  @media (max-width: 645px) {
    width: 100%;
    margin-left: 0;
  }
`
