import { css } from 'styled-components'

export default css`
  border: 1px solid #9198a0;
  border-radius: 0.5em;
  background-color: transparent;
  color: #cbcfd3;
  outline: none;
  font-family: 'Montserrat';
  font-size: 0.875em;
  font-weight: 600;
`
