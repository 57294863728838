import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDropzone } from 'react-dropzone'
import ComponentLoader from '../../layout/ComponentLoader'

interface Props {
  file: FilePreview | undefined
  setFile: React.Dispatch<React.SetStateAction<FilePreview | undefined>>
}

type FilePreview = (File & { preview: string })[]

export default ({ setFile, file }: Props) => {
  const [showDropzone, setShowDropzone] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const accept = {
    'application/pdf': ['.pdf']
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: acceptedFiles => {
      const filesToSet = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
      setIsLoading(true)
      setFile(files => (files ? [...files, ...filesToSet] : filesToSet))
      setIsLoading(false)
    }
  })

  useEffect(() => {
    if (file && file.length > 0) setShowDropzone(false)
    if (!file || file.length === 0) setShowDropzone(true)
  }, [file])

  const handleView = () => {
    if (file && file[0]) {
      return file.map(f => (
        <FileContainer
          {...getRootProps({
            className: 'dropzone',
            onClick: event => event.stopPropagation()
          })}
        >
          <FileIcon src="/img/document.svg" />
          <FileLink target="_blank" href={f.preview}>
            {f.name}
          </FileLink>
          <RemoveIcon src="/img/close.svg" onClick={() => setFile(file.filter(el => el !== f))} />
        </FileContainer>
      ))
    }
  }

  return (
    <>
      {showDropzone && !isLoading && (
        <StyledDropzone {...getRootProps({ className: 'dropzone' })}>
          <DropzoneInput {...getInputProps()} />
          <AddImage src="/img/add-invoice.svg" />
          <StyledText>
            DROP
            <br />
            DOCUMENTS
          </StyledText>
        </StyledDropzone>
      )}

      {!showDropzone && !isLoading && (
        <Preview {...getRootProps({ className: 'dropzone' })}>
          <FileList>{handleView()}</FileList>
          <HiddenInput {...getInputProps()} />
        </Preview>
      )}

      {isLoading && (
        <LoaderContainer>
          <ComponentLoader />
        </LoaderContainer>
      )}
    </>
  )
}

const sizes = css`
  width: 23em;
  height: 32em;

  @media (max-width: 960px) {
    width: 21.5em;
  }

  @media (max-width: 560px) {
    width: 18em;
    height: 22em;
  }
`

const StyledDropzone = styled.div`
  ${sizes}
  border-radius: 20px;
  background-image: url('/img/dropzone-border.svg');
  background-color: #4a4c57;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: none;
`

const DropzoneInput = styled.input`
  outline: none;
  border: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`

const AddImage = styled.img`
  cursor: pointer;
`

const StyledText = styled.span`
  margin-top: 1em;
  font-family: Montserrat;
  font-size: 0.8em;
  color: #9198a0;
  font-weight: 600;
  cursor: pointer;
  line-height: 16px;
  text-align: center;
`

const HiddenInput = styled.input`
  opacity: 0;
`

const FileList = styled.div`
  background-color: #4a4c57;
  border-radius: 1.25em;
  height: 32em;
  width: 23em;
  overflow-y: scroll;
`

const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #d1cdcd;
  font-family: Montserrat;
  font-size: 0.875rem;
  padding: 1em;
`

const FileIcon = styled.img``

const RemoveIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const FileLink = styled.a`
  color: #d1cdcd;
  font-family: Montserrat;
  font-size: 0.875rem;
  text-align: left;
  width: 100%;
  display: inline-block;
  margin: 0.5em;
`

const Preview = styled.div`
  margin-bottom: 1em;
`

const LoaderContainer = styled.div`
  ${sizes}
  background-color: #4a4c57;
  margin-bottom: 1em;
  border-radius: 20px;
`
