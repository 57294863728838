import React from 'react'
import { useNavigate } from 'react-router'
import jwtDecode from 'jwt-decode'
import useLogin from '../hooks/useLogin'

const TokenVerifyComponent = () => {
  const navigate = useNavigate()
  const { token } = useLogin()

  React.useEffect(() => {
    if (token && token) {
      const { exp }: { exp: number } = jwtDecode(token)

      if (exp * 1000 < Date.now()) {
        if (window.location.pathname !== '/login') {
          localStorage.clear()
          navigate('/login')
          window.location.reload()
        }
      }
    }
  }, [window.location.pathname])

  return <React.Fragment />
}

export default TokenVerifyComponent
