import React from 'react'
import styled from 'styled-components'
import FullViewportOverlay from '../../FullViewportOverlay'
import TopSectionPopUp from '../TopSectionPopUp'
import { Text } from '../../Styled/Text'
import PopUpButtons from '../PopUpButtons'
import StyledContainer from '../StyledContainer'
import BottomSection from '../BottomSection'
import Center from '../Center'

interface Props {
  deleteItemText: string
  kindOfDelete: string
  closed: boolean
  handleClosed: () => void
  handleDelete: () => void
}

export default ({ deleteItemText, kindOfDelete, closed, handleDelete, handleClosed }: Props) => {
  if (closed) {
    return null
  }

  return (
    <>
      <FullViewportOverlay />
      <Center>
        <StyledContainer>
          <TopSectionPopUp text={`Delete ${kindOfDelete}`} />
          <BottomSection>
            <CenterSection>
              <PopUpText>
                Are you sure you want to delete <strong>{deleteItemText}</strong> {kindOfDelete} ?
              </PopUpText>
              <Buttons>
                <PopUpButtons
                  tranparentButtonText="Cancel"
                  orangeButtonText="Delete"
                  orangeButtonFunction={handleDelete}
                  transparentButtonFunction={handleClosed}
                  orangeButtonActive={true}
                />
              </Buttons>
            </CenterSection>
          </BottomSection>
        </StyledContainer>
      </Center>
    </>
  )
}

const CenterSection = styled.section`
  margin-top: 2em;
  padding-left: 1em;
  padding-right: 1em;
`

const PopUpText = styled(Text)`
  font-weight: 500;

  @media (max-width: 375px) {
    font-size: 0.81em;
  }
`

const Buttons = styled.div`
  position: relative;
  bottom: -4em;
  right: -10em;

  @media (max-width: 750px) {
    right: -15vw;
  }

  @media (max-width: 375px) {
    right: -10%;
  }
`
