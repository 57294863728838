import React from 'react'
import styled, { css } from 'styled-components'
import renderFooterPrice from '../renderFooterPrice'
import Footer from './Footer'

interface Props {
  invoicesSum: number
  isSticky: boolean
  displayMobile: boolean
  vatPrice?: number
  summaryCurrencyName?: string
}

export default ({ invoicesSum, isSticky, displayMobile, vatPrice, summaryCurrencyName }: Props) => {
  function renderPriceSection(priceText: string, price: number, currency?: string) {
    return <Section>{renderFooterPrice(priceText, price, currency)}</Section>
  }

  return (
    <SummaryFooter displayMobile={displayMobile} isSticky={isSticky}>
      <PriceSection>
        {renderPriceSection('VAT :', vatPrice || 0, summaryCurrencyName)}
        {renderPriceSection('Total :', invoicesSum, summaryCurrencyName)}
      </PriceSection>
    </SummaryFooter>
  )
}

const SummaryFooter = styled(Footer)`
  @media (max-width: 645px) {
    font-size: 0.9em;
  }

  @media (max-width: 440px) {
    padding: 0 10px;
  }

  @media (max-width: 375px) {
    display: none;
  }
`

const PriceSection = styled.section`
  display: flex;
  flex-direction: row;
`

const Section = styled.section`
  display: flex;
  flex-direction: row;
  margin-right: 3em;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 960px) {
    margin-right: 1.5em;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: space-between;
    height: 2.5em;
  }

  @media (max-width: 500px) {
    margin-right: 1em;
  }

  @media (max-width: 440px) {
    margin-right: 0.5em;
  }
`
