import React, { useEffect, useState } from 'react'
import Dropdown from 'rc-dropdown'
import 'rc-dropdown/assets/index.css'
import '../Dropdown.css'
import UserDropdownMenu from './UserDropdownMenu'
import styled, { css } from 'styled-components'
import HeaderArrow from './HeaderArrow'
import useLogin from '../../../hooks/useLogin'

interface Props {
  setPopUpClosed: (state: boolean) => void
  setPath: (state: string) => void
}

export default ({ setPopUpClosed, setPath }: Props) => {
  const [visible, setVisible] = useState(false)
  const { user } = useLogin()

  useEffect(() => {
    function hideOnScroll() {
      setVisible(false)
    }
    document.addEventListener('scroll', hideOnScroll, true)
    return () => document.removeEventListener('scroll', hideOnScroll, true)
  }, [])

  return (
    <Dropdown
      trigger={['click']}
      overlay={<UserDropdownMenu setPopUpClosed={setPopUpClosed} setPath={setPath} />}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <StyledP visible={visible}>
        <p aria-label="show user context menu">{user ? user.displayName : ''}</p>
        <ImageContainer>
          <HeaderArrow />
        </ImageContainer>
      </StyledP>
    </Dropdown>
  )
}

interface SectionProps {
  visible: boolean
}

const StyledP = styled.div<SectionProps>`
  font-size: 0.95em;
  font-family: 'Montserrat';
  font-weight: 500;
  margin-right: 1.3em;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 750px) {
    white-space: nowrap;
  }

  &:hover {
    color: #f29312;
    path {
      fill: #f29312;
    }
  }

  ${({ visible }) =>
    visible &&
    css`
      color: #f29312;
      path {
        fill: #f29312;
      }
    `}
`

const ImageContainer = styled.div`
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.4em;
`
