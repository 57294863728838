import styled from 'styled-components'

export default styled.button`
  color: white;
  background-color: #f29312;
  border: 1px solid transparent;
  border-radius: 5px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.875em;
  margin-top: 2em;
  padding: 1em;
  width: 24.8em;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: #cd7c0e;
  }

  @media (max-width: 450px) {
    width: 85%;
  }
`
