import React from 'react'
import ItemsOverlay from './ItemsOverlay'
import { useQuery } from '@apollo/react-hooks'
import statuses from '../../../../queries/statuses'

interface Props {
  selectedInvoiceIds: string[]
  variables: any
  handleClose: () => void
  handleBack: () => void
  selectedInvoiceStatuses: string[]
}

export default (props: Props) => {
  const { data, loading } = useQuery(statuses)

  return (
    <ItemsOverlay
      selectedInvoicesIds={props.selectedInvoiceIds}
      variables={props.variables}
      handleClose={props.handleClose}
      data={data.statuses}
      handleBack={props.handleBack}
      title="STATUS"
      loading={loading}
      property="status"
      selectedInvoiceCategoriesStatuses={props.selectedInvoiceStatuses}
    />
  )
}
