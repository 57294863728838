import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import CenterSectionHeader from '../../layout/CenterSectionHeader'
import { useNavigate, useLocation } from 'react-router'
import getActualMonth from '../Items/functions/getActualMonth'
import {
  FormDataType,
  userSettingsData,
  userSettingsDataPassword
} from '../../../constants/formsData'
import { HandleChangeEvent, SelectInput } from '../../layout/Form/Input'
import BigOrangeButton from '../../layout/BigOrangeButton'
import inputValueReduce from '../../layout/inputValueReduce'
import user from '../../../queries/user'
import { Navigate } from 'react-router'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Loader from '../../layout/Loader'
import editUser from '../../../mutations/editUser'
import StyledCheckbox from '../../layout/StyledCheckbox'
import changePassword from '../../../mutations/changePassword'
import getActualYear from '../../layout/getActualYear'
import useQueryParams from '../Items/functions/useQueryParams'
import disabledButtonStyles from '../../layout/disabledButtonStyles'
import { InputValueStateType } from '../../../constants/types'
import useLogin from '../../../hooks/useLogin'
import Div100vh from 'react-div-100vh'
import { useMediaQuery } from 'react-responsive'
import InputPopUp from '../Settings/PopUps/InputPopUp'
import ConfirmPopUp from '../../layout/PopUps/ConfirmPopUp'
import setRedirectFalse from '../../layout/setRedirectFalse'
import handleRedirect from '../../layout/handleRedirect'
import { ErrorContext } from '../../../context/errorContext'
import usePrevious from '../../../hooks/usePrevious'
import logout from '../../../mutations/logout'
import handleLogout from '../../layout/handleLogout'

export default () => {
  const isMobile = useMediaQuery({ query: 'max-width: 560px' })
  const loginData = useLogin()
  const queryString = require('query-string')
  const navigate = useNavigate()

  if (!loginData.user) {
    return <Navigate to="/login" />
  }
  const location = useLocation()
  const [inputValue, setInputValue] = useState<InputValueStateType>({})
  const [passwordInputValue, setPasswordInputValue] = useState<InputValueStateType>({})
  const [warning, setWarning] = useState(false)
  const [firstInputValue, setFirstInputValue] = useState<InputValueStateType>({})
  const redirect = useQueryParams().get('redirect')
  const [popUpButtonInactive, setPopUpButtonInactive] = useState(true)
  const [cancelButtonInactive, setCancelButtonInactive] = useState(false)
  const [buttonInactive, setButtonInactive] = useState(true)
  const { error: apiError, closedPopUp } = useContext(ErrorContext)

  const [error, setError] = useState(false)
  const [editPopUpClosed, setEditPopUpClosed] = useState({ closed: true, keepData: false })

  const [confirmClosed, setConfirmClosed] = useState(true)
  const [redirectConfirmClosed, setRedirectConfirmClosed] = useState(true)
  const [saveCanceled, setSaveCanceled] = useState(true)

  const userId = loginData.user.id

  const refetchQueries = {
    refetchQueries: [{ query: user, variables: { userId, includeEmail: true } }]
  }

  const getActualUserData = useQuery(user, {
    variables: { userId, includeEmail: true }
  })
  const [logoutUser] = useMutation(logout)
  const [editCurrentUser] = useMutation(editUser, {
    ...refetchQueries
  })
  const [changeUserPassword] = useMutation(changePassword, {
    ...refetchQueries
  })

  interface CheckboxValueStateType {
    notify: boolean
  }

  const [checkboxValue, setCheckboxValue] = useState<CheckboxValueStateType>({
    notify: false
  })

  const reducedPassword = inputValueReduce(passwordInputValue)

  useEffect(() => {
    setPopUpButtonInactive(
      !reducedPassword.password ||
      !reducedPassword.newPassword ||
      !reducedPassword.newPasswordRepeat
    )
  }, [passwordInputValue])

  useEffect(() => {
    if (closedPopUp && apiError === 'Invalid password') {
      setEditPopUpClosed({ closed: false, keepData: false })
    }
    if (!editPopUpClosed.closed && apiError === 'Invalid password') {
      setEditPopUpClosed({ closed: true, keepData: false })
    }
  }, [closedPopUp, apiError])

  function getPasswordInputValue(PasswordInputValue: InputValueStateType) {
    setPasswordInputValue(PasswordInputValue)
  }

  function handleChange(item: FormDataType, event: HandleChangeEvent) {
    const inputData = {
      ...inputValue,
      ...{
        [item.name]: {
          value: event.target.value,
          isInvalid: item.required && event.target.value === ''
        }
      }
    }
    const reduced = inputValueReduce(inputData)
    setButtonInactive(
      !reduced.username ||
      !reduced.displayName ||
      !reduced.email ||
      (reduced.username === getActualUserData.data.user.username &&
        reduced.displayName === getActualUserData.data.user.displayName &&
        reduced.email === getActualUserData.data.user.email)
    )
    setRedirectFalse(navigate, location.search)
    setInputValue(inputData)
  }

  function handleSelectChange(event: React.ChangeEvent<HTMLInputElement>) {
    return setCheckboxValue({ notify: event.target.checked })
  }

  function setFirstValues() {
    if (getActualUserData.loading) {
      return {}
    }

    const { user } = getActualUserData.data
    const isInvalid = false

    const username = { username: { value: user.username, isInvalid: isInvalid } }
    const displayName = { displayName: { value: user.displayName, isInvalid } }
    const email = { email: { value: user.email, isInvalid: isInvalid } }

    return { ...username, ...displayName, ...email }
  }

  function handlePasswordChange() {
    if (popUpButtonInactive) {
      return null
    }
    setPopUpButtonInactive(true)
    setCancelButtonInactive(true)
    if (
      reducedPassword.password &&
      reducedPassword.newPassword &&
      reducedPassword.newPassword === reducedPassword.newPasswordRepeat
    ) {
      const passwordData = {
        userId: getActualUserData.data.user.id,
        password: reducedPassword.password,
        newPassword: reducedPassword.newPassword
      }
      setError(false)
      changeUserPassword({ variables: { data: passwordData } }).then(() => {
        navigate('?redirect=true')
        setEditPopUpClosed({ closed: true, keepData: false })
        setWarning(true)
      })
    } else {
      setError(true)
    }
    setCancelButtonInactive(false)
  }

  function handleSave() {
    const reduced = inputValueReduce(inputValue)

    if (buttonInactive) {
      return null
    }

    const data = {
      id: getActualUserData.data.user.id,
      username: reduced.username,
      displayName: reduced.displayName,
      email: reduced.email,
      adminLevel: getActualUserData.data.user.adminLevel
    }

    setButtonInactive(true)
    setSaveCanceled(true)

    return editCurrentUser({ variables: { data } }).then(() => {
      navigate('?redirect=true')
      setWarning(true)
    }).then(() => {
      handleLogout(logoutUser)
    })
  }

  if (!getActualUserData.loading) {
    if (Object.entries(inputValue).length === 0) {
      setInputValue(setFirstValues())
    }

    if (Object.entries(firstInputValue).length === 0) {
      setFirstInputValue(inputValue)
    }
    let equalValues = true

    if (Object.entries(firstInputValue).length !== 0) {
      equalValues =
        JSON.stringify(inputValueReduce(inputValue)) ===
        JSON.stringify(inputValueReduce(firstInputValue))
    }

    if (!equalValues && (!redirect || redirect !== 'false') && !getActualUserData.loading) {
      const search = queryString.parse(location.search)

      if (!search.redirect || search.redirect === 'true') {
        search.redirect = 'false'
      }

      navigate(`?${queryString.stringify(search)}`)
    }

    if (equalValues && redirect && !getActualUserData.loading && redirect !== 'true') {
      const search = queryString.parse(location.search)

      if (search.redirect) {
        search.redirect = 'true'
      }

      setRedirectFalse(navigate, search)
    }
  }

  if (getActualUserData.loading) {
    return <Loader />
  }

  const Container = isMobile ? MainContainerMobile : MainContainer

  function handleClose() {
    if (cancelButtonInactive) return

    if (
      reducedPassword.password ||
      reducedPassword.newPassword ||
      reducedPassword.newPasswordRepeat
    ) {
      setEditPopUpClosed({ closed: true, keepData: true })
      setConfirmClosed(false)
    } else {
      setEditPopUpClosed({ closed: true, keepData: false })
    }
  }

  function confirm(action: boolean) {
    if (action) {
      setEditPopUpClosed({ closed: true, keepData: false })
      setConfirmClosed(true)
      return
    }
    setEditPopUpClosed({ closed: false, keepData: true })
    setConfirmClosed(true)
  }

  return (
    <Container>
      <SectionContainer>
        <CenterSectionHeader
          handleClose={() => {
            redirect === 'false'
              ? setRedirectConfirmClosed(false)
              : handleRedirect(
                navigate,
                location.pathname,
                `/invoices/${getActualMonth() + 1}-${getActualYear()}`,
                redirect === 'false'
              )
          }}
          text="User Settings"
          Container={CenterHeader}
        />
        <InputsSection>
          <MobileMainTitle>User Settings</MobileMainTitle>
          {userSettingsData.map((item: FormDataType) => (
            <Margin key={item.name}>
              <SelectInput
                key={item.name}
                item={item}
                isInvalid={inputValue[item.name] && inputValue[item.name].isInvalid}
                value={(inputValue[item.name] && inputValue[item.name].value) || ''}
                handleChange={handleChange}
                mobileSmaller={true}
              />
            </Margin>
          ))}
          <CheckboxContainer>
            <Row>
              <StyledCheckbox
                type="checkbox"
                name="image"
                value={checkboxValue.notify.toString()}
                onChange={e => handleSelectChange(e)}
              />
              <label htmlFor="image"></label>
            </Row>
            <CheckboxText>Send notifications</CheckboxText>
          </CheckboxContainer>
          <InputPopUp
            errorKindText="password"
            error={error}
            closed={editPopUpClosed.closed}
            orangeButtonFunction={handlePasswordChange}
            handleClose={handleClose}
            data={userSettingsDataPassword}
            orangeButtonText="Save"
            titleText="Change password"
            getInputValue={getPasswordInputValue}
            orangeButtonActive={!popUpButtonInactive}
            transparentButtonActive={!cancelButtonInactive}
            forPassword={true}
            keepData={editPopUpClosed.keepData}
          />
          <ConfirmPopUp
            closed={saveCanceled}
            isModalClosed={editPopUpClosed.closed}
            handleCancel={() => setSaveCanceled(true)}
            handleConfirm={handleSave}
            customText="Saving these changes will log you off."

          />
          <ConfirmPopUp
            closed={confirmClosed}
            isModalClosed={editPopUpClosed.closed}
            handleCancel={() => confirm(false)}
            handleConfirm={() => confirm(true)}
          />
          <ConfirmPopUp
            closed={redirectConfirmClosed}
            isModalClosed={editPopUpClosed.closed}
            handleCancel={() => setRedirectConfirmClosed(true)}
            handleConfirm={() => navigate(`/invoices/${getActualMonth() + 1}-${getActualYear()}`)}
          />
          <PasswordContainer>
            <Label>Password</Label>
            <Link onClick={() => setEditPopUpClosed({ closed: false, keepData: false })}>
              Change
            </Link>
          </PasswordContainer>
          <SaveButton active={!buttonInactive} onClick={() => setSaveCanceled(false)}>
            SAVE CHANGES
          </SaveButton>
          <WarningContainer>
            {warning && (
              <ChangesText>Changes will take effect the next time you log in</ChangesText>
            )}
          </WarningContainer>
        </InputsSection>
      </SectionContainer>
    </Container>
  )
}

const MainContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-top: 2.5em;
  margin-bottom: 1em;
`

const MainContainerMobile = styled(Div100vh)`
  width: 100vw;
  margin-top: 2em;
  margin-bottom: 0;
  background-color: #2c3138;
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const InputsSection = styled.div`
  background-color: #3a3c45;
  padding-bottom: 4em;
  padding-top: 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 20px 20px;

  @media (max-width: 560px) {
    background-color: #2c3138;
    padding-bottom: 0em;
  }
`

const CenterHeader = styled.header`
  background-color: #343741;
  width: 56.25em;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 860px) {
    width: 90vw;
  }

  @media (max-width: 560px) {
    display: none;
  }
`

const Margin = styled.div`
  margin-top: 1.5em;
`

interface SaveButtonProps {
  active: boolean
}

const SaveButton = styled(BigOrangeButton)<SaveButtonProps>`
  width: 346px;
  @media (max-width: 560px) {
    width: 262px;
  }

  ${({ active }) => !active && disabledButtonStyles}
`

const CheckboxContainer = styled.div`
  display: flex;
  width: 400px;
  flex-direction: row;
  justify-content: flex-start;

  @media (max-width: 560px) {
    width: 316px;
  }
`

const CheckboxText = styled.span`
  font-family: Montserrat;
  font-size: 0.875em;
  font-weight: 500;
  margin: 0 0 0.2em 0.2em;
  color: white;
  display: flex;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const MobileMainTitle = styled.div`
  display: none;

  @media (max-width: 560px) {
    display: flex;
    font-size: 1.063em;
    color: white;
    font-family: Montserrat;
    font-weight: 500;
    margin-bottom: 1em;
    width: 262px;
    justify-content: flex-start;
  }
`

const ChangesText = styled.span`
  font-family: Montserrat;
  font-size: 0.81em;
  font-weight: 500;
  color: #fc6969;
`

const WarningContainer = styled.div`
  position: relative;
  left: -0.8em;
  top: 0.2em;
  height: 1.5em;
`

const PasswordContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #535562;
  border-bottom: 1px solid #535562;
  align-items: baseline;
  width: 345px;
  margin-top: 30px;
  font-family: 'Montserrat';
  font-weight: 500;
  padding: 30px 0;

  @media (max-width: 560px) {
    width: 16.15rem;
  }
`

const Link = styled.a`
  color: #f29312;
  cursor: pointer;
`

const Label = styled.p`
  color: white;
  font-size: 18px;
  margin: 0;
`
