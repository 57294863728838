import React from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import invoices from '../../../../queries/invoices'
import { ItemType } from '../../ItemType'
import { Container, DownSection, LeftArrowImg, Overlay } from '../../DropdownStyledComponents'
import StyledTopSection from '../StyledTopSection'
import StyledText from '../StyledText'
import handleStatusCategoryEdit from '../../../screens/Items/functions/handleStatusCategoryEdit'
import Cancel from '../Cancel'
import editInvoiceNotRequired from '../../../../mutations/editInvoiceNotRequired'
import { style as orangeDotStyle } from '../../OrangeDot'

interface Props {
  handleClose: () => void
  handleBack: () => void
  selectedInvoiceCategoriesStatuses: string[]
  selectedInvoicesIds: string[]
  variables: any
  data: ItemType[]
  title: string
  loading: boolean
  property: string
}

export default (props: Props) => {
  const [editInvoices] = useMutation(editInvoiceNotRequired, {
    refetchQueries: [{ query: invoices, ...props.variables }]
  })

  if (props.loading) {
    return null
  }

  function renderItems() {
    return props.data.map((item: ItemType, index: number) => {
      const allEqual = props.selectedInvoiceCategoriesStatuses.every(
        v => v === props.selectedInvoiceCategoriesStatuses[0]
      )

      const isActive = allEqual && item.displayName === props.selectedInvoiceCategoriesStatuses[0]

      return (
        <StyledItemText
          onClick={() =>
            handleStatusCategoryEdit(item, props.selectedInvoicesIds, editInvoices, props.property)
          }
          key={index}
          isActive={isActive}
        >
          {item.displayName}
        </StyledItemText>
      )
    })
  }

  return (
    <>
      <Overlay />
      <StyledContainer>
        <StyledTopSection>
          <LeftArrowImg onClick={props.handleBack} />
          <StyledTextSpanBold>{props.title}</StyledTextSpanBold>
        </StyledTopSection>
        <ScrollableDownSection>{renderItems()}</ScrollableDownSection>
        <Cancel handleCancel={props.handleClose} />
      </StyledContainer>
    </>
  )
}

const StyledContainer = styled(Container)`
  height: 37em;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
`

interface StyledItemTextProps {
  isActive?: boolean
}

const StyledItemText = styled.span<StyledItemTextProps>`
  margin-left: 1em;
  margin-top: 2em;
  min-height: 1em;
  margin-bottom: 0.5em;
  ${StyledText}
  font-size: 0.875em;
  display: flex;
  align-items: center;

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 800;

      :after {
        display: block;
        content: '';
        ${orangeDotStyle}
      }
    `}
`

const ScrollableDownSection = styled(DownSection)`
  margin-top: 1em;
  height: 33em;
  overflow-y: auto;
  flex: 1;
`

const StyledTextSpanBold = styled(StyledItemText)`
  margin-top: 2em;
  font-weight: 600;
  min-height: 2em;
`
