import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import SectionHeaderItem from '../../layout/SectionHeaderItem'
import {
  headerDataSummary,
  headerDataUsers,
  HeaderItem,
  SummaryHeaderItem
} from '../../../constants/sectionsData'
import { SectionHeaderSummary } from '../Summary/Main'
import User from './User'
import { useMutation, useQuery } from '@apollo/react-hooks'
import users, { UserType } from '../../../queries/users'
import InputPopUp from '../Settings/PopUps/InputPopUp'
import { addUserData, editUserData, editUserPasswordData } from '../../../constants/formsData'
import DeletePopUp from '../../layout/PopUps/DeletePopUp'
import deleteUser from '../../../mutations/deleteUser'
import editUser from '../../../mutations/editUser'
import areFormInputsValid from '../../layout/areFormInputsValid'
import inputValueReduce from '../../layout/inputValueReduce'
import user from '../../../queries/user'
import addUser from '../../../mutations/addUser'
import TopSection from '../../layout/TopSection'
import { ActualUserType, InputValueStateType } from '../../../constants/types'
import useLogin from '../../../hooks/useLogin'
import { SectionTable, InvoiceLine, SectionHeaderRow } from '../Items/InvoiceSection'
import changePasswordByAdmin from '../../../mutations/changePasswordByAdmin'
import ConfirmPopUp from '../../layout/PopUps/ConfirmPopUp'
import { ErrorContext } from '../../../context/errorContext'

export default () => {
  const { data, loading } = useQuery(users)
  const [closed, setClosed] = useState({ closed: true, keepData: false })
  const [closedAdd, setClosedAdd] = useState({ closed: true, keepData: false })
  const [closedPassword, setClosedPassword] = useState({ closed: true, keepData: false })
  const [deletePopUpClosed, setDeletePopUpClosed] = useState(true)
  const [actualUserValues, setActualUserValues] = useState<any>({})
  const [inputData, setInputData] = useState<InputValueStateType>({})
  const { ADMIN } = useLogin()
  const [error, setError] = useState(false)
  const { error: apiError, closedPopUp } = useContext(ErrorContext)

  const [confirmClosed, setConfirmClosed] = useState(true)
  const [confirmEditClosed, setConfirmEditClosed] = useState(true)
  const [confirmPasswordClosed, setConfirmPasswordClosed] = useState(true)

  const options = [
    { value: 'user', label: 'User' },
    { value: 'accountant', label: 'Accountant' },
    { value: 'admin', label: 'Admin' }
  ]

  const buttonInactive = !areFormInputsValid(editUserData, inputData)
  const buttonInactiveAdd = !areFormInputsValid(addUserData, inputData)
  const PasswordButtonInactive = !areFormInputsValid(editUserPasswordData, inputData)

  const userId = actualUserValues.id ? actualUserValues.id : 1

  const getUserData = useQuery(user, { variables: { userId: userId, includeEmail: false } })

  const refetchUsersQueries = { refetchQueries: [{ query: users }] }

  const [deleteActualUser] = useMutation(deleteUser, {
    ...refetchUsersQueries
  })
  const [editActualUser] = useMutation(editUser, {
    ...refetchUsersQueries
  })

  const [addNewUser] = useMutation(addUser, {
    ...refetchUsersQueries
  })

  const [changePassword] = useMutation(changePasswordByAdmin, {
    ...refetchUsersQueries
  })

  useEffect(() => {
    if (apiError === 'User already exist') {
      setClosedAdd({ closed: true, keepData: false })
    }
    if (apiError === 'User already exist') {
      setClosed({ closed: true, keepData: false })
    }
  }, [closedPopUp, apiError])

  if (loading) {
    return null
  }

  function getActualUser(actualUser: ActualUserType) {
    setActualUserValues(actualUser)
  }

  function getInputData(inputValue: InputValueStateType) {
    setError(false)
    setInputData(inputValue)
  }

  function handleUserDelete() {
    deleteActualUser({ variables: { userId: actualUserValues.id } }).then(() =>
      setDeletePopUpClosed(true)
    )
  }

  function handleUserAdd() {
    let adminLevel = ''
    const reduced = inputValueReduce(inputData)
    const reducedCompetency = reduced.competencySelect.toLowerCase().trim()

    if (buttonInactiveAdd) {
      return null
    }

    if (reducedCompetency === 'admin') {
      adminLevel = 'ADMIN'
    } else if (reducedCompetency === 'accountant') {
      adminLevel = 'ACCOUNTANT'
    } else if (reducedCompetency === 'user') {
      adminLevel = 'USER'
    }

    const data = {
      username: reduced.username,
      displayName: reduced.name,
      password: reduced.password,
      adminLevel: adminLevel,
      email: reduced.email
    }

    addNewUser({ variables: { data: data } }).then(() =>
      setClosedAdd({ closed: true, keepData: false })
    )
  }

  function getUserEditValues() {
    const isInvalid = false
    let adminLevel = actualUserValues.competency

    if (
      actualUserValues.competency === 'active_user' ||
      actualUserValues.competency === 'inactive_user'
    ) {
      adminLevel = 'user'
    }

    const name = { name: { value: actualUserValues.name, isInvalid } }
    const username = { username: { value: actualUserValues.username, isInvalid } }
    const competency = {
      competencySelect: { value: adminLevel, isInvalid }
    }

    return { ...name, ...username, ...competency }
  }

  function handleEdit() {
    if (buttonInactive) {
      return null
    }

    let adminLevel = ''
    const reduced = inputValueReduce(inputData)
    const reducedCompetency = reduced.competencySelect.toLowerCase().trim()

    if (reducedCompetency === 'admin') {
      adminLevel = 'ADMIN'
    } else if (reducedCompetency === 'accountant') {
      adminLevel = 'ACCOUNTANT'
    } else if (reducedCompetency === 'user') {
      adminLevel = 'USER'
    }

    const data = {
      id: actualUserValues.id,
      displayName: reduced.name,
      username: reduced.username,
      adminLevel: adminLevel,
      email: getUserData.data.user.email
    }

    editActualUser({ variables: { data } }).then(() => setClosed({ closed: true, keepData: false }))
  }

  function openDeletePopUp() {
    setDeletePopUpClosed(false)
    setClosed({ closed: true, keepData: false })
  }

  function handleCloseDeletePopUp() {
    setDeletePopUpClosed(true)
    setClosed({ closed: true, keepData: false })
  }

  function handlePasswordChange() {
    if (PasswordButtonInactive) return

    const reduced = inputValueReduce(inputData)

    if (reduced.newPassword && reduced.newPassword === reduced.newPasswordRepeat) {
      const passwordData = {
        userId: actualUserValues.id,
        newPassword: reduced.newPassword
      }
      changePassword({ variables: { data: passwordData } }).then(() =>
        setClosedPassword({ closed: true, keepData: false })
      )
    } else {
      setError(true)
    }
  }

  function renderUserLine() {
    return headerDataSummary.map((item: SummaryHeaderItem) => {
      return (
        <td style={{ padding: 0 }}>
          <InvoiceLine
            style={{ marginLeft: item.marginLeft || 0, marginRight: item.marginRight || 0 }}
          />
        </td>
      )
    })
  }

  function handleCloseAdd() {
    const reduced = inputValueReduce(inputData)
    if (reduced.username || reduced.name || reduced.password || reduced.email) {
      setClosedAdd({ closed: true, keepData: true })
      setConfirmClosed(false)
    } else {
      setClosedAdd({ closed: true, keepData: false })
    }
  }

  function confirm(action: boolean) {
    if (action) {
      setClosedAdd({ closed: true, keepData: false })
      setConfirmClosed(true)
    } else {
      setClosedAdd({ closed: false, keepData: true })
      setConfirmClosed(true)
    }
  }

  function handleClosePassword() {
    const reduced = inputValueReduce(inputData)
    if (reduced.newPassword || reduced.newPasswordRepeat) {
      setClosedPassword({ closed: true, keepData: true })
      setConfirmPasswordClosed(false)
    } else {
      setClosedPassword({ closed: true, keepData: true })
    }
  }

  function confirmPassword(action: boolean) {
    if (action) {
      setClosedPassword({ closed: true, keepData: false })
      setConfirmPasswordClosed(true)
    } else {
      setClosedPassword({ closed: false, keepData: true })
      setConfirmPasswordClosed(true)
    }
  }

  function handleCloseEdit() {
    const reduced = inputValueReduce(inputData)
    if (
      (reduced.name || reduced.username || reduced.competencySelect) &&
      (getUserEditValues().name.value !== reduced.name ||
        getUserEditValues().competencySelect.value !== reduced.competencySelect ||
        getUserEditValues().username.value !== reduced.username)
    ) {
      setClosed({ closed: true, keepData: true })
      setConfirmEditClosed(false)
    } else {
      setClosed({ closed: true, keepData: false })
    }
  }
  function confirmEdit(action: boolean) {
    if (action) {
      setClosed({ closed: true, keepData: false })
      setConfirmEditClosed(true)
    } else {
      setClosed({ closed: false, keepData: true })
      setConfirmEditClosed(true)
    }
  }

  return (
    <Container>
      <DeletePopUp
        handleDelete={handleUserDelete}
        closed={deletePopUpClosed}
        handleClosed={() => handleCloseDeletePopUp()}
        deleteItemText={actualUserValues.name}
        kindOfDelete="user"
      />
      <InputPopUp
        editInputValues={getUserEditValues()}
        handleClose={() => handleClosePassword()}
        orangeButtonText="Save changes"
        error={error}
        orangeButtonFunction={handlePasswordChange}
        data={editUserPasswordData}
        closed={closedPassword.closed}
        getInputValue={getInputData}
        titleText="Change Password"
        errorKindText="password"
        forUser={true}
        orangeButtonActive={!PasswordButtonInactive}
        options={options}
        forPassword={true}
        keepData={closedPassword.keepData}
      />
      <InputPopUp
        editInputValues={getUserEditValues()}
        handleClose={() => handleCloseEdit()}
        orangeButtonText="Save changes"
        error={false}
        orangeButtonFunction={handleEdit}
        data={editUserData}
        closed={closed.closed}
        getInputValue={getInputData}
        titleText="Edit User"
        errorKindText="user"
        forUser={true}
        forCurrency={true}
        orangeButtonActive={!buttonInactive}
        options={options}
        closeButton={true}
        transparentButtonFunction={() => openDeletePopUp()}
        transparentButtonText="Delete user"
        keepData={closed.keepData}
      />
      <InputPopUp
        editInputValues={getUserEditValues()}
        handleClose={handleCloseAdd}
        orangeButtonText="Save"
        error={false}
        orangeButtonFunction={handleUserAdd}
        data={addUserData}
        closed={closedAdd.closed}
        getInputValue={getInputData}
        titleText="Add User"
        errorKindText="user"
        forAddUser={true}
        forCurrency={true}
        orangeButtonActive={!buttonInactiveAdd}
        options={options}
        keepData={closedAdd.keepData}
      />
      <ConfirmPopUp
        closed={confirmClosed}
        isModalClosed={closedAdd.closed}
        handleCancel={() => confirm(false)}
        handleConfirm={() => confirm(true)}
      />
      <ConfirmPopUp
        closed={confirmPasswordClosed}
        isModalClosed={closedPassword.closed}
        handleCancel={() => confirmPassword(false)}
        handleConfirm={() => confirmPassword(true)}
      />
      <ConfirmPopUp
        closed={confirmEditClosed}
        isModalClosed={closed.closed}
        handleCancel={() => confirmEdit(false)}
        handleConfirm={() => confirmEdit(true)}
      />

      <Section>
        <MainTitleSection>
          <TopSection
            handleAdd={() => setClosedAdd({ closed: false, keepData: false })}
            mainTitleText="Users"
            label="user"
          />
        </MainTitleSection>
        <SectionBody>
          <thead>
            <HeaderUsers>
              {headerDataUsers.map((item: HeaderItem) => {
                if (!ADMIN && item.onlyAdminInfo) {
                  return null
                }

                return (
                  <SectionHeaderRow width={item.width || 0} mobileWidth={item.mobileWidth || 0}>
                    <SectionHeaderItem item={item} />
                  </SectionHeaderRow>
                )
              })}
            </HeaderUsers>
          </thead>
          <tbody>
            {data.users.map((item: UserType) => (
              <>
                <tr>{renderUserLine()}</tr>
                <User
                  item={item}
                  handleEdit={() => setClosed({ closed: false, keepData: false })}
                  handlePasswordChange={() => setClosedPassword({ closed: false, keepData: false })}
                  getActualUser={getActualUser}
                />
              </>
            ))}
          </tbody>
        </SectionBody>
      </Section>
    </Container>
  )
}

const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
`

const Section = styled.div`
  display: flex;
  width: 74em;
  flex-direction: column;
  margin: 0 30px;

  @media (max-width: 645px) {
    margin: 0 20px;
  }

  @media (max-width: 1250px) {
    width: 92.4vw;
  }
`

const MainTitleSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

const HeaderUsers = styled(SectionHeaderSummary)`
  text-align: left;
`

const SectionBody = styled(SectionTable)`
  border-radius: 8px;
  background-color: #3a3c45;
  padding: 0 30px;

  @media (max-width: 440px) {
    padding: 0 10px;
  }
`
