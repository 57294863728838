import { HeaderItem } from '../../constants/sectionsData'
import { NavigateFunction } from 'react-router-dom'
const queryString = require('query-string')

function handleSort(
  item: HeaderItem,
  variableObject: any,
  navigate: NavigateFunction,
  location: any
) {
  const newSortByValue = item.sortByValue
  const query = queryString.parse(location.search)
  query.sort = newSortByValue

  if (variableObject.sortBy !== newSortByValue) {
    query.order = 'DESCENDING'
  } else if (variableObject.order === 'DESCENDING') {
    query.order = 'ASCENDING'
  } else {
    delete query.order
    delete query.sort
  }

  navigate(`?${queryString.stringify(query)}`)
}

export default handleSort
