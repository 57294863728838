import styled from 'styled-components'

interface LineProps {
  width: number
}

export default styled.hr<LineProps>`
  height: 1px;
  width: ${({ width }) => width}%;
  border-top: 1px solid #535562;
  border-bottom: none;
  border-right: none;
  border-left: none;
  margin-top: -0.1em;
  margin-bottom: -0.05em;

  @media (max-width: 645px) {
    width: 35em;
    margin-left: 1em;
  }
`
