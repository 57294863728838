import { CategoryItem } from '../../../../queries/categories'
import { CurrencyItem } from '../../../../queries/currencies'
import { StatusItem } from '../../../../queries/statuses'

function isItDuplicity(
  data: Array<CategoryItem | CurrencyItem | StatusItem>,
  compareValueFirstProperty: string,
  compareValueSecond: string
) {
  const duplicityMatch = data.find(
    (item: any) =>
      item[compareValueFirstProperty].toLowerCase().trim() ===
      compareValueSecond.toLowerCase().trim()
  )

  return duplicityMatch !== undefined
}

export default isItDuplicity
