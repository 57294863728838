import React from 'react'
import styled from 'styled-components'
import 'rc-dropdown/assets/index.css'
import '../Dropdown.css'
import Dropdown from 'rc-dropdown'
import StyledText from './StyledText'

interface Props {
  overlay: React.ReactElement
  visible: boolean
  onVisibleChange: ((visible: boolean) => void) | undefined
  text: string
}

export default (props: Props) => {
  return (
    <Dropdown
      overlay={props.overlay}
      trigger={['click']}
      visible={props.visible}
      onVisibleChange={props.onVisibleChange}
    >
      <DropdownLinkContainer>
        <DropDownLink>{props.text}</DropDownLink>
        <ArrowRightImg />
      </DropdownLinkContainer>
    </Dropdown>
  )
}

const DropdownLinkContainer = styled.div`
  display: flex;
  width: 100vw;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
`

const DropDownLink = styled.span`
  ${StyledText}
  margin-top: 3em;
  margin-left: 1em;
  margin-right: 2em;
`

const ArrowRightImg = styled.img.attrs({
  src: '/img/arrow-right-dropdown.svg'
})`
  position: relative;
  top: 1em;
`
