import React from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import roundPrice from '../../layout/roundPrice'

interface Props {
  totalPrice: number
  vatPrice: number
  currencyName: string
}

export default (props: Props) => {
  return (
    <FooterSection>
      <Section>
        <StyledText>Total :</StyledText>
        <StyledTextBold>
          <NumberFormat
            value={roundPrice(props.totalPrice)}
            displayType={'text'}
            thousandSeparator={true}
          />{' '}
          {props.currencyName}
        </StyledTextBold>
      </Section>
      <Section>
        <StyledText>VAT :</StyledText>
        <StyledTextBold>
          <NumberFormat
            value={roundPrice(props.vatPrice)}
            displayType={'text'}
            thousandSeparator={true}
          />{' '}
          {props.currencyName}
        </StyledTextBold>
      </Section>
    </FooterSection>
  )
}

const FooterSection = styled.footer`
  display: none;

  @media (max-width: 375px) {
    display: flex;
    bottom: 0;
    height: 4em;
    width: 100%;
    background-color: #3a3c45;
    box-shadow: 0 0 9px 0 rgba(44, 49, 56, 0.9);
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px;
    z-index: 999;
    align-items: center;
    border-radius: 0 0 8px 8px;
  }
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
  height: 2.5em;
  margin-right: 2em;
`

const StyledText = styled.span`
  color: white;
  font-family: Montserrat;
  font-size: 0.6875rem;
`

const StyledTextBold = styled(StyledText)`
  font-weight: 600;
`
