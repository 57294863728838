import React from 'react'
import styled from 'styled-components'
import CategorySection from './Section/CategorySection'
import StatusSection from './Section/StatusSection'
import CurrencySection from './Section/CurrencySection'

export default () => {
  return (
    <Container>
      <RightSection>
        <CategorySection />
      </RightSection>
      <LeftSection>
        <section>
          <StatusSection />
        </section>
        <section>
          <CurrencySection />
        </section>
      </LeftSection>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 3em;

  @media (max-width: 1260px) {
    flex-direction: column;
    align-items: center;
  }
`

const RightSection = styled.section`
  margin-right: 5.875em;

  @media (max-width: 1260px) {
    margin-right: 0;
  }

  @media (max-width: 645px) {
    display: flex;
    justify-content: center;
  }
`

const LeftSection = styled.section`
  display: flex;
  flex-direction: column;

  @media (max-width: 645px) {
    display: flex;
    justify-content: center;
  }
`
