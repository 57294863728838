import React from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../../../layout/Header'
import ImportSalaries from './ImportSalaries'

export default () => {
  const location = useLocation()
  return (
    <>
      <Header invoiceDate={location.state} />
      <ImportSalaries />
    </>
  )
}
