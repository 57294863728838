import styled from 'styled-components'

export default styled.h2`
  color: white;
  font-family: Montserrat;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.375em;

  @media (max-width: 375px) {
    font-size: 0.875em;
  }
`
