import { UserType } from '../../../../constants/types'
import getIndexById from '../../../../utils/getIndexById'

function getFirstOptions(data: any, userSelectOptions: UserType[]) {
  const isInvalid = false

  if (data) {
    const statusFirstOption = {
      statusSelect: {
        value: data.invoice.status.displayName,
        isInvalid,
      },
    }
    const categoryFirstOption = {
      categorySelect: {
        value: data.invoice.category.displayName,
        isInvalid,
      },
    }
    const issuerFirstOption = {
      issuerSelect: {
        value:
          data.invoice?.issuers[0]?.user.displayName || !userSelectOptions
            ? data.invoice.issuers[0]?.user.displayName
            : userSelectOptions[getIndexById<UserType>(userSelectOptions, data.invoice.userId)]
                ?.displayName,
        isInvalid,
      },
    }
    const currencyFirstOption = {
      currencySelect: {
        value: data.invoice.currency.symbol,
        isInvalid,
      },
    }
    const vatPrice = {
      vatPrice: {
        value: data.invoice.basePrice.toString(),
        isInvalid,
      },
    }
    const price = {
      price: {
        value: data.invoice.totalPrice.toString(),
        isInvalid,
      },
    }
    const description = {
      descriptions: {
        value: data.invoice.description,
        isInvalid,
      },
    }

    return {
      ...statusFirstOption,
      ...categoryFirstOption,
      ...issuerFirstOption,
      ...currencyFirstOption,
      ...vatPrice,
      ...price,
      ...description,
    }
  }
  return {}
}

export default getFirstOptions
