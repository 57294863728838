import React from 'react'
import styled, { css } from 'styled-components'

interface Props {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  clearSearch: () => void
}

const Search = (props: Props) => {
  return (
    <Container>
      <SearchIcon src="/img/search.svg" />
      <Input type="text" value={props.value} onChange={props.onChange} placeholder="Search" />
      <CloseIcon value={props.value} src="/img/close-search.svg" onClick={props.clearSearch} />
    </Container>
  )
}

export default Search

const Container = styled.div`
  margin-bottom: 1em;
  margin-right: 0.5em;
`

interface InputProps {
  value: string
}

const Input = styled.input<InputProps>`
  font-family: Montserrat;
  border-radius: 0.8em;
  background-color: #3a3c45;
  border: none;
  outline: none;
  color: #cbcfd3;
  width: 8em;
  height: 2.4em;
  padding: 0 2.4em 0 2.4em;
  font-size: 0.833125em;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #cbcfd3;
  }
  :-ms-input-placeholder {
    color: #cbcfd3;
  }

  @media (max-width: 600px) {
    width: 9.2em;
  }
  @media (max-width: 375px) {
    width: 6.375em;
  }
`

interface CloseIconProps {
  value: string
}

const CloseIcon = styled.img<CloseIconProps>`
  cursor: pointer;
  position: relative;
  right: 1.3em;
  visibility: visible;
  ${props =>
    props.value.length === 0 &&
    css`
      visibility: hidden;
    `}
`

const SearchIcon = styled.img`
  position: relative;
  left: 1.5em;
  top: 0.2em;
`
