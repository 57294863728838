import { InvoiceType, InvoiceStatusCategoryUserType } from '../../../../queries/invoices'
import { useQuery } from '@apollo/react-hooks'
import categories from '../../../../queries/categories'
import users from '../../../../queries/users'
import statuses from '../../../../queries/statuses'

const queries = { category: categories, issuers: users, status: statuses }
const names = { category: 'categories', issuers: 'users', status: 'statuses' }

function useText(property: 'category' | 'status' | 'issuers', id: number, data: InvoiceType[]) {
  let invoice: InvoiceType | undefined

  if (property === 'issuers') {
    invoice =
      data &&
      data.find((item: InvoiceType) => item[property] && item[property]?.[0]?.user?.id === id)
  } else {
    invoice = data && data.find((item: InvoiceType) => item[property] && item[property]?.id === id)
  }

  const { data: queryData, loading } = useQuery(queries[property], {
    skip: !!invoice,
    variables: { paranoid: false }
  })

  if (!id || loading) {
    return ''
  }

  if (invoice) {
    if (property === 'issuers') {
      return invoice[property]?.[0]?.user?.displayName ?? ''
    }
    return invoice[property]?.displayName ?? ''
  }

  const query =
    queryData && queryData[names[property]].find((item: { id: number }) => item.id === id)
  return query ? query.displayName : ''
}

export default useText
