import styled from 'styled-components'

export default styled.div`
  width: 100vw;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  z-index: 250;
  display: flex;
  right: 0;
`
