interface ItemsType {
  text: string
  url: string
}

export interface LinkType {
  text: string
  url: string
  clickable?: boolean
  dropdown: boolean
  colorRed?: boolean
  items?: ItemsType[]
  onlyAdmin?: boolean
  inClosedMonth?: boolean
  onlyAdminInClosedMonth?: boolean
}

const Links: LinkType[] = [
  {
    text: 'Preview',
    url: '',
    dropdown: false,
    clickable: true,
    inClosedMonth: true,
  },
  {
    text: 'Detail',
    url: '',
    dropdown: false,
    clickable: true,
    inClosedMonth: true,
  },
  {
    text: 'Edit',
    url: '',
    dropdown: false,
    clickable: true,
  },
  {
    text: 'Category',
    url: '',
    dropdown: true,
  },
  {
    text: 'Status',
    url: '',
    dropdown: true,
    inClosedMonth: true,
    onlyAdminInClosedMonth: true,
  },
  {
    text: 'Unapprove',
    url: '',
    dropdown: false,
    clickable: true,
    onlyAdmin: true,
  },
  {
    text: 'Approve',
    url: '',
    dropdown: false,
    clickable: true,
    onlyAdmin: true,
  },
  {
    text: 'Duplicate',
    url: '',
    dropdown: false,
    clickable: true,
  },
  {
    text: 'Delete',
    url: '',
    dropdown: false,
    colorRed: true,
    clickable: true,
  },
]

export default Links

interface UserDropdownLink extends ItemsType {
  onlyAdminLink: boolean
}

export const userDropdownLinks: UserDropdownLink[] = [
  {
    text: 'Settings',
    url: '/settings',
    onlyAdminLink: true,
  },
  {
    text: 'Users',
    url: '/users',
    onlyAdminLink: false,
  },
  {
    text: 'User Settings',
    url: '/userSettings',
    onlyAdminLink: false,
  },
]
