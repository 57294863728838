import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams, useLocation, useNavigate } from 'react-router'
import { ParamsType } from '../ParamsType'
import getActualMonth from '../../screens/Items/functions/getActualMonth'
import getActualYear from '../getActualYear'
import canRedirect from '../canRedirect'
import useQueryParams from '../../screens/Items/functions/useQueryParams'

const _ = require('lodash')

interface Props {
  setYearMonth: (year: number, month: number) => void
  setPopUpClosed: (state: boolean) => void
  setPath: (state: string) => void
}

interface LocationType {
  state: {
    year: number
    month: number
  }
  pathname: string
  search: string
  hash: string
  key: string
}

export default ({ setYearMonth, setPopUpClosed, setPath }: Props) => {
  const params = useParams<ParamsType>()
  const location = useLocation() as LocationType
  const navigate = useNavigate()
  const redirect = useQueryParams().get('redirect') || true

  let [month, setMonth] = useState(getActualMonth() + 1)
  let [year, setYear] = useState(getActualYear())

  const isSummary = location.pathname.includes('summary')
  const isInvoices =
    !location.pathname.includes('summary') && location.pathname.includes('invoices')

  function getUrl(year: number, month: number) {
    let itemsUrl

    if (params.year && !params.month) {
      itemsUrl = `/invoices/${year}`
    } else {
      itemsUrl = `/invoices/${month}-${year}`
    }

    return isSummary ? `${itemsUrl}/summary${location.search}` : `${itemsUrl}${location.search}`
  }

  const redirectUrlInEffect = isSummary
    ? `/invoices/${getActualMonth() + 1}-${getActualYear()}/summary`
    : `/invoices/${getActualMonth() + 1}-${getActualYear()}`

  function handleRightClick() {
    let currentYear = year
    let currentMonth = month

    if (params.year && !params.month) {
      currentYear++
      setYear(currentYear)
    } else if (month != 12) {
      currentMonth++
      setMonth(currentMonth)
    } else {
      currentYear++
      currentMonth = 1
      setYear(currentYear)
      setMonth(currentMonth)
    }

    if (location.search.includes('editInvoice')) {
      location.search = ''
    }
    return getUrl(currentYear, currentMonth)
  }

  function handleLeftClick() {
    let currentYear = year
    let currentMonth = month

    if (params.year && !params.month) {
      currentYear--
      setYear(currentYear)
    } else if (month <= 12 && month > 1) {
      currentMonth--
      setMonth(currentMonth)
    } else {
      currentYear--
      currentMonth = 12
      setYear(currentYear)
      setMonth(currentMonth)
    }

    if (location.search.includes('editInvoice')) {
      location.search = ''
    }
    return getUrl(currentYear, currentMonth)
  }

  function handleRedirect(handleClick: () => void) {
    if (redirect === 'false' && !canRedirect(location.pathname)) return

    return handleClick()
  }

  function handleYearButtonClick() {
    if (isInvoices) {
      return navigate({ pathname: `/invoices/${year}`, search: location.search })
    } else if (isSummary) {
      return navigate({ pathname: `/invoices/${year}/summary`, search: location.search })
    }
    return navigate({ pathname: `/invoices/${year}`, search: location.search })
  }

  function handleMonthButtonClick() {
    if (isInvoices) {
      return navigate({ pathname: `/invoices/${month}-${year}`, search: location.search })
    } else if (isSummary) {
      return navigate({ pathname: `/invoices/${month}-${year}/summary`, search: location.search })
    }
    return navigate({ pathname: `/invoices/${month}-${year}`, search: location.search })
  }

  const getMonthPath = () => {
    if (isInvoices) {
      return `/invoices/${month}-${year}`
    } else if (isSummary) {
      return `/invoices/${month}-${year}/summary`
    }
    return `/invoices/${month}-${year}`
  }

  const getYearPath = () => {
    if (isInvoices) {
      return `/invoices/${month}-${year}`
    } else if (isSummary) {
      return `/invoices/${month}-${year}/summary`
    }
    return `/invoices/${month}-${year}`
  }

  useEffect(() => {
    if (!location.state) return

    if (location.state.year) {
      setYear(location.state.year)
    }

    if (location.state.month) {
      setMonth(location.state.month)
    }
  }, [])

  useEffect(() => {
    setYearMonth(year, month)
  }, [month, year])

  useEffect(() => {
    if (params.month && params.year) {
      if (parseInt(params.month) !== month || parseInt(params.year) !== year) {
        setMonth(parseInt(params.month))
        setYear(parseInt(params.year))
      }

      if (
        isNaN(parseInt(params.month)) ||
        isNaN(parseInt(params.year)) ||
        parseInt(params.month) > 12 ||
        parseInt(params.month) < 1 ||
        params.year.length !== 4
      ) {
        navigate(redirectUrlInEffect)
      }
    } else if (!params.month && params.year) {
      if (parseInt(params.year) !== year) {
        setYear(parseInt(params.year))
      }

      if (isNaN(parseInt(params.year)) || params.year.length !== 4) {
        navigate(`/invoices/${getActualYear()}`)
      }
    }
    setYearMonth(year, month)
  }, [params.year, params.month])

  return (
    <CenterTop>
      <RedirectButton
        onClick={() => {
          if (redirect === 'false') {
            setPath(handleLeftClick())
            setPopUpClosed(false)
            return
          }
          navigate(handleLeftClick())
        }}
      >
        <ArrowLeft src="/img/arrow-right.svg" aria-label="go back in time" />
      </RedirectButton>
      <StyledDate>
        <MonthButton
          aria-label="select the month view"
          active={!!params.year && !!params.month}
          onClick={() => {
            if (redirect === 'false') {
              setPath(getMonthPath())
              setPopUpClosed(false)
              return
            }
            handleRedirect(handleMonthButtonClick)
          }}
        >
          {_.padStart(month, 2, '0')}
        </MonthButton>
        &nbsp;-&nbsp;
        <YearButton
          aria-label="select the year view"
          active={!!params.year && !params.month}
          onClick={() => {
            if (redirect === 'false') {
              setPath(getYearPath())
              setPopUpClosed(false)
              return
            }
            handleRedirect(handleYearButtonClick)
          }}
        >
          {year}
        </YearButton>
      </StyledDate>
      <RedirectButton
        onClick={() => {
          if (redirect === 'false') {
            setPath(handleRightClick())
            setPopUpClosed(false)
            return
          }
          navigate(handleRightClick())
        }}
      >
        <ArrowRight src="/img/arrow-right.svg" aria-label="go forwards in time" />
      </RedirectButton>
    </CenterTop>
  )
}

const ArrowRight = styled.img`
  position: relative;
  top: 0.15em;
  margin-left: -0.5em;
  height: 24px;
`

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: -0.5em;
  height: 24px;
`

const CenterTop = styled.div`
  font-size: 1.25em;
  line-height: 1.5em;
  font-family: Montserrat;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 375px) {
    font-size: 1.1em;
  }
`

const RedirectButton = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`

const StyledDate = styled.span`
  width: 5em;
  display: flex;
  justify-content: center;
`

interface YearButtonProps {
  active: boolean
}

interface MonthButtonProps {
  active: boolean
}

const YearButton = styled.span<YearButtonProps>`
  cursor: pointer;
  color: ${({ active }) => (active ? '#F29312' : 'white')};
  &:hover {
    color: #f29312;
  }
`

const MonthButton = styled.span<MonthButtonProps>`
  cursor: pointer;
  color: ${({ active }) => (active ? '#F29312' : 'white')};
  &:hover {
    color: #f29312;
  }
`
