import React from 'react'
import styled, { css } from 'styled-components'
import ButtonStyles from '../ButtonStyles'
import { ParamsType } from '../ParamsType'
import { useParams } from 'react-router'
import ActionButtons from '../../screens/Items/ActionButtons'
import useLogin from '../../../hooks/useLogin'
import renderFooterPrice from '../renderFooterPrice'
import Footer from './Footer'

interface Props {
  pendingSectionFooter: boolean
  invoicesSum: number
  handleApprove: any
  approveButtonActive: boolean
  isSticky: boolean
  displayMobile: boolean
  invoicesPaidSum: number
  closedMonth?: boolean
  closeMonthButtonActive?: boolean
  handleExportButtonClick?: (withText: boolean) => JSX.Element | 'Export'
  handleMonthClose?: () => void
  handleMonthReOpen?: () => void
  currencyName?: string
  emptyMonth?: boolean
  totalCost?: boolean
}

export default ({
  pendingSectionFooter,
  invoicesSum,
  handleApprove,
  approveButtonActive,
  isSticky,
  displayMobile,
  invoicesPaidSum,
  closedMonth,
  closeMonthButtonActive,
  handleExportButtonClick,
  handleMonthClose,
  handleMonthReOpen,
  emptyMonth,
  currencyName,
  totalCost
}: Props) => {
  const params = useParams<ParamsType>()

  const { ADMIN, USER } = useLogin()

  const styledButtons = (
    <>
      {!emptyMonth && (
        <>
          <ExportButton active={!!closedMonth && !USER}>
            {handleExportButtonClick && handleExportButtonClick(true)}
          </ExportButton>
          <DropdownContainer>
            <ActionButtons
              handleExportButtonClick={handleExportButtonClick && handleExportButtonClick(false)}
            />
          </DropdownContainer>
        </>
      )}
      {!closedMonth ? (
        <CloseMonthButton
          onClick={closeMonthButtonActive ? handleMonthClose : () => null}
          active={!!closeMonthButtonActive}
        >
          Close month
        </CloseMonthButton>
      ) : (
        <ReOpenButton active={ADMIN} onClick={ADMIN ? handleMonthReOpen : () => null}>
          Re-open
        </ReOpenButton>
      )}
    </>
  )

  function renderPriceSection(priceText: string, price: number, currency?: string) {
    return (
      <Section pendingSectionFooter={pendingSectionFooter}>
        {renderFooterPrice(priceText, price, currency)}
      </Section>
    )
  }

  return (
    <Footer
      displayMobile={displayMobile}
      isSticky={isSticky}
      emptyMonth={emptyMonth}
      pendingSectionFooter={pendingSectionFooter}
    >
      <PriceSection>
        {pendingSectionFooter &&
          !emptyMonth &&
          renderPriceSection(totalCost ? 'Total :' : 'Real :', invoicesSum, currencyName)}
        {!pendingSectionFooter && !emptyMonth && (
          <>
            {renderPriceSection('To be paid :', invoicesSum - invoicesPaidSum, currencyName)}
            {renderPriceSection('Paid :', invoicesPaidSum, currencyName)}
            {renderPriceSection(totalCost ? 'Total :' : 'Real :', invoicesSum, currencyName)}
          </>
        )}
      </PriceSection>
      <ButtonSection>
        {!pendingSectionFooter && params.month && params.year && ADMIN && styledButtons}
        {pendingSectionFooter && ADMIN && !emptyMonth && (
          <ApproveButton
            disabled={!approveButtonActive}
            active={approveButtonActive}
            onClick={handleApprove}
          >
            Approve
          </ApproveButton>
        )}
      </ButtonSection>
    </Footer>
  )
}

export const PriceSection = styled.section`
  display: flex;
  flex-direction: row;
`

export const ButtonSection = styled(PriceSection)`
  @media (max-width: 645px) {
    margin-right: 4.6em;
  }

  @media (max-width: 375px) {
    margin-right: 4.2em;
  }
`

interface SectionProps {
  pendingSectionFooter: boolean
}

const Section = styled.section<SectionProps>`
  display: flex;
  flex-direction: row;
  margin-right: 3em;

  @media (max-width: 1200px) {
    ${({ pendingSectionFooter }) =>
      !pendingSectionFooter &&
      css`
        flex-direction: column;
      `}
  }

  @media (max-width: 960px) {
    margin-right: 1.5em;
  }
`

const StyledButton = styled.button`
  ${ButtonStyles}
  margin-right: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1.2em;
  padding-right: 1.2em;

  @media (max-width: 1200px) {
    margin-right: 1.5em;
  }
`

interface ActiveButtonProps {
  active: boolean
}

const activeButtonStyles = css`
  background-color: #f29312;
  border: 1px solid #f29312;
  cursor: pointer;
  color: white;
  pointer-events: auto;
  opacity: 1;

  &:hover {
    background-color: #bd720c;
    border: 1px solid #bd720c;
  }
`

const disabledButtonStyles = css`
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
`

const ApproveButton = styled(StyledButton)<ActiveButtonProps>`
  margin: 0;
  padding-left: 1.5em;
  padding-right: 1.5em;
  ${disabledButtonStyles}
  ${({ active }) => active && activeButtonStyles}

  @media (max-width: 1200px) {
    margin-right: 0;
  }
`

const DownloadButton = styled(StyledButton)`
  @media (max-width: 960px) {
    display: none;
  }

  &:hover {
    background-color: #414249;
  }
`

const ExportButton = styled(DownloadButton)<ActiveButtonProps>`
  padding-right: 2.4em;
  padding-left: 2.4em;
  ${disabledButtonStyles}
  ${({ active }) => active && activeButtonStyles}
`

const DropdownContainer = styled.div`
  display: none;

  @media (max-width: 960px) {
    display: block;
    margin-right: 1em;
  }
`

interface CloseMonthButtonProps {
  active?: boolean
}

export const CloseMonthButton = styled(StyledButton)<CloseMonthButtonProps>`
  margin-right: 0;

  @media (max-width: 960px) {
    padding-left: 0.4em;
    font-size: 0.8em;
    padding-right: 0.4em;
  }

  @media (max-width: 1200px) {
    margin-right: 0;
  }

  &:hover {
    background-color: #414249;
  }
  ${disabledButtonStyles}
  ${({ active }) => active && activeButtonStyles}
`

export const ReOpenButton = styled(CloseMonthButton)`
  @media (max-width: 960px) {
    padding-left: 1.3em;
    padding-right: 1.3em;
  }
`
