import React from 'react'
import styled from 'styled-components'
import StyledText from './StyledText'

interface Props {
  handleCancel: () => void
}

const Cancel = ({ handleCancel }: Props) => (
  <Container>
    <StyledCancel onClick={handleCancel}>CANCEL</StyledCancel>
  </Container>
)

export default Cancel

const Container = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledCancel = styled.span`
  ${StyledText}
  font-weight: 600;
  cursor: pointer;
`
