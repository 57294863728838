function formatPrice(price: number) {
  let finalPrice: number | string = price
  let rowNumber = price.toString()
  rowNumber = rowNumber.replace(' ', '')
  rowNumber = rowNumber.replace('.', '')
  rowNumber = rowNumber.replace(',', '')

  if (rowNumber.length > 7) {
    let stringPrice = price.toString()
    let first7Chars = stringPrice.substr(0, 7)

    if (first7Chars.includes('.') && !stringPrice.includes('e')) {
      if (first7Chars.includes('.', 6)) {
        finalPrice = Math.round((price + Number.EPSILON) * 10) / 10
      } else {
        finalPrice = Math.round((price + Number.EPSILON) * 100) / 100
      }
      finalPrice = finalPrice.toLocaleString('en')
    } else {
      finalPrice = price / 1000000
      finalPrice = Math.round((finalPrice + Number.EPSILON) * 100) / 100
      finalPrice = finalPrice.toLocaleString('en')
      finalPrice = finalPrice + 'M'
    }
  }
  return finalPrice
}

export default formatPrice
