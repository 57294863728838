import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import dateFormat from 'dateformat'
import useLogin from '../../../hooks/useLogin'
import UploadFilePopUp from './UploadFilePopUp'
import Header from '../../layout/CenterSectionHeader'
import Dropzone from './Dropzone'
import DatePicker from '../../layout/Calendar/DatePicker'
import { SelectInput } from '../../layout/Form/Input'
import { HandleChangeEvent } from '../../layout/Form/Input'
import InputsContainer from './InputsContainer'
import getIdFromText from './getIdFromText'
import {
  addInvoiceMobileData,
  addInvoiceRightSectionData,
  addGroupInvoiceRightSectionData,
  issuerLeftSection,
  addGroupInvoiceMobileData,
  addGroupInvoiceLeftSectionData,
  issuerRightSection,
  FormDataType,
  HorizontalBlockType,
  RadioButtonType,
  TYPES,
} from '../../../constants/formsData'
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'
import users, { UserType } from '../../../queries/users'
import statuses from '../../../queries/statuses'
import categories, { CategoryItem } from '../../../queries/categories'
import currencies from '../../../queries/currencies'
import BigOrangeButton from '../../layout/BigOrangeButton'
import inputValueReduce from '../../layout/inputValueReduce'
import addInvoice from '../../../mutations/addInvoice'
import StyledHeader from './StyledHeader'
import { useNavigate, useLocation } from 'react-router'
import editInvoice from '../../../mutations/editInvoice'
import useQueryParams from '../Items/functions/useQueryParams'
import EditButtons from './EditButtons'
import deleteInvoices from '../../../mutations/deleteInvoices'
import areFormInputsValid from '../../layout/areFormInputsValid'
import invoices, { InvoiceStatusCategoryUserType, InvoiceType } from '../../../queries/invoices'
import setRedirectFalse from '../../layout/setRedirectFalse'
import { Navigate } from 'react-router'
import editInvoiceFiles from '../../../mutations/editInvoiceFiles'
import disabledButtonStyles from '../../layout/disabledButtonStyles'
import isMonthClosed from '../../../queries/isMonthClosed'
import GroupInvoice from '../../layout/GroupInvoice'
import roundPrice from '../../layout/roundPrice'
import {
  FilePreview,
  FileResponse,
  InputValueStateType,
  LocationType,
  UserGroupInvoiceType,
} from '../../../constants/types'
import invoice from '../../../queries/invoice'
import Loader from '../../layout/Loader'
import DeletePopUp from '../../layout/PopUps/DeletePopUp'
import imageCompression from 'browser-image-compression'
import path from 'path-browserify'
import heic2any from 'heic2any'
import { useParams } from 'react-router'
import ConfirmPopUp from '../../layout/PopUps/ConfirmPopUp'
import { useDropzone } from 'react-dropzone'
import { isHeic } from '../../../utils/functions'
import comparePrice from '../../../utils/comparePrice'
import FilesPreview from './FilesPreview/FilesPreview'
import DisplayFilesPreview from './FilesPreview/DisplayFilesPreview'
import rotateInvoice from '../../../mutations/rotateInvoice'
import optionsData from '../../../utils/optionsData'
import handleHeaderClosePath from '../../../utils/handleHeaderClose'
import handleRedirect from '../../layout/handleRedirect'
import getUpdatedSelectOptions from '../../../utils/getUpdatedSelectOptions'
import addFloats from '../../../utils/addFloats'

const _ = require('lodash')

const composableFormats = ['.jpg', '.jpeg', '.png']

const options = {
  maxSizeMB: 1.5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  initialQuality: 0.7,
}

const accept = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
  'image/heic': ['.heic'],
  'image/heif': ['.heif'],
  '.heic': ['.heic'],
  '.heif': ['.heif'],
}

interface Props {
  editingInvoice: boolean
  duplicatingInvoice?: boolean
  editInvoiceFilePath?: string
  editInvoiceFirstOptions: (selectOptions: UserType[]) => void
  defaultStartDate?: Date
  imageChanges?: number
  fileType?: string
  invoiceData?: InvoiceType
  detail?: boolean
  previewVisible: boolean
  setPreviewVisible: React.Dispatch<React.SetStateAction<boolean>>
  previewFiles?: FileResponse[]
  onlyActionButtons?: boolean
  hideRotate?: boolean
  hideDelete?: boolean
}
export default ({
  editingInvoice,
  duplicatingInvoice,
  editInvoiceFirstOptions,
  defaultStartDate,
  editInvoiceFilePath,
  fileType,
  invoiceData,
  detail,
  previewVisible,
  setPreviewVisible,
  previewFiles,
  onlyActionButtons,
  hideRotate,
  hideDelete,
}: Props) => {
  const { USER, ADMIN, user } = useLogin()
  const apolloClient = useApolloClient()
  const navigate = useNavigate()

  if (!user) {
    return <Navigate to="/login" />
  }

  // @ts-ignore
  const invoiceForEdit: InvoiceType = invoiceData

  const [inputValue, setInputValue] = useState<InputValueStateType>({})
  const redirect = useQueryParams().get('redirect')
  const location: LocationType = useLocation() as LocationType
  interface RadioButtonStateType {
    selected: string
  }

  const [radioButtonValue, setRadioButtonValue] = useState<RadioButtonStateType>({
    selected: 'Deductible',
  })
  const [radiobuttonsDisabled, setRadioButtonsDisabled] = useState(false)
  const [dateToSent, setDateToSent] = useState('')
  const [fileToSent, setFileToSent] = useState<File[]>([])
  const [disableInputValue, setDisableInputValue] = useState('')
  const [editFirstFile, setEditFirstFile] = useState(true)
  const [disableRotate, setDisableRotate] = useState(false)
  const [removeIssuerId, setRemoveIssuerId] = useState<number | null>(null)

  const [closedPopUp, setClosedPopUp] = useState(true)
  const [closedDeletePopUp, setClosedDeletePopUp] = useState(true)
  const [closedRemoveIssuerPopUp, setClosedRemoveIssuerPopUp] = useState(true)
  const [closedErrorPopUp, setClosedErrorPopUp] = useState(true)
  const [firstInputValue, setFirstInputValue] = useState<InputValueStateType>({})
  const [buttonInactive, setButtonInactive] = useState(true)
  const [loadingFile, setLoadingFile] = useState(false)
  const [files, setFiles] = useState<FilePreview[]>([])
  const [imageChanged, setImageChanged] = useState(0)
  const [deletedFiles, setDeletedFiles] = useState<FileResponse[]>([])
  const [activePreview, setActivePreview] = useState(previewFiles ? previewFiles[0] : undefined)
  const [selectedUsers, setSelectedUsers] = useState<UserGroupInvoiceType[]>([])
  const [selectedIssuers, setSelectedIssuers] = useState<string[]>([])
  const [isGroupInvoice, setIsGroupInvoice] = useState<boolean>(false)
  const [routePath, setPath] = useState<string>('')

  const [confirmPopUpClosed, setConfirmPopUpClosed] = useState(true)

  const [previousPath, setPreviousPath] = useState<string | null>(
    location.state && location.state.previousPath ? location.state.previousPath.toString() : null
  )

  const getUsers = useQuery(users)
  const getStatuses = useQuery(statuses)
  const getCategories = useQuery(categories)
  const getCurrencies = useQuery(currencies)

  const yearMonth = dateToSent.substring(0, 7).toString()
  const sortBy = 'date'
  const order = 'DESCENDING'
  const filterBy: string[] = []
  const filterValues: string[] = []
  const { id: editInvoiceId } = useParams()

  const isMonthClosedData = useQuery(isMonthClosed, { variables: { yearMonth } })

  const [updatedCategories, setUpdatedCategories] = useState<Array<CategoryItem> | null>(null)

  const [updatedUsers, setUpdatedUsers] = useState<Array<UserType> | null>()

  useEffect(() => {
    if (loading || !getCategories?.data?.categories || updatedCategories) {
      return
    }

    setUpdatedCategories(
      getUpdatedSelectOptions(
        getCategories.data.categories,
        invoiceData,
        'category'
      ) as CategoryItem[]
    )

    setUpdatedUsers(getUpdatedSelectOptions(getUsers.data.users, invoiceData, 'user') as UserType[])
  }, [getCategories?.data, getUsers?.data])

  const loadings =
    getUsers.loading ||
    getStatuses.loading ||
    getCategories.loading ||
    getCurrencies.loading ||
    !updatedCategories ||
    !updatedUsers

  const [changeEditFile] = useMutation(editInvoiceFiles, {
    refetchQueries: [
      { query: invoice, variables: { id: editInvoiceId } },
      {
        query: invoices,
        variables: {
          yearMonth,
          userId: selectedUsers.map((user) => user.id),
          sortBy,
          order,
          filterBy,
          filterValues,
        },
      },
    ],
  })

  const [saveInvoice, { loading }] = useMutation(addInvoice, {
    refetchQueries: [
      {
        query: invoices,
        variables: {
          yearMonth,
          userId: selectedUsers.map((user) => user.id),
          sortBy,
          order,
          filterBy,
          filterValues,
        },
      },
    ],
  })

  const [editActualInvoice, { loading: editInvoiceLoading }] = useMutation(editInvoice, {
    refetchQueries: [
      {
        query: invoices,
        variables: {
          yearMonth,
          userId: selectedUsers.map((user) => user.id),
          sortBy,
          order,
          filterBy,
          filterValues,
        },
      },
      { query: invoice, variables: { id: editInvoiceId } },
    ],
  })

  const [deleteActualInvoice] = useMutation(deleteInvoices, {
    refetchQueries: [
      {
        query: invoices,
        variables: {
          yearMonth,
          userId: selectedUsers.map((user) => user.id),
          sortBy,
          order,
          filterBy,
          filterValues,
        },
      },
    ],
  })

  const [rotateEditInvoice] = useMutation(rotateInvoice, {
    refetchQueries: [
      {
        query: invoice,
        variables: { id: editInvoiceId },
      },
    ],
    onCompleted() {
      setDisableRotate(false)
      setImageChanged((value) => ++value)
    },
  })

  function getSelectOptions(item: FormDataType | null, name?: string) {
    if (!getUsers?.data || !getStatuses?.data || !getCategories?.data || !getCurrencies?.data) {
      return [{ value: '', label: '' }]
    }

    switch (item ? item.name : name) {
      case 'statusSelect':
        return optionsData(getStatuses.data.statuses, 'displayName')

      case 'categorySelect':
        return optionsData(updatedCategories ?? getCategories.data.categories, 'displayName')

      case 'selectText':
        return optionsData(getCurrencies.data.currencies, 'symbol')

      case 'issuerSelect':
        return optionsData(updatedUsers ?? getUsers.data.users, 'displayName')
      default:
        return [{ value: '', label: '' }]
    }
  }

  const handleRotate = () => {
    if (disableRotate) return
    setDisableRotate(true)
    rotateEditInvoice({
      variables: { id: editInvoiceId, degrees: 90, fileOrder: activePreview?.order },
    })
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: (acceptedFiles) => {
      setEditFirstFile(false)
      acceptedFiles.map((acceptedFile) => {
        if (!isHeic(acceptedFile)) {
          setFiles((files) => [
            ...files,
            {
              file: acceptedFile,
              preview: URL.createObjectURL(acceptedFile),
            } as FilePreview,
          ])
        }
      })
      setLoadingFile(false)
    },
  })

  const createDefaultIssuers = () => {
    const defaultIssuers = [
      {
        id: 0,
        issuerId: 0,
        issuerSelect: {
          value: getSelectOptions(null, 'issuerSelect')[0].value,
          isInvalid: false,
        },
        vatState: {
          value: 'Deductible',
          isInvalid: false,
        },
        categorySelect: {
          value: getSelectOptions(null, 'categorySelect')[0].value,
          isInvalid: false,
        },
        price: {
          value: '',
          isInvalid: false,
        },
        vatPrice: {
          value: '',
          isInvalid: false,
        },
        realPrice: {
          value: '',
          isInvalid: false,
        },
      },
      {
        id: 1,
        issuerId: 0,
        issuerSelect: {
          value: getSelectOptions(null, 'issuerSelect')[0].value,
          isInvalid: false,
        },
        vatState: {
          value: 'Deductible',
          isInvalid: false,
        },
        categorySelect: {
          value: getSelectOptions(null, 'categorySelect')[0].value,
          isInvalid: false,
        },
        price: {
          value: '',
          isInvalid: false,
        },
        vatPrice: {
          value: '',
          isInvalid: false,
        },
        realPrice: {
          value: '',
          isInvalid: false,
        },
      },
    ]

    setSelectedUsers(defaultIssuers)
    setSelectedIssuers(defaultIssuers.map((user) => user.issuerSelect.value))
  }

  React.useEffect(() => {
    setSelectedUsers([])
    setFiles([])
    setInputValue({})

    const pathname = location.pathname
    const hasMoreIssuers = !!(invoiceData?.issuers && invoiceData.issuers.length > 1)
    setIsGroupInvoice(pathname === '/addGroupInvoice' || hasMoreIssuers)

    if (pathname === '/addGroupInvoice') {
      createDefaultIssuers()
    }

    if (hasMoreIssuers && getCategories?.data) {
      const issuers: UserGroupInvoiceType[] = invoiceForEdit.issuers.map((issuer, index) => {
        return {
          id: index,
          issuerId: issuer.user.id,
          issuerSelect: {
            value: issuer.user.displayName ?? '',
            isInvalid: false,
          },
          vatState: {
            value: issuer.vatState === 'DEDUCTIBLE_INVOICE' ? 'Deductible' : 'Non-Deductible',
            isInvalid: false,
          },
          categorySelect: {
            value: issuer.category.displayName,
            isInvalid: false,
          },
          price: {
            value: issuer.totalPrice?.toString() ?? '',
            isInvalid: false,
          },
          vatPrice: {
            value: issuer.basePrice?.toString() ?? '',
            isInvalid: false,
          },
          realPrice: {
            value: issuer.realPrice?.toString() ?? '',
            isInvalid: false,
          },
        }
      })

      setSelectedUsers(issuers)
    }
  }, [location.pathname, getCategories?.data, getUsers?.data, invoiceForEdit])

  React.useEffect(() => {
    const price: number = selectedUsers.reduce((acc: number, user: UserGroupInvoiceType) => {
      return addFloats(
        acc,
        parseFloat(user.price.value ? user.price.value.replace(',', '.').replace(/\s/g, '') : '0')
      )
    }, 0)

    const vatPrice: number = selectedUsers.reduce((acc: number, user: UserGroupInvoiceType) => {
      return addFloats(
        acc,
        parseFloat(
          user.vatPrice.value ? user.vatPrice.value.replace(',', '.').replace(/\s/g, '') : '0'
        )
      )
    }, 0)

    let data = {}
    if (price) {
      data = {
        ...data,
        price: {
          value: (price ? price : '0').toString(),
          isInvalid: inputValue.price?.isInvalid ?? false,
        },
      }
    }

    if (vatPrice) {
      data = {
        ...data,
        vatPrice: {
          value: (vatPrice ? vatPrice : '0').toString(),
          isInvalid: inputValue.vatPrice?.isInvalid ?? false,
        },
      }
    }

    setInputValue((inputValue) => ({
      ...inputValue,
      ...data,
    }))

    setSelectedIssuers(selectedUsers.map((user) => user.issuerSelect.value))
  }, [selectedUsers])

  useEffect(() => {
    const selected =
      !invoiceForEdit || invoiceForEdit.vatState === 'DEDUCTIBLE_INVOICE'
        ? 'Deductible'
        : 'Non-Deductible'
    setRadioButtonValue({ selected })
  }, [])

  useEffect(() => {
    if (Object.entries(firstInputValue).length === 0) {
      setFirstInputValue(inputValue)
    }
  }, [inputValue])

  useEffect(() => {
    if (!loadings) {
      const equalValuesEditing =
        JSON.stringify(editInvoiceFirstOptions(updatedUsers ?? getUsers.data.users)) ===
        JSON.stringify(inputValue)
      let equalValues = true

      if (Object.entries(firstInputValue).length !== 0) {
        equalValues = JSON.stringify(firstInputValue) === JSON.stringify(inputValue)
      }

      if (
        ((editingInvoice || detail) && !equalValuesEditing && !redirect) ||
        (!equalValues && !redirect)
      ) {
        setRedirectFalse(navigate, location.search, location.state)
      }
    }
  }, [inputValue])

  useEffect(() => {
    !_.isEmpty(fileToSent) && setRedirectFalse(navigate, location.search, location.state)
  }, [fileToSent])

  let values = inputValueReduce(inputValue)

  useEffect(() => {
    const invalidPriceInput = inputValue && inputValue.price && inputValue.price.isInvalid
    const invalidVatPriceInput =
      (inputValue && inputValue.vatPrice && inputValue.vatPrice.isInvalid) || !values.vatPrice
    const emptyFileToSent = _.isEmpty(fileToSent) && !editInvoiceFilePath

    setButtonInactive(
      isGroupInvoice
        ? !(
            selectedUsers.every((user) => {
              return (
                !user.price.isInvalid &&
                user.price.value &&
                !user.vatPrice.isInvalid &&
                user.vatPrice.value
              )
            }) &&
            !emptyFileToSent &&
            !loading
          )
        : invalidPriceInput ||
            !values.price ||
            invalidVatPriceInput ||
            !areFormInputsValid(addInvoiceRightSectionData, inputValue) ||
            emptyFileToSent ||
            loading
    )
  }, [inputValue, values, fileToSent])

  useEffect(() => {
    setActivePreview(previewFiles?.find((file) => !deletedFiles.find((f) => f.url === file.url)))
  }, [deletedFiles])

  if (loadings) {
    return null
  }

  const realPriceActive =
    radioButtonValue.selected === 'Deductible'
      ? parseFloat(values.vatPrice && values.vatPrice.replace(',', '.').replace(/\s/g, ''))
      : parseFloat(values.price && values.price.replace(',', '.').replace(/\s/g, ''))

  const realPriceGroupActive = selectedUsers.reduce((acc, user) => {
    return (
      acc +
      (user?.realPrice.value
        ? parseFloat(user?.realPrice.value.replace(',', '.').replace(/\s/g, ''))
        : 0)
    )
  }, 0)

  const realPriceActiveToString = roundPrice(
    isGroupInvoice ? realPriceGroupActive : realPriceActive
  ).toString()

  function handleChange(item: FormDataType, event: HandleChangeEvent) {
    const inputData = {
      ...inputValue,
      ...{
        [item.name]: {
          value: event.target.value,
          isInvalid:
            (item.required && event.target.value === '') ||
            (item.regExp && !item.regExp.test(event.target.value)) ||
            comparePrice(item, event, inputValue),
        },
      },
    }
    setInputValue(inputData)
  }

  function handleRadioButtonChange(event: HandleChangeEvent) {
    setRadioButtonValue({
      selected: event.target.value,
    })
  }

  function getFirstOptions() {
    const isInvalid = false

    const statusFirstOption = {
      statusSelect: {
        value: optionsData(getStatuses.data.statuses, 'displayName')[0].value,
        isInvalid,
      },
    }
    const categoryFirstOption = {
      categorySelect: {
        value: isGroupInvoice
          ? 'Group'
          : optionsData(getCategories.data.categories, 'displayName')[0].value,
        isInvalid,
      },
    }
    const issuerFirstOption = {
      issuerSelect: {
        value:
          !USER && !ADMIN
            ? optionsData(getUsers.data.users, 'displayName')[0].value
            : user && user.displayName,
        isInvalid,
      },
    }
    const currencyFirstOption = {
      currencySelect: {
        value: optionsData(getCurrencies.data.currencies, 'symbol')[0].value,
        isInvalid,
      },
    }
    const vatPriceFirstOption = {
      vatPrice: {
        value: '',
        isInvalid,
      },
    }

    return {
      ...statusFirstOption,
      ...categoryFirstOption,
      ...issuerFirstOption,
      ...currencyFirstOption,
      ...vatPriceFirstOption,
    }
  }

  if (Object.entries(inputValue).length === 0) {
    const hasData = duplicatingInvoice || editingInvoice || detail
    setInputValue(
      // @ts-ignore
      hasData && editInvoiceFirstOptions(updatedUsers ?? getUsers.data.users)
        ? editInvoiceFirstOptions(updatedUsers ?? getUsers.data.users)
        : getFirstOptions()
    )
  }

  function getDate(date: Date) {
    const sentDate = dateFormat(date, 'yyyy-mm-dd')
    setDateToSent(sentDate)
  }

  function handleErrorPopUpOpen() {
    if (closedErrorPopUp) {
      setClosedErrorPopUp(false)
    }
  }

  function handleFileError(err: Error) {
    alert('File preparation failed')
    console.log(err)
    setFileToSent([])
  }

  async function getFileToSent(files: File[]) {
    files.forEach(async (file: File) => {
      // @ts-ignore
      const fileExtension = path.extname(file.path).toLowerCase()

      try {
        if (composableFormats.includes(fileExtension)) {
          const compressedFile = await imageCompression(file, options)
          setFileToSent((fileToSent) => [...fileToSent, compressedFile])
        } else if (fileExtension === '.heic' || fileExtension === '.heif') {
          setLoadingFile(true)
          const convertedFile = await heic2any({
            blob: file,
            toType: 'image/jpeg',
          })
          const fileObject = new File([convertedFile as Blob], file.name, { type: 'image/jpeg' })
          const compressedFile = await imageCompression(fileObject, options)
          setLoadingFile(false)
          setFileToSent((fileToSent) => [...fileToSent, compressedFile])
        } else {
          setFileToSent((fileToSent) => [...fileToSent, file])
        }
      } catch (err) {
        return handleFileError(err as Error)
      }
    })
  }

  function handleSubmit(buttonInactive: boolean, testInvoice: boolean) {
    if (buttonInactive) {
      return null
    }

    if (!fileToSent) {
      return closedPopUp && setClosedPopUp(false)
    }

    if (!user?.id) {
      navigate('/login')
    }

    let values = inputValueReduce(inputValue)
    const redirectYearMonth = dateFormat(dateToSent, 'mm-yyyy')

    const data = {
      date: dateToSent,
      categoryId: getIdFromText(updatedCategories, 'displayName', values.categorySelect) ?? 1,
      statusId: getIdFromText(getStatuses.data.statuses, 'displayName', values.statusSelect),
      totalPrice: values.price ? parseFloat(values.price.replace(',', '.').replace(/\s/g, '')) : 0,
      basePrice: values.vatPrice
        ? parseFloat(values.vatPrice.replace(',', '.').replace(/\s/g, ''))
        : 0,
      currencyId: getIdFromText(getCurrencies.data.currencies, 'symbol', values.currencySelect),
      description: values.descriptions || '',
      userId: isGroupInvoice
        ? user?.id
        : getIdFromText(updatedUsers, 'displayName', values.issuerSelect),
      rotate: 0,
      vatState:
        radioButtonValue.selected === 'Deductible'
          ? 'DEDUCTIBLE_INVOICE'
          : 'NON_DEDUCTIBLE_INVOICE',
    }

    let issuers:
      | {
          userId: number
          categoryId: number
          totalPrice: number
          basePrice: number
          vatState: string
        }[]
      | undefined = []

    if (selectedUsers.length > 0) {
      issuers = selectedUsers.map((user: UserGroupInvoiceType) => {
        return {
          userId: getIdFromText(updatedUsers, 'displayName', user.issuerSelect.value),
          categoryId: getIdFromText(updatedCategories, 'displayName', user.categorySelect.value),
          totalPrice:
            parseFloat(
              user.price.value ? user.price.value.replace(',', '.').replace(/\s/g, '') : '0'
            ) ?? 0,
          basePrice:
            parseFloat(
              user.vatPrice.value ? user.vatPrice.value.replace(',', '.').replace(/\s/g, '') : '0'
            ) ?? 0,
          vatState:
            user.vatState.value === 'Deductible' ? 'DEDUCTIBLE_INVOICE' : 'NON_DEDUCTIBLE_INVOICE',
        }
      })
    }

    if (buttonInactive) {
      return handleErrorPopUpOpen()
    }

    const variables: any = { data, issuers, files: fileToSent }

    return saveInvoice({ variables }).then(function () {
      navigate(`/invoices/${redirectYearMonth}`)
    })
  }

  function getActualCategory() {
    return (updatedCategories ?? getCategories.data.categories).filter(
      (item: CategoryItem) => item.displayName === inputValue.categorySelect.value
    )
  }

  function isCategoryTaxable(category: string): boolean {
    return (
      (updatedCategories ?? getCategories.data.categories).filter(
        (item: CategoryItem) => item.displayName === (category.length > 0 ? category : 'General')
      )[0]?.vat === 'TAXABLE_CATEGORY'
    )
  }

  function handleVat() {
    const actualCategory = getActualCategory()

    if (!actualCategory || !actualCategory[0]) {
      return
    }

    if (actualCategory[0].vat === 'NON_TAXABLE_CATEGORY' && !radiobuttonsDisabled) {
      setRadioButtonsDisabled(true)
    }

    if (actualCategory[0].vat !== 'NON_TAXABLE_CATEGORY' && radiobuttonsDisabled) {
      setRadioButtonsDisabled(false)
      setDisableInputValue('')
    }
  }

  function getDisabledInputValue(price: string) {
    const actualCategory = getActualCategory()

    if (!actualCategory) {
      return '0'
    }

    if (price) {
      let vatPrice = parseFloat(price.replace(',', '.').replace(/\s/g, '')) / 0.81
      const rounded = Math.round(vatPrice * 100) / 100

      return disableInputValue !== rounded.toString()
        ? setDisableInputValue(rounded.toString())
        : ''
    }

    if (disableInputValue) {
      return setDisableInputValue('')
    }
  }

  if (inputValue.categorySelect) {
    handleVat()
    getDisabledInputValue(inputValue?.price?.value ?? '0')
  }

  function handlePopUpClose() {
    if (!closedPopUp) {
      setClosedPopUp(true)
    }
  }

  async function handleEdit() {
    let values = inputValueReduce(inputValue)

    if (!user?.id) {
      navigate('/login')
    }

    const data = {
      id: editInvoiceId,
      date: dateToSent,
      categoryId: getIdFromText(updatedCategories, 'displayName', values.categorySelect) ?? 1,
      statusId: getIdFromText(getStatuses.data.statuses, 'displayName', values.statusSelect),
      totalPrice: parseFloat(values.price.replace(',', '.').replace(/\s/g, '')),
      basePrice: parseFloat(values.vatPrice.replace(',', '.').replace(/\s/g, '')),
      currencyId: getIdFromText(getCurrencies.data.currencies, 'symbol', values.currencySelect),
      description: values.descriptions || '',
      userId: isGroupInvoice
        ? user?.id
        : getIdFromText(updatedUsers, 'displayName', values.issuerSelect),
      vatState:
        radioButtonValue.selected === 'Deductible'
          ? 'DEDUCTIBLE_INVOICE'
          : 'NON_DEDUCTIBLE_INVOICE',
    }

    let issuers:
      | {
          userId: number
          categoryId: number
          totalPrice: number
          basePrice: number
          vatState: string
        }[]
      | undefined = []
    if (selectedUsers.length > 0) {
      issuers = selectedUsers.map((user: UserGroupInvoiceType) => {
        return {
          userId: getIdFromText(updatedUsers, 'displayName', user.issuerSelect.value),
          categoryId: getIdFromText(updatedCategories, 'displayName', user.categorySelect.value),
          totalPrice: parseFloat(
            user.price.value ? user.price.value.replace(',', '.').replace(/\s/g, '') : '0'
          ),
          basePrice: parseFloat(
            user.price.value ? user.vatPrice.value.replace(',', '.').replace(/\s/g, '') : '0'
          ),
          vatState:
            user.vatState.value === 'Deductible' ? 'DEDUCTIBLE_INVOICE' : 'NON_DEDUCTIBLE_INVOICE',
        }
      })
    }

    if (
      (!data.totalPrice && data.totalPrice !== 0) ||
      (!data.basePrice && data.basePrice !== 0 && !radiobuttonsDisabled) ||
      !areFormInputsValid(
        isGroupInvoice ? addGroupInvoiceRightSectionData : addInvoiceRightSectionData,
        inputValue
      )
    ) {
      return handleErrorPopUpOpen()
    }

    if (!_.isEmpty(fileToSent) || (deletedFiles && deletedFiles.length > 0)) {
      changeEditFile({
        variables: { id: editInvoiceId, files: fileToSent, deletedFiles },
      }).then()
    }

    try {
      await apolloClient.resetStore()
      await editActualInvoice({ variables: { data, issuers } })
    } catch (err) {
      console.log(err)
      handleErrorPopUpOpen()
    }
    return navigate(previousPath || '/')
  }

  function handleDelete() {
    return deleteActualInvoice({ variables: { ids: [editInvoiceId] } }).then(function () {
      navigate(previousPath || '/')
    })
  }

  function handleErrorPopUpClose() {
    if (!closedErrorPopUp) {
      setClosedErrorPopUp(true)
    }
  }

  function loaderOnUpload() {
    if (loading || editInvoiceLoading) {
      return (
        <LoaderOverlay>
          <Loader />
        </LoaderOverlay>
      )
    }
  }

  function handleAddIssuer() {
    const lastSelectedUserId = selectedUsers[selectedUsers.length - 1]
      ? selectedUsers[selectedUsers.length - 1].id
      : 0
    setSelectedUsers([
      ...selectedUsers,
      {
        id: lastSelectedUserId + 1,
        issuerId: 0,
        issuerSelect: {
          value: '',
          isInvalid: false,
        },
        vatState: {
          value: '',
          isInvalid: false,
        },
        categorySelect: {
          value: '',
          isInvalid: false,
        },
        price: {
          value: '',
          isInvalid: false,
        },
        vatPrice: {
          value: '',
          isInvalid: false,
        },
        totalPrice: {
          value: '',
          isInvalid: false,
        },
        realPrice: {
          value: '',
          isInvalid: false,
        },
      },
    ])
  }

  function handleRemoveIssuer(id: number) {
    const newSelectedUsers = selectedUsers.filter((user) => user.id !== id)
    setSelectedUsers(newSelectedUsers)
    setClosedRemoveIssuerPopUp(true)
  }

  function getDefaultStartDate() {
    if (location.state && location.state.month && location.state.year) {
      if (duplicatingInvoice) {
        return defaultStartDate
      }

      const pathMonth = _.padStart(location.state.month)
      const pathYear = location.state.year

      if (isNaN(pathMonth) || isNaN(pathYear)) return undefined

      const pathDate = new Date(pathYear, pathMonth - 1)
      const date = new Date()

      const currentYearMonthDate = new Date(
        `${date.getFullYear()}-${_.padStart(date.getMonth() + 1, 2, '0')}`
      )

      if (currentYearMonthDate.getMonth() === pathDate.getMonth()) {
        defaultStartDate = date
      } else if (currentYearMonthDate.getTime() < pathDate.getTime()) {
        defaultStartDate = pathDate
      } else if (currentYearMonthDate.getTime() > pathDate.getTime()) {
        defaultStartDate = new Date(pathYear, pathMonth, 0)
      }
    }

    if (invoiceForEdit) defaultStartDate = new Date(invoiceForEdit.date)

    return defaultStartDate
  }

  return (
    <MainContainer>
      {loaderOnUpload()}
      {!closedPopUp && (
        <UploadFilePopUp
          handleClose={handlePopUpClose}
          closed={closedPopUp}
          titleText="Upload file"
        />
      )}
      {!closedDeletePopUp && (
        <DeletePopUp
          closed={closedDeletePopUp}
          handleClosed={() => setClosedDeletePopUp(true)}
          handleDelete={handleDelete}
          deleteItemText={'this'}
          kindOfDelete={'invoice'}
        />
      )}
      {!closedErrorPopUp && (
        <UploadFilePopUp
          handleClose={handleErrorPopUpClose}
          closed={closedErrorPopUp}
          customImageUrl="/img/errorPopUp.svg"
          titleText="Error"
          errorText="An error occurred while saving invoice"
        />
      )}
      <ConfirmPopUp
        closed={confirmPopUpClosed}
        handleCancel={() => setConfirmPopUpClosed(true)}
        handleConfirm={() => {
          location.state && location.state.previousPath
            ? navigate(location.state.previousPath.toString(), {
                state: {
                  month: location.state.month,
                  year: location.state.year,
                },
              })
            : navigate('/')
        }}
      />
      {!closedRemoveIssuerPopUp && (
        <DeletePopUp
          closed={closedRemoveIssuerPopUp}
          handleClosed={() => setClosedRemoveIssuerPopUp(true)}
          handleDelete={() => (removeIssuerId ? handleRemoveIssuer(removeIssuerId) : null)}
          deleteItemText={'this'}
          kindOfDelete={'issuer'}
        />
      )}
      <Items>
        <Header
          Container={StyledHeader}
          handleClose={() =>
            handleRedirect(
              navigate,
              location.pathname,
              handleHeaderClosePath(location),
              redirect === 'false',
              setConfirmPopUpClosed,
              setPath,
              location.state?.month,
              location.state?.year
            )
          }
          text={
            detail
              ? 'Invoice Detail' +
                (invoiceForEdit.documentNumber ? ' - ' + invoiceForEdit.documentNumber : '')
              : editingInvoice
              ? 'Edit Invoice'
              : 'Add Invoice'
          }
        />
        <InputsContainer>
          <TopSection detail={detail}>
            <LeftSection>
              <Dropzone
                getFileToSent={getFileToSent}
                setEditFirstFile={(state) => setEditFirstFile(state)}
                editInvoiceFilePath={editInvoiceFilePath}
                handleEditInvoiceRotate={handleRotate}
                hideRotate={hideRotate}
                hideDelete={hideDelete}
                imageChanged={imageChanged}
                fileType={fileType ? fileType : ''}
                invoice={invoiceForEdit}
                disabled={detail}
                previewVisible={previewVisible}
                setPreviewVisible={setPreviewVisible}
                loadingFile={loadingFile}
                setLoadingFile={setLoadingFile}
                acceptedFiles={acceptedFiles}
                getInputProps={getInputProps}
                getRootProps={getRootProps}
                file={files}
                setFile={setFiles}
                filePreview={activePreview}
                editingInvoice={editingInvoice}
                duplicatingInvoice={duplicatingInvoice}
                setDeletedFiles={setDeletedFiles}
                displayDelete={previewFiles ? previewFiles?.length + files.length > 1 : false}
                onlyActionButtons={onlyActionButtons}
              />
              <PreviewContainer>
                {(!previewFiles || editingInvoice) && !detail && (
                  <Preview {...getRootProps({ className: 'dropzone' })}>
                    <AddIcon src="/img/add-invoice.svg" />
                  </Preview>
                )}
                {previewFiles &&
                  previewFiles
                    .filter((file) => !deletedFiles.find((f) => f.url === file.url))
                    .map((file, i) => (
                      <DisplayFilesPreview
                        key={i}
                        selected={activePreview?.url === file.url}
                        invoice={invoiceData!}
                        file={file}
                        onClick={() => setActivePreview(file)}
                        imageChanged={imageChanged}
                      />
                    ))}
                {files &&
                  files.map((file, i) => (
                    <FilesPreview
                      key={i}
                      selected={(editingInvoice || !!detail) && activePreview?.url === file.preview}
                      file={file}
                      setFiles={setFiles}
                      setFilesToSent={setFileToSent}
                      files={files}
                      editing={!!previewFiles}
                      onClick={() =>
                        (editingInvoice || !!detail) &&
                        setActivePreview({ order: 0, url: file.preview })
                      }
                    />
                  ))}
              </PreviewContainer>
              {!isGroupInvoice && (
                <MobileHidden disabled={detail}>
                  {ADMIN &&
                    issuerLeftSection.map((item: FormDataType) => (
                      <SelectInput
                        key={item.name}
                        item={item}
                        isInvalid={inputValue[item.name] && inputValue[item.name].isInvalid}
                        value={inputValue[item.name] && inputValue[item.name].value}
                        options={getSelectOptions(item)}
                        handleChange={handleChange}
                        mobileSmaller={false}
                        fullWidth={true}
                        disabled={detail}
                        searchable={true}
                      />
                    ))}
                </MobileHidden>
              )}
            </LeftSection>
            <RightSection disabled={detail}>
              <Mobile>
                {(isGroupInvoice ? addGroupInvoiceMobileData : addInvoiceMobileData).map(
                  (item: FormDataType, index) => (
                    <SelectInput
                      key={`${item.name}_${index}_mobile`}
                      item={item}
                      isInvalid={inputValue[item.name] && inputValue[item.name].isInvalid}
                      value={(inputValue[item.name] && inputValue[item.name].value) || ''}
                      inputValue={inputValue}
                      handleChange={handleChange}
                      options={getSelectOptions(item)}
                      handleRadioButtonChange={handleRadioButtonChange}
                      mobileSmaller={false}
                      isRadioButtonDisabled={radiobuttonsDisabled || detail || isGroupInvoice}
                      isInputDisabled={isGroupInvoice}
                      disabledInputValue={
                        radiobuttonsDisabled ? disableInputValue : realPriceActiveToString
                      }
                      radioButtonValue={radioButtonValue.selected}
                      disabled={detail || (isGroupInvoice && item.name === 'categorySelect')}
                    />
                  )
                )}
              </Mobile>
              <DatePicker
                disabled={detail}
                getDate={getDate}
                defaultStartDate={getDefaultStartDate()}
              />
              {(isGroupInvoice ? addGroupInvoiceRightSectionData : addInvoiceRightSectionData).map(
                (item: FormDataType, index) =>
                  (!ADMIN || isGroupInvoice) && item.name === 'issuerSelect' ? null : (
                    <SelectInput
                      key={`${item.name}_${index}_right`}
                      item={item}
                      isInvalid={inputValue[item.name] && inputValue[item.name].isInvalid}
                      value={inputValue[item.name] && inputValue[item.name].value}
                      inputValue={inputValue}
                      handleChange={handleChange}
                      options={getSelectOptions(item)}
                      handleRadioButtonChange={handleRadioButtonChange}
                      mobileSmaller={false}
                      disabledInputValue={
                        radiobuttonsDisabled ? disableInputValue : realPriceActiveToString
                      }
                      isRadioButtonDisabled={radiobuttonsDisabled || detail || isGroupInvoice}
                      isInputDisabled={isGroupInvoice}
                      radioButtonValue={radioButtonValue.selected}
                      disabled={detail || (isGroupInvoice && item.name === 'categorySelect')}
                      searchable={item.name === 'categorySelect' || item.name === 'issuerSelect'}
                    />
                  )
              )}
              {!detail && !isGroupInvoice && (
                <>
                  {editingInvoice && !duplicatingInvoice ? (
                    <EditButtons
                      saveButtonActive={!buttonInactive}
                      handleDelete={() => setClosedDeletePopUp(false)}
                      handleSave={handleEdit}
                    />
                  ) : (
                    <SubmitButton
                      disabled={buttonInactive}
                      active={!buttonInactive}
                      onClick={() => handleSubmit(buttonInactive, false)}
                    >
                      SAVE
                    </SubmitButton>
                  )}
                </>
              )}
              {!editingInvoice &&
                ADMIN &&
                process.env.REACT_APP_ENABLE_TEST_INVOICES === 'true' &&
                !detail && (
                  <SubmitButton
                    active={!buttonInactive}
                    onClick={() => handleSubmit(buttonInactive, true)}
                  >
                    SAVE TEST INVOICES
                  </SubmitButton>
                )}
            </RightSection>
          </TopSection>
          {isGroupInvoice && (
            <>
              {selectedUsers.map((user: UserGroupInvoiceType, index: number) => {
                return (
                  <GroupInvoice
                    user={user}
                    isAdmin={ADMIN}
                    detail={detail}
                    setClosedRemoveIssuerPopUp={setClosedRemoveIssuerPopUp}
                    setRemoveIssuerId={setRemoveIssuerId}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    getSelectOptions={getSelectOptions}
                    disableInputValue={disableInputValue}
                    isCategoryTaxable={isCategoryTaxable}
                    disabled={detail ?? false}
                    index={index}
                    key={index}
                  />
                )
              })}
              {!detail && (
                <ButtonWrapper>
                  <AddIssuerButton onClick={() => handleAddIssuer()}>ADD ISSUER</AddIssuerButton>
                  <HeaderLine isLast={true} src="/img/separator.svg" />
                  <>
                    {editingInvoice && !duplicatingInvoice ? (
                      <EditButtons
                        saveButtonActive={!buttonInactive}
                        handleDelete={() => setClosedDeletePopUp(false)}
                        handleSave={handleEdit}
                      />
                    ) : (
                      <SubmitButton
                        disabled={buttonInactive}
                        active={!buttonInactive}
                        onClick={() => handleSubmit(buttonInactive, false)}
                      >
                        SAVE
                      </SubmitButton>
                    )}
                  </>
                </ButtonWrapper>
              )}
            </>
          )}
        </InputsContainer>
      </Items>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2em 0;

  @media (max-width: 560px) {
    margin: 0;
  }
`

interface TopSectionProps {
  detail: boolean | undefined
}

const TopSection = styled.div<TopSectionProps>`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => props.detail && 'flex-start'};

  @media (max-width: 860px) {
    flex-direction: column;
  }
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: end;

  @media (max-width: 960px) and (min-width: 860px) {
    position: relative;
    right: 1.25em;
  }

  @media (max-width: 860px) {
    align-items: center;
    padding-bottom: 2rem;
    width: 21.5rem;
  }

  @media (max-width: 560px) {
    width: 18.5rem;
  }
`

const AddIssuerButton = styled(BigOrangeButton)`
  & {
    background-color: transparent;
    border: 1px solid #cbcfd3;
    border-radius: 5px;

    &:hover {
      background-color: transparent;
    }
  }

  @media (max-width: 560px) {
    width: 100%;
  }
`

const LeftSection = styled.div`
  margin-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 860px) {
    margin-left: 0.5em;
    margin-bottom: 2em;
  }
`

export const LeftGroupSection = styled(LeftSection)`
  flex: 1;
  justify-content: start;
  gap: 1.7rem;

  @media (max-width: 560px) {
    align-items: center;
  }
`

interface SectionProps {
  disabled?: boolean
  isGroupInvoice?: boolean
}

export const RightSection = styled(LeftSection)<SectionProps>`
  margin-left: 4em;

  @media (max-width: 860px) {
    margin-left: 0.5em;
  }

  @media (max-width: 560px) {
    ${(props) =>
      props.isGroupInvoice &&
      css`
        align-items: center;
        & > div {
          justify-content: space-between;
          & > div {
            flex: 0;
          }
          & > div.radio-container {
            flex: 1;
          }
        }
      `}
  }

  ${(props) =>
    props.isGroupInvoice &&
    css`
      @media (max-width: 960px) and (min-width: 860px) {
        position: relative;
        right: 1.25em;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`

const MobileHidden = styled.div<SectionProps>`
  @media (max-width: 860px) {
    display: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`

const Mobile = styled.div`
  display: none;

  @media (max-width: 560px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

export const LoaderOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.31);
  display: flex;
  justify-content: center;
  align-items: center;
`

interface SubmitButtonProps {
  active: boolean
}

const SubmitButton = styled(BigOrangeButton)<SubmitButtonProps>`
  @media (max-width: 560px) {
    width: 21em;
  }

  ${({ active }) => !active && disabledButtonStyles}
`

const PreviewContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
  min-height: 5rem;
  margin-bottom: auto;

  @media (min-width: 560px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const AddIcon = styled.img`
  width: 2.125rem;
  aspect-ratio: 1/1;
`

const Preview = styled.div`
  height: 5rem;
  aspect-ratio: 1/1;
  background: #4a4c57;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    > div:nth-child(2) {
      display: flex;
    }
  }
`

interface HeaderLineInterface {
  isLast?: boolean
}

export const HeaderLine = styled.img<HeaderLineInterface>`
  width: 95%;
  margin-top: 2em;
  ${({ isLast }) => !isLast && 'margin-bottom: 2em;'};

  @media (min-width: 860px) {
    width: 100%;
  }
`
