import styled, { css } from 'styled-components'

export const style = css`
  width: 6px;
  height: 6px;
  background: #f29312;
  border-radius: 50%;
  margin-left: 0.5em;
`

export default styled.span`
  ${style}
`
