import getSortBy from './getSortBy'

function getImageForHeaderItem(text: string, sortBy: string, orderBy: string) {
  sortBy = getSortBy(sortBy)

  if (text === 'VAT') text = 'vatPrice'
  if (text === 'PRICE') text = 'realPrice'

  if (text.toLowerCase() === sortBy.toLowerCase()) return orderBy.toLowerCase()

  return 'default'
}
export default getImageForHeaderItem
