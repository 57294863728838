import React, { useEffect } from 'react'
import styled from 'styled-components'
import FullViewportOverlay from '../../FullViewportOverlay'
import TopSectionPopUp from '../TopSectionPopUp'
import { Text } from '../../Styled/Text'
import PopUpButtons from '../PopUpButtons'
import StyledContainer from '../StyledContainer'
import BottomSection from '../BottomSection'
import Center from '../Center'
import useOutsideClick from '../../../../hooks/useOutsideClick'

interface Props {
  errorText?: string
  closed: boolean
  handleClosed: () => void
}

export default ({ errorText, closed, handleClosed }: Props) => {
  const ref = useOutsideClick(handleClosed)

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      event.key === 'Escape' && handleClosed()
    }

    window.addEventListener('keydown', onKeyDown)

    return () => window.removeEventListener('keydown', onKeyDown)
  }, [])

  if (closed) {
    return null
  }

  return (
    <>
      <FullViewportOverlay />
      <Center>
        <StyledContainer ref={ref}>
          <TopSectionPopUp
            text={`Error`}
            closeButton={true}
            icon={true}
            handleClose={handleClosed}
          />
          <MainSection>
            <CenterSection>
              <PopUpText>{errorText}</PopUpText>
            </CenterSection>
          </MainSection>
        </StyledContainer>
      </Center>
    </>
  )
}

const CenterSection = styled.section`
  margin-top: 2em;
  padding-left: 1em;
  padding-right: 1em;
`

const MainSection = styled(BottomSection)`
  height: 5em;
`
const PopUpText = styled(Text)`
  font-weight: 500;

  @media (max-width: 375px) {
    font-size: 0.81em;
  }
`
