import { gql } from '@apollo/client'

interface CategoryType {
  id: number
  displayName: string
  keyName: string
  vat: number
  createdAt: Date
  updatedAt: Date
}

export interface CategorySectionType {
  vatPrice: number
  realPrice: number
  totalPrice: number
  category: CategoryType
  map: (
    func: (summary: CategorySectionType, summaryIndex: number) => JSX.Element | null
  ) => JSX.Element
}

interface TotalSectionType {
  totalPrice: number
  totalVat: number
  totalRealPrice: number
}

interface CurrencyItem {
  symbol: string
}

export interface SummaryItem {
  categorySection: CategorySectionType[]
  totalSection: TotalSectionType
  currency: CurrencyItem
}

export default gql`
  query summary($yearMonth: String!, $sortBy: String!, $order: OrderSummary!) {
    summary(yearMonth: $yearMonth, sortBy: $sortBy, order: $order) {
      categorySection {
        vatPrice
        realPrice
        totalPrice
        category {
          id
          displayName
          keyName
          vat
          createdAt
          updatedAt
        }
      }

      totalSection {
        totalPrice
        totalVat
        totalRealPrice
      }
      currency {
        symbol
      }
    }
  }
`
