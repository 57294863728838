import React from 'react'
import styled, { StyledComponent } from 'styled-components'
import Title from './PopUps/StyledPopUpTitle'

interface Props {
  Container: StyledComponent<'header', {}, {}, never>
  handleClose: () => void
  text: string
  imageUrl?: string
}

export default ({ Container, handleClose, text, imageUrl }: Props) => {
  return (
    <Container>
      <CenterSection>
        <Title>
          {imageUrl && <CustomImage src={imageUrl} />}
          {text}
        </Title>
      </CenterSection>
      <ButtonClose aria-label="close popup" onClick={handleClose}>
        <Close src="/img/add-invoice-close.svg" />
      </ButtonClose>
    </Container>
  )
}

const ButtonClose = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 0 20px 0 0;
`

const CenterSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 1.3125em;
`

const Close = styled.img`
  margin-right: 2em;
  width: 1.25em;

  @media (max-width: 860px) {
    margin-right: 3em;
  }
`

const CustomImage = styled.img`
  position: relative;
  top: 0.2em;
  right: 0.5em;
`
