import React from 'react'
import { Routes, Navigate, Route } from 'react-router-dom'

import Login from './components/screens/Login/Main'
import LoginRoute from './Routes/LoginRoute'
import AuthorizedRoute from './Routes/AuthorizedRoute'
import YearOverview from './components/screens/Items'
import Summary from './components/screens/Summary/index'
import AddInvoice from './components/screens/Invoice/AddInvoice'
import EditInvoice from './components/screens/Invoice/EditInvoice'
import DetailInvoice from './components/screens/Invoice/DetailInvoice'
import Settings from './components/screens/Settings'
import UserSettings from './components/screens/UserSettings'
import Users from './components/screens/Users/index'
import AdminRoute from './Routes/AdminRoute'
import Items from './components/screens/Items'
import importSalaries from './components/screens/import/Salaries'
import DuplicateInvoice from './components/screens/Invoice/DuplicateInvoice'

export const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginRoute Component={Login} />} />
      <Route path="/invoices/:month-:year" element={<AuthorizedRoute Component={Items} />} />
      <Route path="/invoices/:year" element={<AuthorizedRoute Component={YearOverview} />} />
      <Route
        path="/invoices/:month-:year/summary"
        element={<AuthorizedRoute Component={Summary} />}
      />
      <Route path="/invoices/:year/summary" element={<AuthorizedRoute Component={Summary} />} />
      <Route path="/addInvoice" element={<AuthorizedRoute Component={AddInvoice} />} />
      <Route path="/addGroupInvoice" element={<AuthorizedRoute Component={AddInvoice} />} />
      <Route path="/invoices/:id/edit" element={<AuthorizedRoute Component={EditInvoice} />} />
      <Route
        path="/invoices/:id/duplicate"
        element={<AuthorizedRoute Component={DuplicateInvoice} />}
      />
      <Route path="/invoices/:id/detail" element={<AuthorizedRoute Component={DetailInvoice} />} />
      <Route path="/settings" element={<AdminRoute Component={Settings} />} />
      <Route path="/import/salaries" element={<AuthorizedRoute Component={importSalaries} />} />
      <Route path="/userSettings" element={<AuthorizedRoute Component={UserSettings} />} />
      <Route path="/users" element={<AuthorizedRoute Component={Users} />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  )
}
