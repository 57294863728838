import React from 'react'
import styled from 'styled-components'
import ButtonStyles from '../ButtonStyles'
import disabledButtonStyles from '../disabledButtonStyles'

interface Props {
  tranparentButtonText: string
  transparentButtonFunction: () => void
  orangeButtonText: string
  orangeButtonFunction: () => void
  orangeButtonActive: boolean
  closeButton?: boolean
  transparentButtonActive?: boolean
}

export default (props: Props) => (
  <>
    <Container>
      <TransparentButton
        border={props.closeButton}
        active={props.transparentButtonActive}
        onClick={props.transparentButtonFunction}
      >
        {props.tranparentButtonText}
      </TransparentButton>
      <OrangeButton
        aria-label="Continue in action"
        active={props.orangeButtonActive}
        onClick={props.orangeButtonFunction}
      >
        {props.orangeButtonText}
      </OrangeButton>
    </Container>
  </>
)

const Container = styled.div`
  width: 100%;
  position: relative;
  bottom: 0;
`

interface TransparentButtonProps {
  border?: boolean
  active?: boolean
}

const TransparentButton = styled.button<TransparentButtonProps>`
  ${ButtonStyles}
  padding-left: 2.2em;
  padding-right: 2.2em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  cursor: pointer;

  &:hover {
    background-color: #414249;
  }

  @media (max-width: 390px) {
    font-size: 0.688em;
  }

  ${({ border }) =>
    border &&
    `
    border: 1px solid #F45151;
  `}
`

const OrangeButton = styled(TransparentButton)`
  background-color: #f29312;
  border: 1px solid #f29312;
  color: white;
  margin-left: 1.5em;
  cursor: pointer;

  &:hover {
    background-color: #bd720c;
    border: 1px solid #bd720c;
  }

  ${({ active }) => !active && disabledButtonStyles}
`
