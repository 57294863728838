import React, { useState } from 'react'
import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import Header from './StyledHeader'
import YearMonthSwitch from './YearMonthSwitch'
import useQueryParams from '../../screens/Items/functions/useQueryParams'
import { useNavigate, useLocation } from 'react-router'
import OptionDropdown from '../OptionDropdown'
import MenuDropdown from '../HeaderDropdown/index'
import useLogin from '../../../hooks/useLogin'
import ConfirmPopUp from '../PopUps/ConfirmPopUp'
import handleRedirect from '../handleRedirect'

interface Props {
  invoiceDate: any
}

export default (props: Props) => {
  const location = useLocation()
  const redirect = useQueryParams().get('redirect') || true
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { ACCOUNTANT, USER } = useLogin()

  const [month, setMonth] = useState<number>()
  const [year, setYear] = useState<number>()
  const [popUpClosed, setPopUpClosed] = useState(true)
  const [path, setPath] = useState<string>('')

  function setYearMonth(year: number, month: number) {
    setYear(year)
    setMonth(month)
  }

  return (
    <>
      <Header displayMobile={true}>
        <MenuDropdown
          invoiceDate={props.invoiceDate}
          setPopUpClosed={setPopUpClosed}
          setPath={setPath}
        >
          <HamburgerMenu src="/img/hamburger-menu.svg" />
        </MenuDropdown>

        <Center>
          <Logo src="/img/logo-header.svg" />
          <HeaderLine src="/img/header-line.svg" />
          <YearMonthSwitch
            setYearMonth={setYearMonth}
            setPopUpClosed={setPopUpClosed}
            setPath={setPath}
          />
        </Center>
        <ButtonContainer>
          <AddInvoiceImg
            src="/img/add-invoice.svg"
            onClick={() => {
              if (ACCOUNTANT) {
                return
              }
              setPath(`/addInvoice`)
              redirect === 'false'
                ? setPopUpClosed(false)
                : handleRedirect(
                    navigate,
                    location.pathname,
                    '/addInvoice',
                    false,
                    setPopUpClosed,
                    setPath,
                    month,
                    year
                  )
            }}
          />
          {!USER && (
            <OptionDropdown
              setPopUpClosed={setPopUpClosed}
              setPath={setPath}
              month={month}
              year={year}
            >
              <AddInvoiceImg src="/img/option-button.svg" />
            </OptionDropdown>
          )}
        </ButtonContainer>
      </Header>
      <ConfirmPopUp
        closed={popUpClosed}
        handleCancel={() => setPopUpClosed(true)}
        handleConfirm={() => {
          setPopUpClosed(true)
          location.search = ''
          navigate(path.replace('?redirect=false', ''), {
            state: { previousPath: location.pathname, month, year }
          })
        }}
      />
    </>
  )
}

const AddInvoiceImg = styled.img`
  margin-right: 2em;
  width: 44px;
  cursor: pointer;

  @media (max-width: 375px) {
    width: 35px;
    margin-top: 0.7em;
  }
`

const HamburgerMenu = styled.img`
  width: 45px;
  margin-left: 2em;
  cursor: pointer;

  @media (max-width: 375px) {
    width: 28px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: end;
  row-gap: 0.5rem;

  & > img {
    margin-right: 0.5rem;
  }

  & > div > img {
    margin-right: 0.5rem;
  }
`

const Center = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.img`
  width: 142px;

  @media (max-width: 375px) {
    width: 92px;
  }
`

const HeaderLine = styled.img`
  width: 232px;
  margin-bottom: 0.5em;
  margin-top: 1em;

  @media (max-width: 375px) {
    width: 170px;
  }
`
