import React from 'react'
import styled from 'styled-components'

export default () => (
  <StyledHeaderArrow xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path fill="#FFF" fillRule="nonzero" d="M4.5 9l7.5 7.5L19.5 9z" />
  </StyledHeaderArrow>
)

const StyledHeaderArrow = styled.svg`
  margin-left: 0.2em;
  position: relative;
  bottom: 0.1em;
`
