import React from 'react'
import styled, { css } from 'styled-components'
import ButtonStyles from '../../ButtonStyles'
import DownloadExportDropdown from '../../FooterDropdown/MobileDropdown'
import { Text, TextBold } from '../../Styled/Text'
import NumberFormat from 'react-number-format'
import roundPrice from '../../roundPrice'
import ActionButtons from '../../../screens/Items/ActionButtons'
import useLogin from '../../../../hooks/useLogin'

interface Props {
  handleOpen: () => void
  handleClose: () => void
  invoicesSum: number
  handleMonthClose: () => void
  handleMonthReOpen: () => void
  handleExportButtonClick: () => void
  closeMonthButtonActive: boolean
  closedMonth: boolean
  exportFilePath: string
  totalCost?: boolean
}

export default (props: Props) => {
  const { ADMIN, USER } = useLogin()

  return (
    <MobileFooter>
      <TextSection onClick={props.handleOpen}>
        <Clickable>
          <WithUnderlineText>{props.totalCost ? 'Total :' : 'Real :'}</WithUnderlineText>
          <WithImageSection>
            <WithUnderlineTextBold>
              <NumberFormat
                value={roundPrice(props.invoicesSum)}
                displayType="text"
                thousandSeparator={true}
              />
              CZK
            </WithUnderlineTextBold>
          </WithImageSection>
        </Clickable>
      </TextSection>
      {ADMIN && (
        <ButtonSection>
          <Hidden>
            <ActionButtons handleExportButtonClick={props.handleExportButtonClick} />
          </Hidden>
          <Mobile>
            <DownloadExportDropdown
              exportButtonActive={!USER && props.closedMonth}
              exportFilePath={props.exportFilePath}
            />
          </Mobile>
          {!props.closedMonth ? (
            <CloseMonthButton
              onClick={props.closeMonthButtonActive ? props.handleMonthClose : () => null}
              active={props.closeMonthButtonActive}
            >
              Close Month
            </CloseMonthButton>
          ) : (
            <ReOpenButton
              active={props.closedMonth && ADMIN}
              onClick={props.closedMonth && ADMIN ? props.handleMonthReOpen : () => null}
            >
              Re-open
            </ReOpenButton>
          )}
        </ButtonSection>
      )}
    </MobileFooter>
  )
}

const MobileFooter = styled.footer`
  display: none;

  @media (max-width: 645px) {
    display: flex;
    z-index: 999;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    background-color: #3a3c45;
    height: 4em;
    position: sticky;
    bottom: 0;
  }
`

const ButtonSection = styled.section`
  display: flex;
  flex-direction: row;
`

interface MonthButtonProps {
  active?: boolean
}

const CloseMonthButton = styled.button<MonthButtonProps>`
  ${ButtonStyles}
  margin-left: 1.5em;
  margin-right: 1.8em;

  @media (max-width: 375px) {
    font-size: 0.7em;
  }

  &:hover {
    background-color: #414249;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #f29312;
      border: 1px solid #f29312;
      color: white;

      &:hover {
        background-color: #bd720c;
        border: 1px solid #bd720c;
      }
    `}
`

const ReOpenButton = styled(CloseMonthButton)<MonthButtonProps>`
  padding-left: 1.8em;
  padding-right: 1.8em;

  &:hover {
    background-color: #414249;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #f29312;
      border: 1px solid #f29312;
      color: white;

      &:hover {
        background-color: #bd720c;
        border: 1px solid #bd720c;
      }
    `}
`

const TextSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-left: 1.6em;

  @media (max-width: 375px) {
    font-size: 0.7em;
    margin-left: 14px;
  }
`

const WithImageSection = styled.section`
  display: flex;
  flex-direction: row;
`

const Hidden = styled.div`
  @media (max-width: 375px) {
    display: none;
  }
`

const Mobile = styled.div`
  display: none;

  @media (max-width: 375px) {
    display: block;
  }
`

const WithUnderlineText = styled(Text)`
  text-decoration: underline;
`

const WithUnderlineTextBold = styled(TextBold)`
  text-decoration: underline;
`

const Clickable = styled.div`
  cursor: pointer;
`
