import React from 'react'
import styled from 'styled-components'
import FullViewportOverlay from '../../layout/FullViewportOverlay'
import Header from '../../layout/CenterSectionHeader'
import StyledHeader from './StyledHeader'
import { Text } from '../../layout/Styled/Text'

interface Props {
  closed: boolean
  handleClose: () => void
  customImageUrl?: string
  errorText?: string
  titleText: string
}

export default ({ closed, handleClose, errorText, customImageUrl, titleText }: Props) => {
  return (
    <>
      <FullViewportOverlay />
      <Container>
        <Header
          imageUrl={customImageUrl}
          Container={StyledHeaderPopUp}
          handleClose={handleClose}
          text={titleText}
        />
        <ContainerBody>
          <PopUpText>
            {errorText ? (
              errorText
            ) : (
              <>
                You have to upload <strong>JPG</strong>, <strong>PNG</strong>, <strong>HEIC</strong>{' '}
                or
                <strong>PDF</strong>
                file
              </>
            )}
          </PopUpText>
        </ContainerBody>
      </Container>
    </>
  )
}

const Container = styled.div`
  position: fixed;
  z-index: 250;
  padding-left: 0.5em;
  padding-right: 0.5em;
`

const ContainerBody = styled.div`
  border-radius: 0 0 7px 7px;
  background-color: #3a3c45;
  width: 38em;
  height: 6em;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    width: 70vw;
  }
`

const StyledHeaderPopUp = styled(StyledHeader)`
  width: 38em;
  height: 4em;
  border-radius: 7px 7px 0 0;

  @media (max-width: 960px) {
    width: 70vw;
  }

  @media (max-width: 860px) {
    width: 70vw;
  }

  @media (max-width: 560px) {
    display: flex;
  }
`

const PopUpText = styled(Text)`
  font-weight: 500;
`
