import React, { useState } from 'react'
import MobileFooter from './MobileFooter'
import ExtendedSection from './ExtendedSection'

interface Props {
  invoicesSum: number
  paidInvoicesSum: number
  handleMonthClose: () => void
  handleMonthReOpen: () => void
  handleExportButtonClick: any
  closeMonthButtonActive: boolean
  closedMonth: boolean
  exportFilePath: string
  totalCost?: boolean
}

export default (props: Props) => {
  const [visible, setVisible] = useState(false)

  function handleOpen() {
    setVisible(true)
  }

  function handleClose() {
    if (visible) {
      setVisible(false)
    }
  }

  return (
    <>
      <MobileFooter
        invoicesSum={props.invoicesSum}
        handleOpen={handleOpen}
        handleClose={handleClose}
        closeMonthButtonActive={props.closeMonthButtonActive}
        handleMonthClose={props.handleMonthClose}
        handleMonthReOpen={props.handleMonthReOpen}
        handleExportButtonClick={props.handleExportButtonClick}
        closedMonth={props.closedMonth}
        exportFilePath={props.exportFilePath}
        totalCost={props.totalCost}
      />
      {visible && (
        <ExtendedSection
          invoicesSum={props.invoicesSum}
          paidInvoicesSum={props.paidInvoicesSum}
          handleClose={handleClose}
          totalCost={props.totalCost}
        />
      )}
    </>
  )
}
