import React from 'react'
import styled from 'styled-components'
import Line from '../../layout/Line'
import { Text } from '../../layout/Styled/Text'
import WithUnderline from '../../layout/WithUnderline'
import { useNavigate } from 'react-router'
import getActualMonth from '../Items/functions/getActualMonth'
import getActualYear from '../../layout/getActualYear'
import { CurrencyItem } from '../../../queries/currencies'
import { CategoryItem } from '../../../queries/categories'
import { StatusItem } from '../../../queries/statuses'

interface Props {
  nameProperty: string
  item: CurrencyItem | CategoryItem | StatusItem
  forCategory?: boolean
  forStatus?: boolean
  forCurrency?: boolean
  handleDelete: () => void
  handleEdit: () => void
  getId: (item: CurrencyItem | CategoryItem | StatusItem) => void
}

export default ({
  nameProperty,
  item,
  forCategory,
  forCurrency,
  forStatus,
  handleDelete,
  handleEdit,
  getId
}: Props) => {
  const Container = forCategory ? WithUnderline : ItemDiv
  const navigate = useNavigate()

  function categoryItemSwitch() {
    if ('vat' in item) {
      switch (item.vat) {
        case 'NON_TAXABLE_CATEGORY':
          return 'Non-Taxable'

        case 'TAXABLE_CATEGORY':
          return 'Taxable'
      }
    }
  }

  function statusItemSwitch() {
    if ('paid' in item) {
      switch (item.paid) {
        case false:
          return 'Non-Paid'

        case true:
          return 'Paid'
      }
    }
  }

  function getMiddleItemValue() {
    if (forCategory) {
      return categoryItemSwitch()
    }

    if (forStatus) {
      return statusItemSwitch()
    }
  }

  function handleFilter() {
    navigate(`/invoices/${getActualMonth() + 1}-${getActualYear()}?categoryId=${item.id}`)
  }

  return (
    <ItemContainer onMouseOver={() => getId(item)}>
      <td>
        <StyledText onClick={forCategory ? handleFilter : () => null}>
          <Container>{item[nameProperty]}</Container>
        </StyledText>
      </td>
      {!forCurrency && (
        <td>
          <StyledMiddleItem>{getMiddleItemValue()}</StyledMiddleItem>
        </td>
      )}
      <td>
        <Actions>
          <EditImage
            src="/img/edit-icon.svg"
            onClick={handleEdit}
            data-testid={`edit-${item[nameProperty]
              .toString()
              .split(' ')
              .join('-')}`}
          />
          <DeleteImage
            src="/img/delete-icon.svg"
            onClick={handleDelete}
            data-testid={`delete-${item[nameProperty]
              .toString()
              .split(' ')
              .join('-')}`}
          />
        </Actions>
      </td>
    </ItemContainer>
  )
}

const ItemContainer = styled.tr`
  background-color: #3a3c45;
  height: 3rem;
`

const StyledText = styled(Text)`
  color: #cbcfd3;
  font-size: 0.82em;
  display: flex;
  width: 10em;
  justify-content: flex-start;

  @media (max-width: 460px) {
    width: 9em;
  }

  @media (max-width: 375px) {
    font-size: 0.688em;
    width: 8em;
  }

  @media (max-width: 325px) {
    font-size: 0.65em;
    width: 7em;
  }
`

const StyledMiddleItem = styled(StyledText)`
  width: 12em;

  @media (max-width: 375px) {
    width: 10em;
  }
`

const Actions = styled.span`
  display: flex;
  flex-direction: row;

  @media (max-width: 500px) {
    white-space: nowrap;
  }
`

const DeleteImage = styled.img`
  cursor: pointer;
`
const EditImage = styled(DeleteImage)`
  margin-right: 0.8em;
`

const ItemDiv = styled.div``
