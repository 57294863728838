import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import DeletePopUp from '../../../layout/PopUps/DeletePopUp'
import { FilePreview } from '../../../../constants/types'
import { isPdf } from '../../../../utils/functions'
import { PreviewImage, PreviewPdf, Preview } from './FilesPreviewComponents'

interface Props {
  setFiles: React.Dispatch<React.SetStateAction<FilePreview[]>>
  file: FilePreview
  files: FilePreview[]
  editing?: boolean
  onClick?: () => void
  selected: boolean
  setFilesToSent: React.Dispatch<React.SetStateAction<File[]>>
}

const FilesPreview = ({ file, setFiles, editing, onClick, selected, setFilesToSent }: Props) => {
  const [displayDelete, setDisplayDelete] = useState(false)
  const [displayPopUp, setDisplayPopUp] = useState(false)
  const nodeRef = useRef(null)

  const handleDelete = (file: FilePreview) => {
    setFiles(files => files.filter(f => f.preview !== file.preview))
    setFilesToSent(files => files.filter(f => f.name !== file.file.name))
    setDisplayPopUp(false)
  }

  return (
    <>
      <Preview
        onMouseEnter={() => setDisplayDelete(true)}
        onMouseLeave={() => setDisplayDelete(false)}
        onClick={onClick}
      >
        {isPdf(file.file.name) ? (
          <PreviewPdf src={file.preview} selected={selected} />
        ) : (
          <PreviewImage src={file.preview} selected={selected} />
        )}
        {!editing && (
          <CSSTransition
            nodeRef={nodeRef}
            in={displayDelete}
            timeout={300}
            classNames="delete-transition"
            unmountOnExit
          >
            <DeleteOverlay ref={nodeRef} onClick={() => setDisplayPopUp(true)}>
              <AddIcon src="/img/delete-icon.svg" />
            </DeleteOverlay>
          </CSSTransition>
        )}
      </Preview>
      <DeletePopUp
        closed={!displayPopUp}
        handleDelete={() => handleDelete(file)}
        kindOfDelete="Image"
        deleteItemText="this"
        handleClosed={() => setDisplayPopUp(false)}
      />
    </>
  )
}

export default FilesPreview

const AddIcon = styled.img`
  width: 2.125rem;
  aspect-ratio: 1/1;
`

const DeleteOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.1875rem;
  box-sizing: border-box;
`
