import React, { useState, useEffect, useCallback } from 'react'
import styled, { keyframes } from 'styled-components'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './InvoiceDetail.css'
import { InvoiceFilesType, InvoiceType } from '../../../queries/invoices'
import Loader from '../../layout/Loader'
import useLogin from '../../../hooks/useLogin'
import InvoiceDetailPreview from './InvoiceDetailPreview'
import { getFileExtension, getFilePath } from '../../../utils/functions'

interface Props {
  handleClose: () => void
  fileUrl?: string
  invoicesForDetail: InvoiceType[]
  invoiceId: string
  disableDescription?: boolean
  imageChanged?: number
}

const responsive = {
  default: {
    breakpoint: { max: 9999, min: 0 },
    items: 1
  }
}
const preloadCount = 5

export default ({
  handleClose,
  invoicesForDetail,
  invoiceId,
  disableDescription,
  imageChanged
}: Props) => {
  const [detailSlideshowStartState, setDetailSlideshowStartState] = useState(true)
  const [carouselReference, setCarouselReference] = useState<any>()
  const [visibleCarousel, setVisibleCarousel] = useState(false)
  const [previewLoading, setPreviewLoading] = useState(true)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [loadedInvoices, setLoadedInvoices] = useState<number[]>([])
  const [filePreview, setFilePreview] = useState<InvoiceFilesType | undefined>()

  const { token } = useLogin()

  useEffect(() => {
    setFilePreview(undefined)
  }, [activeIndex])

  const numberOfPreviewItems = 4

  function handleView(invoice: InvoiceType, index: number, fileForPreview: InvoiceFilesType) {
    const filePath = getFilePath(invoice, fileForPreview, token!)
    const fileExtension = getFileExtension(fileForPreview)
    const rows = Math.round(invoice.files.length / numberOfPreviewItems)

    if (fileExtension === '.pdf') {
      return (
        <PdfContainer visible={loadedInvoices.includes(index)} rows={rows}>
          {loadedInvoices.includes(index) && renderControls(filePath, index)}
          <PdfView
            src={`${filePath}#zoom=FitH`}
            rows={rows}
            onLoad={() => {
              setPreviewLoading(false)
              setLoadedInvoices(loadedInvoices => [...loadedInvoices, index])
            }}
          />
        </PdfContainer>
      )
    }
    return (
      <ImageContainer visible={loadedInvoices.includes(index)} rows={rows}>
        {loadedInvoices.includes(index) && renderControls(filePath, index)}
        <StyledImage
          rows={rows}
          src={`${filePath}&imageChanged=${imageChanged}`}
          onLoad={event => {
            imageOnLoad(event)
            setPreviewLoading(false)
            setLoadedInvoices(loadedInvoices => [...loadedInvoices, index])
          }}
        />
      </ImageContainer>
    )
  }

  function renderControls(filePath: string, index: number) {
    return (
      <>
        {CustomRightArrow(index)}
        {CustomLeftArrow(index)}
        <CloseImage onClick={handleClose} />
        <OpenImage onClick={() => window.open(filePath, '_blank')} />
      </>
    )
  }

  function handleDescription(invoice: InvoiceType, index: number) {
    if (
      invoice.description &&
      invoice.description !== '' &&
      !disableDescription &&
      loadedInvoices.includes(index)
    ) {
      return (
        <DescriptionContainer className={'detailDescription'}>
          <TextContainer>
            <DescriptionText>{invoice.description}</DescriptionText>
          </TextContainer>
        </DescriptionContainer>
      )
    }
  }

  const CustomRightArrow = (index: number) => {
    if (index !== invoicesForDetail.length - 1) {
      return (
        <RightArrow
          onClick={() => {
            carouselReference.next()
            setFilePreview(undefined)
          }}
        />
      )
    }
  }

  const CustomLeftArrow = (index: number) => {
    if (index !== 0) {
      return (
        <LeftArrow
          onClick={() => {
            carouselReference.previous()
            setFilePreview(undefined)
          }}
        />
      )
    }
  }

  function getInvoiceIndex() {
    const filterById = (invoice: InvoiceType) => invoice.id === invoiceId
    return invoicesForDetail.findIndex(filterById)
  }

  function handleStartOfCarousel(carousel: Carousel | null) {
    const index = getInvoiceIndex()
    if (detailSlideshowStartState && carousel) {
      setActiveIndex(index)
      carousel.goToSlide(index)
      setDetailSlideshowStartState(false)
    }
    setCarouselReference(carousel)
    setMaxDescriptionWidth()
  }

  function setMaxDescriptionWidth() {
    const detailItems = document.getElementsByClassName('detailItem')

    for (let i = 0; i < detailItems.length; i++) {
      if (detailItems[i].lastChild) {
        // @ts-ignore
        if (detailItems[i].lastChild.className.includes('detailDescription')) {
          // @ts-ignore
          detailItems[i].lastChild.style.maxWidth = detailItems[i].firstChild.offsetWidth + 'px'
        }
      }
    }
  }

  function afterChange() {
    setMaxDescriptionWidth()
    setVisibleCarousel(true)
  }

  function beforeChange(nextSlide: number) {
    setActiveIndex(nextSlide)

    if (!loadedInvoices.includes(nextSlide)) {
      setPreviewLoading(true)
    }
  }

  function imageOnLoad({ target: img }: any) {
    if (img.offsetWidth < 230) {
      img.style.borderRadius = 0
      img.parentElement.style.padding = '0 5px'
    }
    setMaxDescriptionWidth()
  }

  document.documentElement.style.setProperty('--vh', `${window.screen.availHeight * 0.01}px`)

  window.addEventListener('resize', function() {
    setMaxDescriptionWidth()
    let vh = window.screen.availHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  })

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      event.key === 'Escape' && handleClose()
    }

    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  useEffect(() => {
    if (!carouselReference) return

    const handleKeyboardNavigation = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        carouselReference.next()
        setFilePreview(undefined)
      } else if (event.key === 'ArrowLeft') {
        carouselReference.previous()
        setFilePreview(undefined)
      }
    }

    window.addEventListener('keydown', handleKeyboardNavigation)

    return () => {
      window.removeEventListener('keydown', handleKeyboardNavigation)
    }
  }, [carouselReference])

  function loader() {
    if (previewLoading) {
      return (
        <LoaderOverlay>
          <CloseImage onClick={handleClose} />
          <Loader />
        </LoaderOverlay>
      )
    }
  }

  return (
    <Container>
      {loader()}
      <DetailContainer>
        <Carousel
          focusOnSelect={true}
          ref={carousel => handleStartOfCarousel(carousel)}
          draggable={false}
          responsive={responsive}
          keyBoardControl={false}
          customTransition="all .0"
          transitionDuration={500}
          arrows={false}
          containerClass="carousel-container"
          afterChange={afterChange}
          beforeChange={beforeChange}
          swipeable={false}
          autoPlay={false}
        >
          {visibleCarousel &&
            invoicesForDetail.map((invoice: InvoiceType, index: number) => (
              <DetailItemContainer className={'detailItem'}>
                {activeIndex + preloadCount > index && activeIndex - preloadCount < index && (
                  <>
                    {handleView(invoice, index, filePreview || invoice.files[0])}
                    {invoice.files.length > 1 && (
                      <InvoiceDetailPreview
                        key={invoice.id}
                        filePreview={filePreview || invoice.files[0]}
                        invoice={invoice}
                        setFilePreview={setFilePreview}
                        setPreviewLoading={setPreviewLoading}
                        imageChanged={imageChanged}
                      />
                    )}
                  </>
                )}
                {handleDescription(invoice, index)}
              </DetailItemContainer>
            ))}
        </Carousel>
      </DetailContainer>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: fit-content;
`

interface ViewProps {
  rows: number
}

const StyledImage = styled.img<ViewProps>`
  max-width: 100%;
  border-radius: 8px;
  max-height: calc(79vh - 5rem * ${props => props.rows});

  @media (max-height: 69rem) {
    max-height: calc(65vh - 5rem * ${props => props.rows});
  }
`

const LoaderOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.31);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
`

const DetailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

interface ContainerProps {
  visible: boolean
  rows: number
}

const ImageContainer = styled.div<ContainerProps>`
  position: relative;
  background-color: #3a3c45;
  border-radius: 8px;
  min-width: 230px;
  display: ${visible => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 10rem;
  max-height: calc(79vh - 5rem * ${props => props.rows});

  @media (max-height: 69rem) {
    max-height: calc(65vh - 5rem * ${props => props.rows});
  }
`

const PdfContainer = styled.div<ContainerProps>`
  position: relative;
  background-color: #3a3c45;
  display: ${visible => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  max-height: calc(79vh - 5rem * ${props => props.rows});

  @media (max-height: 69rem) {
    max-height: calc(65vh - 5rem * ${props => props.rows});
  }
`

const DetailItemContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 64px 123px;

  @media (max-width: 1000px) {
    margin: 64px 24px;
  }
  @media (max-width: 500px) {
    margin: 10px 24px;
  }
`

const DescriptionContainer = styled.div`
  border-radius: 8px;
  background-color: #3a3c45;
  margin-top: 8px;
  position: relative;
  max-width: fit-content;
  display: flex;
  overflow-y: hidden;
`

const TextContainer = styled.div`
  margin: 10px;
  display: flex;
  overflow-y: auto;
  max-height: 72px;

  @media (max-width: 500px) {
    max-height: 54px;
  }
`

const DescriptionText = styled.span`
  color: #ffffff;
  font-family: 'Open Sans Regular', sans-serif;
  font-size: 13px;
  line-height: 18px;
  line-break: anywhere;
`

const PdfView = styled.iframe<ViewProps>`
  width: 80vw;
  height: 100vh;
  border: none;
  border-radius: 8px;
  overflow-x: hidden;
  max-height: calc(79vh - 5rem * ${props => props.rows});

  @media (max-height: 69rem) {
    max-height: calc(65vh - 5rem * ${props => props.rows});
  }
`

const DetailControl = styled.div`
  cursor: pointer;
  position: absolute;
  width: 35%;
  height: calc(100% - 80px);
  max-height: 75%;
  overflow-x: unset;
  background-position-y: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  min-height: 28px;
  z-index: 100;

  @media (max-width: 650px) {
    width: 25%;
  }
`

export const RightArrow = styled(DetailControl)`
  right: 0;
  padding-right: 8px;
  background-image: url('/img/Invoice_Detail_Chevron_Next.svg');
  background-position-x: right;

  &:hover {
    background-image: url('/img/Invoice_Detail_Chevron_Next_Hover.svg');
  }
`

const LeftArrow = styled(DetailControl)`
  left: 0;
  padding-left: 8px;
  background-image: url('/img/Invoice_Detail_Chevron_Previous.svg');
  background-position-x: left;

  &:hover {
    background-image: url('/img/Invoice_Detail_Chevron_Previous_Hover.svg');
  }
`

const animation = keyframes`
    from {
      box-shadow: inset rgba(0, 0, 0, 0) 0 0 0;
    }
    to {
      box-shadow: inset rgba(0, 0, 0, 0.0000001) 0 0 0 10px;
    }
`

const CloseImage = styled(DetailControl)`
  right: 0;
  top: 0;
  padding: 8px 8px 0 0;
  width: 12.5%;
  height: 12.5%;
  background-position: top right;
  background-image: url('/img/Close_Modal_Window_Active.svg');

  &:hover {
    background-image: url('/img/Close_Modal_Window_Active_Hover.svg');
  }

  animation: 4s infinite ${animation};
`

const OpenImage = styled(DetailControl)`
  right: 0;
  bottom: 0;
  padding: 0 8px 8px 0;
  width: 12.5%;
  height: 12.5%;
  background-image: url('/img/zoom.svg');
  background-position: bottom right;
`
