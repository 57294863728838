import React, { useState, useEffect, useContext } from 'react'
import Section from './index'
import { headerDataSettingsCurrencies } from '../../../../constants/sectionsData'
import { useMutation, useQuery } from '@apollo/react-hooks'
import currencies, { CurrencyItem } from '../../../../queries/currencies'
import DeletePopUp from '../../../layout/PopUps/DeletePopUp'
import InputPopUp from '../PopUps/InputPopUp'
import { settingsDataCurrency } from '../../../../constants/formsData'
import editCurrency from '../../../../mutations/editCurrency'
import addCurrency from '../../../../mutations/addCurrency'
import deleteCurrency from '../../../../mutations/deleteCurrency'
import inputValueReduce from '../../../layout/inputValueReduce'
import handleClose from './handleClose'
import handleOpen from './handleOpen'
import isItDuplicity from './isItDuplicity'
import { CategoryItem } from '../../../../queries/categories'
import { StatusItem } from '../../../../queries/statuses'
import { InputValueStateType } from '../../../../constants/types'
import { ErrorContext } from '../../../../context/errorContext'
import usePrevious from '../../../../hooks/usePrevious'

export default () => {
  const { data, loading } = useQuery(currencies)
  const [actualCurrency, setActualCurrency] = useState({
    id: 1,
    symbol: 'CZK'
  })
  const [inputValue, setInputValue] = useState<InputValueStateType>({})

  const [deletePopUpClosed, setDeletePopUpClosed] = useState(true)
  const [editPopUpClosed, setEditPopUpClosed] = useState(true)
  const [addPopUpClosed, setAddPopUpClosed] = useState(true)
  const [error, setError] = useState(false)
  const { error: apiError, closedPopUp } = useContext(ErrorContext)
  const prevApiError = usePrevious(apiError)

  const refetchCurrencyQueries = { refetchQueries: [{ query: currencies }] }

  const [editActualCurrency] = useMutation(editCurrency, {
    ...refetchCurrencyQueries
  })
  const [addNewCurrency] = useMutation(addCurrency, {
    ...refetchCurrencyQueries
  })
  const [deleteActualCurrency] = useMutation(deleteCurrency, {
    ...refetchCurrencyQueries
  })

  const reduced = inputValueReduce(inputValue)

  const buttonInactive = !reduced.currencyName

  useEffect(() => {
    if (closedPopUp && prevApiError === 'Currency Duplicity') {
      setEditPopUpClosed(false)
    }
    if (!editPopUpClosed && prevApiError === 'Currency Duplicity') {
      setEditPopUpClosed(true)
    }
  }, [closedPopUp, prevApiError])

  function getId(item: CurrencyItem | CategoryItem | StatusItem) {
    if ('symbol' in item) {
      setActualCurrency({
        id: item.id,
        symbol: item.symbol.toString()
      })
    }
  }

  function getInputValue(inputValue: InputValueStateType) {
    setInputValue(inputValue)
  }

  function handleDelete() {
    deleteActualCurrency({ variables: { id: actualCurrency.id } }).then(() =>
      setDeletePopUpClosed(true)
    )
  }

  function handleEdit() {
    if (buttonInactive) {
      return null
    }

    const { id } = actualCurrency
    const symbol = reduced.currencyName

    editActualCurrency({ variables: { id, symbol: symbol } }).then(function() {
      setEditPopUpClosed(true)
      setActualCurrency({
        id: 0,
        symbol: ''
      })
    })
  }

  function handleAdd() {
    if (buttonInactive) {
      return null
    }

    const symbol = reduced.currencyName

    if (isItDuplicity(data.currencies, 'symbol', inputValue.currencyName.value)) {
      return setError(true)
    }

    addNewCurrency({ variables: { symbol } }).then(() => setAddPopUpClosed(true))
  }

  function getCurrencyEditValues() {
    return { currencyName: { value: actualCurrency.symbol, isInvalid: false } }
  }

  if (loading) {
    return null
  }

  return (
    <>
      <DeletePopUp
        handleDelete={handleDelete}
        closed={deletePopUpClosed}
        deleteItemText={actualCurrency.symbol}
        handleClosed={() => handleClose(deletePopUpClosed, setDeletePopUpClosed, setError)}
        kindOfDelete="currency"
      />
      <InputPopUp
        errorKindText="currency"
        error={error}
        closed={editPopUpClosed}
        orangeButtonFunction={handleEdit}
        handleClose={() => handleClose(editPopUpClosed, setEditPopUpClosed, setError)}
        data={settingsDataCurrency}
        orangeButtonText="Save changes"
        titleText="Edit currency"
        getInputValue={getInputValue}
        editInputValues={getCurrencyEditValues()}
        orangeButtonActive={!buttonInactive}
        forCategory={true}
      />
      <InputPopUp
        forCurrency={true}
        errorKindText="currency"
        error={error}
        closed={addPopUpClosed}
        orangeButtonFunction={handleAdd}
        handleClose={() => handleClose(addPopUpClosed, setAddPopUpClosed, setError)}
        data={settingsDataCurrency}
        orangeButtonText="Add"
        titleText="Add currency"
        getInputValue={getInputValue}
        editInputValues={getCurrencyEditValues()}
        orangeButtonActive={!buttonInactive}
        forCategory={true}
      />
      <Section
        getId={getId}
        headerTitle="Currencies"
        handleAdd={() => handleOpen(addPopUpClosed, setAddPopUpClosed)}
        handleDelete={() => handleOpen(deletePopUpClosed, setDeletePopUpClosed)}
        forCurrency={true}
        handleEdit={() => handleOpen(editPopUpClosed, setEditPopUpClosed)}
        headerData={headerDataSettingsCurrencies}
        data={data.currencies}
        nameProperty="symbol"
      />
    </>
  )
}
