import React, { useEffect, useState } from 'react'
import Dropdown from 'rc-dropdown'
import OptionDropdownMenu from './OptionDropdownMenu'
import 'rc-dropdown/assets/index.css'
import '../Dropdown.css'
import styled, { css } from 'styled-components'

interface Props {
  children: React.ReactNode
  setPopUpClosed: (state: boolean) => void
  setPath: (state: string) => void
  month: number | undefined
  year: number | undefined
}

export default ({ children, setPath, setPopUpClosed, month, year }: Props) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    function hideOnScroll() {
      setVisible(false)
    }
    document.addEventListener('scroll', hideOnScroll, true)
    return () => document.removeEventListener('scroll', hideOnScroll, true)
  }, [])

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <OptionDropdownMenu
          setPopUpClosed={setPopUpClosed}
          setPath={setPath}
          month={month}
          year={year}
        />
      }
      visible={visible}
      onVisibleChange={setVisible}
    >
      <StyledP visible={visible}>{children}</StyledP>
    </Dropdown>
  )
}

interface SectionProps {
  visible: boolean
}

const StyledP = styled.div<SectionProps>`
  font-size: 0.95em;
  font-family: 'Montserrat';
  font-weight: 500;
  margin-right: 1.3em;
  display: flex;
  align-items: end;
  cursor: pointer;
  width: 3rem;
  height: 3rem;

  @media (max-width: 750px) {
    white-space: nowrap;
  }

  &:hover {
    color: #f29312;
    path {
      fill: #f29312;
    }
  }

  ${({ visible }) =>
    visible &&
    css`
      color: #f29312;
      path {
        fill: #f29312;
      }
    `}
`

const ImageContainer = styled.div`
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.4em;
`
