import styled from 'styled-components'

export default styled.span`
  text-decoration: none;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 1.2em;
  color: white;
  cursor: pointer;
`
